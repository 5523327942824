import { parse, format } from 'fecha';


const helpers = {
    parseDate: (value, template) => {
        return parse(value, template);
    },

    formatDate: (value, template) => {
        return format(value, template);
    },

    stringForNumber: (value, strings) => {
        let idx = 2;
        let num = value;
        if (num > 100) {
            num = num % 100;
        }
        if ((num < 10) || (num > 19)) {
            let z = num % 10;
            if (z === 1) {
                idx = 0;
            } else if ((z > 1) && (z < 5)) {
                idx = 1;
            }
        }
        return value + ' ' + strings[idx];
    },

    toPrice: (value, params) => {
        if (!params) {
            params = {};
        }
        value = value * 1;
        let minus = (value >= -0.0049) ? '' : '-';
        value = Math.abs(value);
        let kop = Math.floor((Math.abs(value - Math.floor(value)) + 0.005) * 100);
        if (kop < 10) {
            kop = '0' + kop;
        }
        value = Math.floor(value + 0.0001);
        value = Math.abs(value) + '';
        let text = '';
        let l = value.length;
        let i = 0;
        let k;
        while (i < l) {
            k = ((i === 0) && (l % 3 > 0)) ? l % 3 : 3;
            if (i + k < l) {
                text = text + value.substr(i, k) + ' ';
            } else {
                text = text + value.substr(i, k);
            }
            i = i + k;
        }
        if (params.pointer) {
            if ((params.zero_kop && kop !== '00') || !params.zero_kop) {
                text = text + params.pointer + kop;
            }
        }
        if (params.sign) {
            text = text + ' ' + params.sign;
        }
        return minus + text;
    },

    validateEmail: (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
             );
    },

    prepareTableFields: (tableHeader, tableFilters) => {
        let fields = [];
        if (tableFilters.length) {
            Object.keys(tableHeader).map((key) => {
                if (tableFilters.indexOf(key) !== -1) {
                    let field = {
                        key: key,
                        label: tableHeader[key]
                    }
                    field.label.key = key;
                    fields.push(field);
                }
            });
        }
        return fields;
    },

    objEqual: (obj1, obj2) => {
        console.log(obj1, obj2);
        const obj1Keys = Object.keys(obj1).sort();
        const obj2Keys = Object.keys(obj2).sort();
        if (obj1Keys.length !== obj2Keys.length) {
            return false;
        } else {
            const areEqual = obj1Keys.every((key, index) => {
                const objValue1 = obj1[key];
                const objValue2 = obj2[obj2Keys[index]];
                return objValue1 === objValue2;
            });
            return areEqual ? true : false;
        }
    }
}

export default {
    install (app) {
        app.helpers = helpers
        app.config.globalProperties.$helpers = helpers
    }
}
