<template>
    <div class="notifications">
        <div class="app__block-title">
            <h1 class="mb-5">Уведомления</h1>
        </div>
        <div class="filters notifications__filters d-flex">
            <div class="d-flex mb-4 w-100">
                <div class="col-auto mb-3">
                    <b-card
                        class="filters__item shadow"
                        bodyClass="py-1"
                    >
                        <b-form-checkbox 
                            variant="success" 
                            v-model="tableDataFilters.seen" 
                            name="check-button" 
                            switch
                            @change="getNotifications"
                        >
                            Показать прочитанные
                        </b-form-checkbox>
                    </b-card>
                </div>

                <b-button
                    variant="light"
                    :class="['filters__item shadow-sm col-auto ms-auto me-3 mb-3']"
                    size=""
                    pill
                    :disabled="notifications.length ? false : true"
                    @click.prevent="setSeenAll()"
                >
                    Отметить все прочитанными
                </b-button>
                <b-button
                    variant="light"
                    :class="['filters__item shadow-sm col-auto mb-3']"
                    size=""
                    pill
                    @click.prevent="next('messages-reminders')"
                >
                    К списку напоминаний
                </b-button>
            </div>
        </div>
        <b-card
            class="notifications__list col-12 shadow mb-5"
        >
            <div class="notifications__list-block">
                <div 
                    v-for="item in notifications"
                    :key="`notification-${item.id}`"
                    class="notifications__list-item pb-3 mb-3"
                >
                    <div class="notifications__list-item-main">
                        <div class="notifications__list-item-message mb-2">
                            <strong>{{ item.reminder?.creator?.public_name }} (ID: {{ item.reminder?.creator?.id }}):</strong> 
                            <div v-html="item.content" />
                        </div>
                        <div class="notifications__list-item-info">
                            <b-icon-chat-left v-if="item.seen" />
                            <b-icon-chat-left-dots 
                                v-else 
                                class="text-danger" 
                            />
                            <span class="mx-2">{{ $helpers.formatDate(new Date(item.created), 'DD.MM.YYYY') }}</span>
                            <template
                                v-if="item.seen"
                            >
                                Прочитано
                            </template>
                            <template
                                v-else
                            >
                                <a href="#" @click.prevent="setSeen(item.id)">Отметить прочитанным</a>
                            </template>
                        </div>
                    </div>
                    <div class="notifications__list-item-right">
                        <div class="notifications__list-item-type">{{ item.title }}</div>
                        <div class="notifications__list-item-account">{{ item.reminder.portfolio?.trust_name || item.reminder.portfolio?.name }} · {{ item.reminder.instrument?.name }}</div>
                    </div>
                </div>
            </div>

            <BlockNavigateTable
                :totalRows="currentTotalRows"
                :currentPage="currentPage"
                :currentPerPage="currentPerPage"
                @changeNavigateTable="changeNavigateTable"
            />
            <b-overlay 
                :show="showLoaderSending"
                variant="transparent"
                no-wrap
                spinner-variant="success"
            />
        </b-card>
    </div>
</template>

<script>
    import { app } from "@/services";

    //import { app } from "@/services";

    import BlockNavigateTable from "@/components/block-navigate-table";
    //import Helper from "@/components/helper";
    //import ModalBondGroupAdd from "@/components/modals/modal-bond-group-add";
    export default {
        name: 'messagesNotifications',
        components: {
            BlockNavigateTable,
            //ModalBondGroupAdd
        },
        props: {
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        watch: {
        },
        data() {
            return {
                tableDataFilters: {
                    seen: false
                },
                notifications: [],
                currentPerPage: 10,
                currentPage: 1,
                showLoaderSending: false
            };
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            this.getNotifications();
        },
        methods: {
            getNotifications() {
                this.showLoaderSending = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                });
                app.getNotificationsList(params).then(res => {
                    console.log(res);
                    if (!res.error) {
                        this.currentTotalRows = res.count;
                        this.notifications = res.results;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            filterTableData(data) {
                let list = [...data];
                return list;
            },
            changeFilterTableData(data = null) {
                console.log('changeFilterTableData');
                if (data) this.tableDataFilters = Object.assign(this.tableDataFilters, data);
                this.currentPage = 1;
                this.useFilters = true;
                this.getNotifications();
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
                this.getNotifications();
            },
            setSeen(id) {
                this.showLoaderSending = true;
                app.setNotificationsSeen(id).then(() => {
                    //this.notifications.filter(item => { return item.id === id; } )[0] = res;
                    this.getNotifications();
                    //this.showLoaderSending = false;
                    app.getUser().then(res => {
                        delete res.currencies;
                        this.$store.dispatch('setUser', res);
                    });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            setSeenAll() {
                this.showLoaderSending = true;
                app.setNotificationsSeenAll().then(res => {
                    console.log(res);
                    this.currentTotalRows = res.count;
                    this.notifications = res.results;
                    this.showLoaderSending = false;
                    app.getUser().then(res => {
                        delete res.currencies;
                        this.$store.dispatch('setUser', res);
                    });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
