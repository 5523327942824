<template>
    <div 
        class="app__block profile m--full-width d-flex h-100 flex-column p-4"
        :class="$route.name"
    >
        <BlockUserCurrencies/>
        <template
            v-if="!showLoaderSending"
        >
            <h1 class="mb-5">
                {{ user.first_name || 'Профиль пользователя' }} <span>(ID: {{ user.id }})</span>
            </h1>
            <div class="profile__buttons mb-4">
                <b-button
                    v-for="item in menu"
                    :key="item.name"
                    :variant="(item.name === $route.name) ? 'outline-primary' : 'outline-primary'"
                    :active="item.name === $route.name"
                    class="profile__buttons-item me-3 mb-2"
                    size="lg"
                    pill
                    @click.stop="next(item.name)"
                >
                    {{ item.title }}
                </b-button>
            </div>
            <routerView />
        </template>
        <template
            v-else
        >
            <div class="app__loader">
                <b-spinner 
                    variant="success"
                    class="me-3"
                />
                <span class="text-success">Загрузка данных ...</span>
            </div>
        </template>
    </div>
</template>

<script>
    //import { app } from "@/services";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    export default {
        name: 'profile',
        components: {
            BlockUserCurrencies
        },
        watch: {
            link: {
                immediate: true,
                handler() {
                    /*
                    this.showLoaderSending = true;
                    app.getPortfolio(this.id).then(res => {
                        if (!res.error) {
                            this.portfolio = res;
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                    */
                }
            },
        },
        props: {
        },
        data() {
            return {
                menu: [{
                    name: 'profile-user',
                    title: 'Профиль',
                    icon: null,
                }, {
                /*
                    name: 'profile-accounts',
                    title: 'Брокерские счета',
                    icon: null,
                }, {
                */
                    name: 'profile-currencies',
                    title: 'Валюты клиента',
                    icon: null,
                }, {
                    name: 'profile-tariffs',
                    title: 'Тариф',
                    icon: null,
                }],
                portfolio: {},
                showLoaderSending: false
            };
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
