<template>
    <b-modal
        id="modal-watchlist-item-delete"
        v-model="showModal"
        classes="modal__container"
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Удаление актива из Watchlist"
        centered
        hide-footer
        scrollable
        @hidden="hideModal()"
    >
        <div class="modal__content auth__form">
            <b-alert
                class="mb-3"
                variant="success"
                :modelValue="dismissCountDown"
                @dismiss-count-down="countDownChanged"
            >
                <div class="form__content">
                    {{ watchlistDeleted }}
                </div>
            </b-alert>
            <template v-if="formErrors.detail">
                <b-alert
                    class="mb-3"
                    variant="danger"
                    show
                >
                    <div class="form__content">
                        {{ formErrors.detail }}
                    </div>
                </b-alert>
            </template>
            <template v-else-if="!watchlistDeleted">
                <b-form
                    class="form d-flex flex-column"
                    @submit="onSubmit"
                >
                    <div class="form__content mb-3">
                        Вы удаляете актив <strong>{{ instrument.name }}</strong> из Watchlist.
                    </div>
                    <b-button
                        type="submit"
                        variant="success"
                        class="d-block mx-auto"
                        pill
                    >
                        Подтвердить
                    </b-button>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-form>
            </template>
        </div>
        <b-overlay
            :show="showLoaderModal"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-modal>
</template>

<script>
    import { app } from "@/services";
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            watchlistId: {
                type: [Number, String],
                default() { return null; }
            },
            instrument: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                formData: {},
                formErrors: {},
                showModal: this.show,
                showLoaderModal: false,
                showLoaderSending: false,
                dismissCountDown: 0,
                watchlistDeleted: '',
                refreshData: false,
            };
        },
        created() {
        },
        mounted() {
            this.formData = {
                id: this.watchlistId,
                instrument: this.instrument.id
            };
        },
        methods: {
            onSubmit() {
                this.formErrors = {};
                let params = this.formData;
                this.showLoaderSending = true;
                app.deleteWatchListInstrument(params).then(res => {
                    this.showLoaderSending = false;
                    this.watchlistDeleted = res.detail || 'Актив Watchlist успешно удален!';
                    this.dismissCountDown = 3;
                    this.refreshData = true;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response);
                        this.formErrors = err.response.data.error;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            countDownChanged(dismissCountDown) {
                console.log('dismissCountDown', dismissCountDown);
                //this.dismissCountDown = dismissCountDown;
                if (dismissCountDown === 0) {
                    this.$nextTick(() => {
                        this.showModal = false;
                    });
                }
            },
            hideModal() {
                this.formErrors = {};
                this.watchlistDeleted = '';
                this.dismissCountDown = 0;
                this.$emit('hideModal', this.refreshData);
            },
        }
    };
</script>
