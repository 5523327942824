<template>
    <div class="app__block empty d-flex h-100 flex-column p-4">
        <h1 class="mb-5">В разработке</h1>
        <div class="empty__content"></div>
    </div>
</template>

<script>
    export default {
        name: 'empty',
        components: {
        },
        props: {
        },
        data() {
            return {
                menu: [{
                    name: 'account-info',
                    title: 'Сводная информация по счету',
                    icon: null,
                }, {
                    name: 'account-history',
                    title: 'История сделок',
                    icon: null,
                }, {
                    name: 'account-flow',
                    title: 'Движение денежных средств',
                    icon: null,
                }, {
                    name: 'account-analitic',
                    title: 'Аналитика портфеля',
                    icon: null,
                }],
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
