<template>
    <b-form
        @submit="onSubmitCashDeal"
        class="account__edit-form row"
    >
        <b-form-group
            id="input-group-date"
            label="Дата сделки*"
            class="col-6"
        >
            <b-input-group
                class="m--with-icon"
            >
                <template #append>
                    <b-icon-calendar2-check class="icon"></b-icon-calendar2-check>
                </template>
                <VueDatePicker
                    v-model="formData.date"
                    model-type="yyyy-MM-dd"
                    format="dd.MM.yyyy"
                    locale="ru"
                    :auto-apply="true"
                    select-text="Выбрать"
                    cancel-text="Отменить"
                    :year-range="[2000, 2050]"
                    :max-date="new Date()"
                    :prevent-min-max-navigation="true"
                    text-input
                    :enable-time-picker="false"
                    :month-change-on-scroll="true"
                    :clearable="false"
                    :hide-input-icon="true"
                >
                    <template #dp-input="{ value, onInput, onEnter, onTab }">
                        <input
                            v-maska
                            data-maska="##.##.####"
                            data-maska-eager
                            class="form-control form-control-sm shadow-sm m--no-pill"
                            type="text"
                            :value="value"
                            @keyup="onInput"
                            @keydown.enter.prevent="onEnter"
                            @keydown.tab="onTab"
                        />
                    </template>
                </VueDatePicker>
            </b-input-group>
            <!--DatePicker
                v-model="formData.date"
                :model-config="calendarConfig"
                :masks="masks"
                mode="date"
                color="blue"
            >
                <template v-slot="{ inputValue, inputEvents }">
                    <b-input-group
                        class="m--with-icon"
                    >
                        <template #append>
                            <b-icon-calendar2-check class="icon"></b-icon-calendar2-check>
                        </template>
                        <b-form-input
                            name="date"
                            readonly
                            :value="inputValue"
                            v-on="inputEvents"
                            :state="null"
                            size="sm"
                            class="shadow-sm m--no-pill"
                            required
                        ></b-form-input>
                    </b-input-group>
                </template>
            </DatePicker-->
        </b-form-group>
        <b-form-group
            id="input-group-operation_type"
            label="Операция*"
            class="col-6"
        >
            <Multiselect
                id="input-operation_type"
                ref="input-operation_type"
                v-model="formData.operation_type"
                :options="operationList"
                mode="single"
                :searchable="true"
                class="shadow-sm m--no-pill"
                placeholder=""
                noOptionsText="Список пуст"
                noResultsText="По Вашему запросу ничего не найдено"
                required
                :disabled="showLoaderSending"
            />
        </b-form-group>
        <b-form-group
            id="input-group-currency"
            label="Валюта сделки*"
            class="col-6"
        >
            <Multiselect
                id="input-currency"
                ref="input-currency"
                v-model="formData.currency"
                :options="currencyList"
                mode="single"
                :close-on-select="true"
                :searchable="true"
                :create-option="false"
                class="shadow-sm m--no-pill"
                placeholder=""
                noOptionsText="Список пуст"
                noResultsText="По Вашему запросу ничего не найдено"
                :disabled="showLoaderSending"
                required
            />
        </b-form-group>
        <b-form-group
            id="input-group-summ"
            label="Сумма*"
            class="col-6"
        >
            <input
                id="input-summ"
                v-maska:[optionsMaska]
                data-maska="0.99"
                data-maska-tokens="0:\d:multiple|9:\d:optional"
                v-model="formData.payment_sum"
                type="text"
                placeholder="Сумма в выбранной валюте"
                class="form-control form-control-sm shadow-sm m--no-pill"
                :disabled="showLoaderSending"
                required
            />
        </b-form-group>
        <b-form-group
            id="input-group-comment"
            label="Комментарий"
            class="col-12"
        >
            <b-form-textarea
                id="input-comment"
                v-model="formData.comment"
                placeholder="При необходимости введите ваш комментарий"
                size="sm"
                class="shadow-sm m--no-pill"
                :disabled="showLoaderSending"
            ></b-form-textarea>
        </b-form-group>
        <div class="col-12">
            <b-alert
                variant="success"
                :show="addSuccess"
            >
                {{ dealId ? 'Сделка успешно изменена' : 'Сделка успешно добавлена' }}
            </b-alert>
            <b-alert
                variant="danger"
                :show="addError"
            >
                {{ addError }}
            </b-alert>
        </div>
        <div
            class="col-12 pt-3 row"
            :class="(inModal || dealId) && 'justify-content-center'"
        >
            <div
                v-if="inModal"
                class="col-auto"
            >
                <b-button
                    variant="outline-success"
                    class="account__edit-form-button mb-3"
                    type="submit"
                    pill
                    :disabled="showLoaderSending"
                    @click="formData.typeSubmit = 'close'"
                >
                    Сохранить
                </b-button>
            </div>
            <div
                v-if="!dealId"
                class="col-auto"
            >
                <b-button
                    variant="success"
                    class="account__edit-form-button mb-3"
                    type="submit"
                    pill
                    :disabled="showLoaderSending"
                    @click="formData.typeSubmit = 'repeat'"
                >
                    Сохранить и добавить еще
                </b-button>
            </div>
        </div>
        <b-overlay
            :show="showLoaderSending"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-form>
</template>

<script>
    import { app } from "@/services";
    import Multiselect from '@vueform/multiselect'
    export default {
        components: {
            Multiselect,
        },
        emits: ['hideModal', 'refreshData'],
        props: {
            /*
            id: {
                type: [Number, String],
                default() { return null; }
            },
            */
            dealId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            inModal: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                optionsMaska:  {
                    preProcess: val => {
                        return val.replace(/[$,\s]/g, '')
                    },
                    postProcess: val => {
                        if (!val) return ''
                        const sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)
                        return Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).format(val)
                            .slice(0, sub ? -sub : undefined)
                            .replace(/[$]/g, '')
                            .replace(/[,]/g, ' ')
                    }
                },
                /*
                masks: {
                    input: 'DD.MM.YYYY',
                },
                calendarConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD'
                },
                */
                formData: {
                    portfolioId: this.portfolioId,
                    date: this.$helpers.formatDate(new Date(), 'YYYY-MM-DD'),
                },
                showLoaderSending: false,
                addSuccess: false,
                addError: null,
                operationList: [],
                currencyList: [],
                refreshData: false,
            };
        },
        computed: {
        },
        mounted() {
            if (this.dealId) {
                this.getPortfolioDeal();
            } else {
                this.getCurrencies();
                this.getOperations();
            }
        },
        methods: {
            getPortfolioDeal() {
                if (this.dealId) {
                    let params = {
                        dealId: this.dealId,
                        portfolioId: this.portfolioId,
                    }
                    app.getPortfolioDeal(params, this.portfolioType).then(res => {
                        if (!res.error) {
                            console.log(res);
                            res.date = this.$helpers.formatDate(new Date(res.date), 'YYYY-MM-DD');
                            this.formData = res;
                            this.formData.portfolioId = res.portfolio;
                            delete res.portfolio;
                            this.getCurrencies();
                            this.getOperations();
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                }
            },
            getCurrencies() {
                let params = {};
                this.showLoaderSending = true;
                app.getCurrencies(params).then(res => {
                    if (!res.error) {
                        this.currencyList = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id,
                                currency_iso: item.key
                            }
                        });
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getOperations() {
                let params = {};
                this.showLoaderSending = true;
                app.getOperations(params).then(res => {
                    if (!res.error) {
                        let operations = res.filter(item => {
                            return item.cash_operation;
                        })
                        this.operationList = operations.map(item => {
                            return {
                                label: item.name,
                                value: item.pk,
                                key: item.key
                            }
                        });
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            onSubmitCashDeal() {
                console.log('onSubmitCashDeal');
                this.showLoaderSending = true;
                this.addSuccess = false;
                this.addError = null;
                let params = Object.assign({}, this.formData);
                params.payment_sum = +(params.payment_sum.replace(/\s/g, ''));
                if (this.dealId) {
                    app.updatePortfolioDealCash(params, this.portfolioType).then(res => {
                        this.showLoaderSending = false;
                        console.log(res);
                        this.refreshData = true;
                        this.addSuccess = true;
                        console.log(this.formData.typeSubmit);
                        if (this.formData.typeSubmit === 'close') {
                            this.$emit('hideModal', this.refreshData);
                        }
                        this.formData = {
                            portfolioId: this.portfolioId
                        };
                    }).catch(err => {
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                } else {
                    app.addPortfolioDealCash(params, this.portfolioType).then(res => {
                        this.showLoaderSending = false;
                        console.log(res);
                        this.refreshData = true;
                        this.addSuccess = true;
                        if (this.formData.typeSubmit === 'close') {
                            this.$emit('hideModal', this.refreshData);
                        }
                        this.formData = {
                            portfolioId: this.portfolioId
                        };
                        this.$refs['input-operation_type'].clear();
                        this.$refs['input-currency'].clear();
                        this.$emit('refrshData');
                    }).catch(err => {
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                }
            },
        }
    };
</script>
