<template>
    <b-dropdown
        :id="`share-${id}`"
        class="float-end"
        :container="`#app`"
        variant="link-primary px-2"
        no-caret
        lazy
        menuClass="ms-2 m--z-1000"
    >
        <template #button-content>
            {{ $helpers.stringForNumber(shares.length, ['портфель', 'портфеля', 'портфелей']) }}
        </template>
        <b-dropdown-item-button
            v-for="item in shares"
            :key="`share-item-${item.portfolio}`"
            @click.prevent="$router.push({ name: `account`, params: { portfolioId: item.portfolio } })"
        >
            <span>{{ item.portfolio_name }}</span>
            <div class="small text-muted">Доля: {{ $helpers.toPrice(item.share_in_portfolio, { pointer: ',', sign: '%' }) }} ({{ $helpers.toPrice(item.actual_sum, { pointer: ',', sign: sign }) }})</div>
        </b-dropdown-item-button>
    </b-dropdown>
</template>

<script>
    export default {
        components: {
        },
        props: {
            id: {
                type: [Number, String],
                default() { return null; }
            },
            shares: {
                type: Array,
                default() { return []; }
            },
            sign: {
                type: String,
                default() { return ''; }
            },
        },
        data() {
            return {
            }
        },
        mounted() {
        },
        methods: {
        }
    };
</script>
