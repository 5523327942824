<template>
    <div
        class="accounts__item account"
    >
        <div class="accounts__item-header d-flex pb-3">
            <b-card
                class="accounts__item-title mb-2 shadow"
                @click.prevent="$router.push({ name: `account${routeSuffix}`, params: { portfolioId: portfolio.id } })"
            >
                <div class="accounts__item-broker">
                        <img
                            :src="portfolio.broker_logo"
                            alt=""
                        >
                </div>
                {{ portfolio.name }}
            </b-card>

            <div class="d-flex flex-wrap mt-auto ms-auto">
                <b-card
                    class="text-nowrap ms-3 mb-2 shadow"
                    bodyClass="py-0 pe-2 d-flex align-items-center"
                >
                    <b-form-checkbox variant="success" size="sm" v-model="currentFilter" :value="true" name="check-button" switch>
                        Все валюты
                    </b-form-checkbox>
                </b-card>
                <BlockAccountActions
                    :portfolioId="portfolioId"
                    :portfolio="portfolio"
                    :portfolioType="portfolioType"
                    @refreshPortfolio="refreshPortfolio"
                />
            </div>
        </div>
        <div class="account__info">
            <BlockAccountBlocks
                :portfolio="portfolio"
                :currentFilter="currentFilter"
            />
        </div>
    </div>
</template>

<script>
    import BlockAccountActions from "@/components/block-account-actions";
    import BlockAccountBlocks from "@/components/block-account-blocks";
    export default {
        components: {
            BlockAccountActions,
            BlockAccountBlocks
        },
        emits: ['refreshPortfolio'],
        computed: {
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            routeSuffix: {
                type: String,
                default() { return ''; }
            },
        },
        data() {
            return {
                currentFilter: false,
            }
        },
        methods: {
            changeFilter() {
                this.currentFilter = !this.currentFilter;
            },
            refreshPortfolio() {
                console.log('refreshPortfolio');
                this.$emit('refreshPortfolio');
            },
        }
    };
</script>
