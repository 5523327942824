<template>
    <b-modal
        id="modal-filter-table-rows"
        v-model="showModal"
        size="lg"
        classes="modal__container" 
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        :title="title"
        centered
        hide-footer
        scrollable
        no-stacking
        @hidden="hideModal"
    >
        <div class="modal__content">
            <div
                class="row"
            >
                <div 
                    :key="`filter-all`"
                    class="col-12 mb-1"
                >
                    <b-form-checkbox
                        v-model="filterAll"
                        name="filter"
                        switch
                        @change="onChangeAll()"
                    >
                        Все операции
                    </b-form-checkbox>
                </div>
                <div
                    v-for="(group, index) in items"
                    :key="`group-${index}`"
                    class="row mb-3"
                >
                    <div 
                        v-if="group.name"
                        class="col-12 mb-2"
                    >
                        <b-form-checkbox
                            v-model="filter" 
                            name="filter"
                            :value="group.name"
                            :disabled="group.disabled"
                            switch
                            @change="onChangeGroup(group.name)"
                        >
                            <strong>{{ group.title }}</strong>
                        </b-form-checkbox>
                    </div>
                    <template
                        v-for="(item, key) in group.items"
                        :key="`filter-${key}`"
                    >
                        <div 
                            class="mb-1"
                            :class="item.class || 'col-12 col-lg-6'"
                        >
                            <b-form-checkbox
                                v-model="filter" 
                                name="filter"
                                :value="`${group.name}-${item.name}`"
                                :disabled="group.disabled || item.disabled"
                                switch
                                @change="onChange(`${group.name}-${item.name}`)"
                            >
                                {{ item.title }}{{ item.titleSuffix ? titleSuffix(item.titleSuffix) : '' }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </b-modal> 

</template>

<script>
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        //computed: {
        //    showModal() {
        //        return this.show;
        //    },
        //},
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            title: {
                type: String,
                default() { return ''; }
            },
            items: {
                type: Object,
                default() { return {}; }
            },
            itemsFiltered: {
                type: Array,
                default() { return []; }
            },
        },
        data() {
            return {
                filter: [],
                filterAll: false,
                totalItems: this.items.filter(item => { return !item.disabled }).length,
                showModal: this.show,
            };
        },
        created() {
        },
        mounted() {
            this.$nextTick(() => {
                this.filter = this.itemsFiltered;
                //this.filterAll = true;
                this.items.forEach(item => {
                    if (!item.disabled) {
                        this.totalItems += item.items.length;
                        item.items.forEach(i => {
                            if (!i.disabled && i.alias) {
                                this.totalItems += i.alias.length;
                            } 
                        });
                    }
                });
                this.filterAll = (this.totalItems === this.filter.length) ? true : false;
            });
        },
        methods: {
            onChangeAll() {
                this.filter = [];
                if (this.filterAll) {
                    this.items.forEach(group => {
                        if (!group.disabled) {
                            this.filter.push(group.name);
                        }
                        if (group.items.length && !group.disabled) {
                            group.items.forEach(item => {
                                if (!item.disabled) {
                                    this.filter.push(`${group.name}-${item.name}`);
                                    if (item.alias) {
                                        item.alias.forEach(alias => {
                                            this.filter.push(`${group.name}-${alias}`);
                                        });
                                    }
                                }
                            });
                        }
                    });
                } else {
                    //this.filter = this.itemsFiltered;
                    //this.filter = this.filter.splice(0, 1);
                }
                this.$emit('changeFilter', this.filter);
            },
            onChangeGroup(group) {
                console.log(group);
                let idx = this.filter.indexOf(group);
                this.filter = this.filter.filter(item => {
                    let param = item.split('-');
                    if (param[0] === group) {
                        return false;
                    }
                    return true;
                });
                if (idx !== -1) {
                    let items = this.items.filter(item => {
                        return item.name === group;
                    })[0].items;
                    this.filter.push(group);
                    items.forEach(item => {
                        this.filter.push(`${group}-${item.name}`);
                        if (item.alias) {
                            item.alias.forEach(alias => {
                                this.filter.push(`${group}-${alias}`);
                            });
                        }
                    });
                }
                this.filterAll = (this.totalItems === this.filter.length) ? true : false;
                this.$emit('changeFilter', this.filter);
            },
            onChange(value) {
                console.log(value)
                this.items.forEach(group => {
                    let groupChecked = false;
                    if (group.items.length) {
                        group.items.forEach(item => {
                            if (this.filter.indexOf(`${group.name}-${item.name}`) !== -1) {
                                groupChecked = true;
                            }
                            if (value === `${group.name}-${item.name}` && item.alias) {
                                if (this.filter.indexOf(`${group.name}-${item.name}`) !== -1) {
                                    item.alias.forEach(alias => {
                                        this.filter.push(`${group.name}-${alias}`);
                                    });
                                } else {
                                    item.alias.forEach(alias => {
                                        let idx = this.filter.indexOf(`${group.name}-${alias}`);
                                        if (idx !== -1) {
                                            this.filter.splice(idx, 1);
                                        }
                                    });
                                }
                            }
                        });
                        let idx = this.filter.indexOf(group.name);
                        if (!groupChecked && idx !== -1) {
                            this.filter.splice(idx, 1);
                        }
                        if (groupChecked && idx === -1) {
                            this.filter.push(group.name);
                        }
                    }
                });
                this.filterAll = (this.totalItems === this.filter.length) ? true : false;
                this.$emit('changeFilter', this.filter);
            },
            hideModal() {
                this.$emit('hideModal', this.filter);
            },
            titleSuffix(fnName) {
                return this[fnName]();
            },
            currencySign() {
                console.log('currencySign');
                let currency = this.$store.state.currencyCurrent;
                return `, ${currency.sign}`;
            }
        }
    };
</script>
