<template>
    <b-dropdown
        :id="`action-${list.id}`"
        class="ms-auto"
        dropleft
        variant="link-primary"
        no-caret
    >
        <template #button-content>
            <b-icon-gear />
        </template>
        <b-dropdown-item-button
            @click.prevent="showAddWatchlist"
        >
            Переименовать список
        </b-dropdown-item-button>
        <b-dropdown-item-button
            variant="danger"
            @click.prevent="showDeleteWatchlist"
        >
            Удалить список
        </b-dropdown-item-button>
    </b-dropdown>
    <ModalWatchlistAdd
        v-if="showModalAddWatchlist"
        :show="showModalAddWatchlist"
        :watchlistId="list.id"
        :list="list"
        @hideModal="hideAddWatchlist"
    />
    <ModalWatchlistDelete
        v-if="showModalDeleteWatchlist"
        :show="showModalDeleteWatchlist"
        :watchlistId="list.id"
        :list="list"
        @hideModal="hideDeleteWatchlist"
    />
</template>

<script>
    import ModalWatchlistAdd from "@/components/modals/modal-watchlist-add";
    import ModalWatchlistDelete from "@/components/modals/modal-watchlist-delete";
    export default {
        emits: ['getWatchLists'],
        components: {
            ModalWatchlistAdd,
            ModalWatchlistDelete,
        },
        props: {
            list: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                showModalAddWatchlist: false,
                showModalDeleteWatchlist: false,
            }
        },
        mounted() {
        },
        methods: {
            showAddWatchlist() {
                this.showModalAddWatchlist = true;
            },
            hideAddWatchlist(refreshData = false) {
                this.showModalAddWatchlist = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getWatchLists');
                    })
                }
            },
            showDeleteWatchlist() {
                this.showModalDeleteWatchlist = true;
            },
            hideDeleteWatchlist(refreshData = false) {
                this.showModalDeleteWatchlist = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getWatchLists');
                    })
                }
            },
        }
    };
</script>
