import { createRouter, createWebHistory  } from 'vue-router';
import store from '@/store/store'
import auth from '@/views/auth';
import empty from '@/views/empty';
import home from '@/views/home';
import account from '@/views/account';
import accountAdd from '@/views/account-add';
import accountInfo from '@/views/account-info';
import accountHistory from '@/views/account-history';
import accountCoupons from '@/views/account-coupons';
import accountCouponsFuture from '@/views/account-coupons-future';
import accountCouponsPayments from '@/views/account-coupons-payments';
import accountAnalytic from '@/views/account-analytic';
import accountAnalyticFlow from '@/views/account-analytic-flow';
import accountSettings from '@/views/account-settings.vue';
import accountSettingsMain from '@/views/account-settings-main';
import accountSettingsPublic from '@/views/account-settings-public';
import accountSettingsTrust from '@/views/account-settings-trust';
import accountSettingsImport from '@/views/account-settings-import';
import accounts from '@/views/accounts';
import accountsDemo from '@/views/accounts-demo';
import assets from '@/views/assets';
import bond from '@/views/bond';
import bondScreener from '@/views/bond-screener';
import bondList from '@/views/bond-list';
import bondLists from '@/views/bond-lists';
import emitter from '@/views/emitter';
import page from '@/views/page';
import page404 from '@/views/page404';
import profile from '@/views/profile';
//import profileAccounts from '@/views/profile-accounts';
import profileCurrencies from '@/views/profile-currencies';
import profileTariffs from '@/views/profile-tariffs';
import profileUser from '@/views/profile-user';
import messages from '@/views/messages';
import messagesNotifications from '@/views/messages-notifications';
import messagesReminders from '@/views/messages-reminders';
import support from '@/views/support';

import stock from '@/views/stock';
import trustManagment from '@/views/trust-management';

import publicAccounts from '@/views/public-accounts';


const routes = [
    {
        path: '/',
        name: 'home',
        component: home,
        meta: {
            title: 'Сервис планирования, учета и аналитики инвестиций',
        },
        props: true,
    }, {
        path: '/auth',
        name: 'auth',
        component: auth,
        meta: { title: 'Вход в систему' },
        props: true
    }, {
        path: '/public',
        name: 'publics-accounts',
        component: publicAccounts,
        meta: {
            title: 'Публичные брокерские счета',
            breadcrumb(route) {
                //let breadcrumb = app.config.globalProperties.$api.getBreadcrumb(route)
                return { label: 'Публичные счета', link: `${route.path}` }
            },
        },
        props: false,
        children: [
            {
                path: ':portfolioId',
                name: 'publics',
                component: account,
                meta: {
                    title: 'Публичный брокерский счет',
                    breadcrumb(route) {
                        return { label: 'Счет', link: `${route.path}` }
                    },
                    hideButtons: true,
                },
                props: route => ({ portfolioType: 'public', portfolioId: route.params.portfolioId }),
            }
        ]
    }, {
        path: '/stock',
        name: 'stock',
        component: stock,
        meta: {
            title: 'Фондовый рынок',
            breadcrumb: 'Фондовый рынок',
            requiresAuth: true
        },
        props: false,
        children: [
            {
                path: 'accounts',
                name: 'accounts',
                component: accounts,
                meta: {
                    title: 'Все брокерские счета',
                    breadcrumb(route) {
                        return { label: 'Брокерские счета', link: `${route.path}` }
                    },
                    requiresAuth: true
                },
                props: false,
                children: [
                    {
                        path: ':portfolioId',
                        name: 'account',
                        component: account,
                        meta: {
                            title: 'Брокерский счет',
                            breadcrumb(route) {
                                return { label: 'Счет', link: `${route.path}` }
                            },
                            requiresAuth: true
                        },
                        props: true,
                        redirect: { name: 'account-info' },
                        children: [
                            {
                                path: 'account-edit',
                                name: 'account-edit',
                                component: accountAdd,
                                meta: {
                                    title: 'Редактирование брокерского счета',
                                    hideButtons: true,
                                    requiresAuth: true
                                },
                                props: true,
                            }, {
                                path: 'account-info',
                                name: 'account-info',
                                component: accountInfo,
                                meta: {
                                    title: 'Сводная информация по счету',
                                    breadcrumb(route) {
                                        return { label: 'Сводная информация по счету', link: `${route.path}` }
                                    },
                                    requiresAuth: true
                                },
                                props: true,
                            }, {
                                path: 'account-history',
                                name: 'account-history',
                                component: accountHistory,
                                meta: {
                                    title: 'История сделок',
                                    breadcrumb(route) {
                                        return { label: 'История сделок', link: `${route.path}` }
                                    },
                                    requiresAuth: true
                                },
                                props: true,
                            }, {
                                path: 'account-coupons',
                                name: 'account-coupons',
                                component: accountCoupons,
                                meta: {
                                    title: 'Купоны',
                                    breadcrumb(route) {
                                        return { label: 'Купоны', link: `${route.path}` }
                                    },
                                    requiresAuth: true
                                },
                                props: true,
                                redirect: { name: 'account-coupons-future' },
                                children: [
                                    {
                                        path: 'future',
                                        name: 'account-coupons-future',
                                        component: accountCouponsFuture,
                                        meta: {
                                            title: 'Будущие купоны',
                                            breadcrumb(route) {
                                                return { label: 'Будущие купоны', link: `${route.path}` }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }, {
                                        path: 'payments',
                                        name: 'account-coupons-payments',
                                        component: accountCouponsPayments,
                                        meta: {
                                            title: 'Анализ прошлых выплат',
                                            breadcrumb(route) {
                                                return { label: 'Анализ прошлых выплат', link: `${route.path}` }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }
                                ]
                            }, {
                                path: 'account-analytic',
                                name: 'account-analytic',
                                component: accountAnalytic,
                                meta: {
                                    title: 'Аналитика портфеля',
                                    breadcrumb(route) {
                                        return { label: 'Аналитика портфеля', link: `${route.path}` }
                                    },
                                    requiresAuth: true
                                },
                                props: true,
                                redirect: { name: 'account-analytic-flow' },
                                children: [
                                    {
                                        path: 'flow',
                                        name: 'account-analytic-flow',
                                        component: accountAnalyticFlow,
                                        meta: {
                                            title: 'Движение денежных средств',
                                            breadcrumb(route) {
                                                return { label: 'Движение денежных средств', link: `${route.path}` }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }, {
                                        path: 'purchased',
                                        name: 'account-analytic-purchased',
                                        component: accountAnalytic,
                                        meta: {
                                            title: 'Анализ купленных облигаций',
                                            breadcrumb(route) {
                                                return { label: 'Анализ купленных облигаций', link: `${route.path}` }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    },
                                ]
                            }, {
                                path: 'account-settings',
                                name: 'account-settings',
                                component: accountSettings,
                                meta: {
                                    title: 'Настройки брокерского счета',
                                    breadcrumb(route) {
                                        return { label: 'Настройки', link: `${route.path}` }
                                    },
                                    requiresAuth: true,
                                    hideButtons: true,
                                    hideTitle: true
                                },
                                props: true,
                                redirect: { name: 'account-settings-main' },
                                children: [
                                    {
                                        path: 'main',
                                        name: 'account-settings-main',
                                        component: accountSettingsMain,
                                        meta: {
                                            title: 'Общие настройки',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return { label: 'Общие', link: `${route.path}` }
                                            },
                                        },
                                        props: true,
                                    }, {
                                        path: 'public',
                                        name: 'account-settings-public',
                                        component: accountSettingsPublic,
                                        meta: {
                                            title: 'Публичный доступ',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return { label: 'Публичный доступ', link: `${route.path}` }
                                            },
                                        },
                                        props: true,
                                    }, {
                                        path: 'trust',
                                        name: 'account-settings-trust',
                                        component: accountSettingsTrust,
                                        meta: {
                                            title: 'Доверительное управление',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return { label: 'Доверительное управление', link: `${route.path}` }
                                            },
                                        },
                                        props: true,
                                    }, {
                                        path: 'import',
                                        name: 'account-settings-import',
                                        component: accountSettingsImport,
                                        meta: {
                                            title: 'Импорт сделок',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return { label: 'Импорт сделок', link: `${route.path}` }
                                            },
                                        },
                                        props: true,
                                    }
                                ]
                            }, {
                                path: 'assets/:ticker',
                                name: 'account-assets',
                                component: assets,
                                meta: {
                                    title: 'Актив',
                                    breadcrumb(route) {
                                        return { label: 'Актив', link: `${route.path}` }
                                    },
                                    requiresAuth: true,
                                    hideButtons: true,
                                    hideTitle: true
                                },
                                props: true,
                            }
                        ]
                    }
                ]
            }, {
                path: 'account-add',
                name: 'account-add',
                component: accountAdd,
                meta: {
                    title: 'Добавление брокерского счета',
                    breadcrumb(route) {
                        return {
                            label: 'Добавление брокерского счета',
                            link: `${route.path}`
                        }
                    },
                    requiresAuth: true
                },
                props: true,
            }, {
                path: 'accounts-demo',
                name: 'accounts-demo',
                component: accountsDemo,
                meta: {
                    title: 'Демонстрационные брокерские счета',
                    breadcrumb(route) {
                        return {
                            label: 'Демонстрационные счета',
                            link: `${route.path}`
                        }
                    },
                    requiresAuth: true
                },
                children: [
                    {
                        path: ':portfolioId',
                        name: 'account-demo',
                        component: account,
                        meta: {
                            title: 'Демо брокерский счет',
                            breadcrumb(route) {
                                return {
                                    label: 'Счет',
                                    link: `${route.path}`
                                }
                            },
                            requiresAuth: true
                        },
                        props: true,
                        redirect: { name: 'account-info-demo' },
                        children: [
                            {
                                path: 'account-edit',
                                name: 'account-edit-demo',
                                component: accountAdd,
                                meta: {
                                    title: 'Редактирование брокерского счета',
                                    hideButtons: true,
                                    requiresAuth: true
                                },
                                props: true,
                            }, {
                                path: 'account-info',
                                name: 'account-info-demo',
                                component: accountInfo,
                                meta: {
                                    title: 'Сводная информация по счету',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Сводная информация по счету',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true
                                },
                                props: true,
                            }, {
                                path: 'account-history',
                                name: 'account-history-demo',
                                component: accountHistory,
                                meta: {
                                    title: 'История сделок',
                                    breadcrumb(route) {
                                        return {
                                            label: 'История сделок',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true
                                },
                                props: true,
                            }, {
                                path: 'account-coupons',
                                name: 'account-coupons-demo',
                                component: accountCoupons,
                                meta: {
                                    title: 'Купоны',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Купоны',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true
                                },
                                props: true,
                                redirect: { name: 'account-coupons-future-demo' },
                                children: [
                                    {
                                        path: 'future',
                                        name: 'account-coupons-future-demo',
                                        component: accountCouponsFuture,
                                        meta: {
                                            title: 'Будущие купоны',
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Будущие купоны',
                                                    link: `${route.path}`
                                                }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }, {
                                        path: 'payments',
                                        name: 'account-coupons-payments-demo',
                                        component: accountCouponsPayments,
                                        meta: {
                                            title: 'Анализ прошлых выплат',
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Анализ прошлых выплат',
                                                    link: `${route.path}`
                                                }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }
                                ]
                            }, {
                                path: 'account-analytic',
                                name: 'account-analytic-demo',
                                component: accountAnalytic,
                                meta: {
                                    title: 'Аналитика портфеля',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Аналитика портфеля',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true
                                },
                                props: true,
                                redirect: { name: 'account-analytic-flow-demo' },
                                children: [
                                    {
                                        path: 'flow',
                                        name: 'account-analytic-flow-demo',
                                        component: accountAnalyticFlow,
                                        meta: {
                                            title: 'Движение денежных средств',
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Движение денежных средств',
                                                    link: `${route.path}`
                                                }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }, {
                                        path: 'purchased',
                                        name: 'account-analytic-purchased-demo',
                                        component: accountAnalytic,
                                        meta: {
                                            title: 'Анализ купленных облигаций',
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Анализ купленных облигаций',
                                                    link: `${route.path}`
                                                }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    },
                                ]
                            }, {
                                path: 'account-settings',
                                name: 'account-settings-demo',
                                component: accountSettings,
                                meta: {
                                    title: 'Настройки брокерского счета',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Настройки',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true,
                                    hideButtons: true,
                                    hideTitle: true
                                },
                                props: true,
                                redirect: { name: 'account-settings-main-demo' },
                                children: [
                                    {
                                        path: 'main',
                                        name: 'account-settings-main-demo',
                                        component: accountSettingsMain,
                                        meta: {
                                            title: 'Общие настройки',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Общие',
                                                    link: `${route.path}`
                                                }
                                            },
                                        },
                                        props: true,
                                    }, {
                                        path: 'public',
                                        name: 'account-settings-public-demo',
                                        component: accountSettingsPublic,
                                        meta: {
                                            title: 'Публичный доступ',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Публичный доступ',
                                                    link: `${route.path}`
                                                }
                                            },
                                        },
                                        props: true,
                                    }, {
                                        path: 'trust',
                                        name: 'account-settings-trust-demo',
                                        component: accountSettingsTrust,
                                        meta: {
                                            title: 'Доверительное управление',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Доверительное управление',
                                                    link: `${route.path}`
                                                }
                                            },
                                        },
                                        props: true,
                                    }, {
                                        path: 'import',
                                        name: 'account-settings-import-demo',
                                        component: accountSettingsImport,
                                        meta: {
                                            title: 'Импорт сделок',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Импорт сделок',
                                                    link: `${route.path}`
                                                }
                                            },
                                        },
                                        props: true,
                                    }
                                ]
                            }, {
                                path: 'assets/:ticker',
                                name: 'account-assets-demo',
                                component: assets,
                                meta: {
                                    title: 'Актив',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Актив',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true,
                                    hideButtons: true,
                                    hideTitle: true
                                },
                                props: true,
                            }
                        ]
                    }
                ]
            }, {
                path: 'public',
                name: 'public-accounts',
                component: publicAccounts,
                meta: {
                    title: 'Публичные брокерские счета',
                    breadcrumb(route) {
                        return {
                            label: 'Публичные счета',
                            link: `${route.path}`
                        }
                    },
                    requiresAuth: true
                },
                props: false,
                children: [
                    {
                        path: ':portfolioId',
                        name: 'public',
                        component: account,
                        meta: {
                            title: 'Публичный брокерский счет',
                            breadcrumb(route) {
                                return {
                                    label: 'Счет',
                                    link: `${route.path}`
                                }
                            },
                            requiresAuth: true,
                            hideButtons: true,
                        },
                        props: route => ({ portfolioType: 'public', portfolioId: route.params.portfolioId }),
                    }
                ]
            }, {
                path: 'trusts',
                name: 'trust-management',
                component: trustManagment,
                meta: {
                    title: 'Доверительное управление',
                    breadcrumb(route) {
                        return {
                            label: 'Доверительное управление',
                            link: `${route.path}`
                        }
                    },
                    requiresAuth: true
                },
                props: false,
                children: [
                    {
                        path: 'trust/:portfolioId',
                        name: 'trust',
                        component: account,
                        meta: {
                            title: 'Брокерский счет',
                            breadcrumb(route) {
                                return {
                                    label: 'Счет',
                                    link: `${route.path}`
                                }
                            },
                            requiresAuth: true
                        },
                        props: route => ({ portfolioType: 'trust', portfolioId: route.params.portfolioId }),
                        redirect: { name: 'trust-info' },
                        children: [
                            {
                                path: 'account-edit',
                                name: 'trust-edit',
                                component: accountAdd,
                                meta: {
                                    title: 'Редактирование брокерского счета',
                                    hideButtons: true,
                                    requiresAuth: true
                                },
                                props: { portfolioType: 'trust' },
                            }, {
                                path: 'account-info',
                                name: 'trust-info',
                                component: accountInfo,
                                meta: {
                                    title: 'Сводная информация по счету',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Сводная информация по счету',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true
                                },
                                props: { portfolioType: 'trust' },
                            }, {
                                path: 'account-history',
                                name: 'trust-history',
                                component: accountHistory,
                                meta: {
                                    title: 'История сделок',
                                    breadcrumb(route) {
                                        return {
                                            label: 'История сделок',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true
                                },
                                props: { portfolioType: 'trust' },
                            }, {
                                path: 'account-coupons',
                                name: 'trust-coupons',
                                component: accountCoupons,
                                meta: {
                                    title: 'Купоны',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Купоны',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true
                                },
                                props: { portfolioType: 'trust' },
                                redirect: { name: 'trust-coupons-future' },
                                children: [
                                    {
                                        path: 'future',
                                        name: 'trust-coupons-future',
                                        component: accountCouponsFuture,
                                        meta: {
                                            title: 'Будущие купоны',
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Будущие купоны',
                                                    link: `${route.path}`
                                                }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }, {
                                        path: 'payments',
                                        name: 'trust-coupons-payments',
                                        component: accountCouponsPayments,
                                        meta: {
                                            title: 'Анализ прошлых выплат',
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Анализ прошлых выплат',
                                                    link: `${route.path}`
                                                }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }
                                ]
                            }, {
                                path: 'account-analytic',
                                name: 'trust-analytic',
                                component: accountAnalytic,
                                meta: {
                                    title: 'Аналитика портфеля',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Аналитика портфеля',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true
                                },
                                props: { portfolioType: 'trust' },
                                redirect: { name: 'trust-analytic-flow' },
                                children: [
                                    {
                                        path: 'flow',
                                        name: 'trust-analytic-flow',
                                        component: accountAnalyticFlow,
                                        meta: {
                                            title: 'Движение денежных средств',
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Движение денежных средств',
                                                    link: `${route.path}`
                                                }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    }, {
                                        path: 'purchased',
                                        name: 'trust-analytic-purchased',
                                        component: accountAnalytic,
                                        meta: {
                                            title: 'Анализ купленных облигаций',
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Анализ купленных облигаций',
                                                    link: `${route.path}`
                                                }
                                            },
                                            requiresAuth: true
                                        },
                                        props: true,
                                    },
                                ]
                            }, {
                                path: 'account-settings',
                                name: 'trust-settings',
                                component: accountSettings,
                                meta: {
                                    title: 'Настройки брокерского счета',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Настройки',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true,
                                    hideButtons: true,
                                    hideTitle: true
                                },
                                props: { portfolioType: 'trust' },
                                redirect: { name: 'trust-settings-main' },
                                children: [
                                    {
                                        path: 'main',
                                        name: 'trust-settings-main',
                                        component: accountSettingsMain,
                                        meta: {
                                            title: 'Общие настройки',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Общие',
                                                    link: `${route.path}`
                                                }
                                            },
                                        },
                                        props: { portfolioType: 'trust' },
                                    }, {
                                        path: 'public',
                                        name: 'trust-settings-public',
                                        component: accountSettingsPublic,
                                        meta: {
                                            title: 'Публичный доступ',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Публичный доступ',
                                                    link: `${route.path}`
                                                }
                                            },
                                        },
                                        props: { portfolioType: 'trust' },
                                    }, {
                                        path: 'trust',
                                        name: 'trust-settings-trust',
                                        component: accountSettingsTrust,
                                        meta: {
                                            title: 'Доверительное управление',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Доверительное управление',
                                                    link: `${route.path}`
                                                }
                                            },
                                        },
                                        props: { portfolioType: 'trust' },
                                    }, {
                                        path: 'import',
                                        name: 'trust-settings-import',
                                        component: accountSettingsImport,
                                        meta: {
                                            title: 'Импорт сделок',
                                            requiresAuth: true,
                                            breadcrumb(route) {
                                                return {
                                                    label: 'Импорт сделок',
                                                    link: `${route.path}`
                                                }
                                            },
                                        },
                                        props: { portfolioType: 'trust' },
                                    }
                                ]
                            }, {
                                path: 'assets/:ticker',
                                name: 'trust-assets',
                                component: assets,
                                meta: {
                                    title: 'Актив',
                                    breadcrumb(route) {
                                        return {
                                            label: 'Актив',
                                            link: `${route.path}`
                                        }
                                    },
                                    requiresAuth: true,
                                    hideButtons: true,
                                    hideTitle: true
                                },
                                props: route => ({ portfolioType: 'trust', ticker: route.params.ticker }),
                            }
                        ]
                    }
                ]
            }, {
                path: 'bond-screener',
                name: 'bond-screener',
                component: bondScreener,
                meta: {
                    title: 'Все облигации',
                    breadcrumb(route) {
                        return {
                            label: 'Скринер облигаций',
                            link: `${route.path}`
                        }
                    },
                    requiresAuth: true
                },
                props: false,
                children: [
                    {
                        path: ':slug',
                        name: 'bond',
                        component: bond,
                        meta: {
                            title: 'Облигация',
                            breadcrumb(route) {
                                return {
                                    label: 'Облигация',
                                    link: `${route.path}`
                                }
                            },
                            requiresAuth: true
                        },
                        props: true,
                    }
                ]
            }, {
                path: 'bond-lists',
                name: 'bond-lists',
                component: bondLists,
                meta: {
                    title: 'Watchlist (списки ценных бумаг для портфелей)',
                    breadcrumb(route) {
                        return {
                            label: 'Watchlist',
                            link: `${route.path}`
                        }
                    },
                    requiresAuth: true
                },
                props: false,
                children: [
                    {
                        path: 'bond-lists/:watchlistId',
                        name: 'bond-screener-list',
                        component: bondList,
                        meta: {
                            title: 'Список',
                            breadcrumb(route) {
                                return {
                                    label: 'Список',
                                    link: `${route.path}`
                                }
                            },
                            requiresAuth: true
                        },
                        props: true,
                    }
                ]
            }, {
                path: 'emitters/:inn',
                name: 'emitter',
                component: emitter,
                meta: {
                    title: 'Эмитент',
                    breadcrumb(route) {
                        return {
                            label: 'Эмитент',
                            link: `${route.path}`
                        }
                    },
                    requiresAuth: true
                },
                props: true,
            }, {
                path: 'investment-planning',
                name: 'investment-planning',
                component: empty,
                meta: { title: 'Планирование инвестиций', requiresAuth: true },
                props: true,
            }, {
                path: 'decisionmaking',
                name: 'decisionmaking',
                component: empty,
                meta: { title: 'Система принятия решений', requiresAuth: true },
                props: true,
            }, {
                path: 'demo-portfolio',
                name: 'demo-portfolio',
                component: empty,
                meta: { title: 'Демо-портфель', requiresAuth: true },
                props: true,
            }, {
                path: 'companies',
                name: 'companies',
                component: empty,
                meta: { title: 'Каталог компаний', requiresAuth: true },
                props: true,
            }
        ]
    }, {
        path: '/profile',
        name: 'profile',
        component: profile,
        meta: {
            title: 'Настройки профиля',
            breadcrumb: 'Настройки профиля',
            requiresAuth: true
        },
        props: true,
        redirect: { name: 'profile-user' },
        children: [
            {
                path: 'profile-user',
                name: 'profile-user',
                component: profileUser,
                meta: {
                    title: 'Профиль пользователя',
                    breadcrumb: 'Профиль пользователя',
                    requiresAuth: true
                },
                props: true,
            /*
            }, {
                path: 'profile-accounts',
                name: 'profile-accounts',
                component: profileAccounts,
                meta: {
                    title: 'Брокерские счета',
                    breadcrumb: 'Брокерские счета',
                    requiresAuth: true
                },
                props: true,
            */
            }, {
                path: 'profile-currencies',
                name: 'profile-currencies',
                component: profileCurrencies,
                meta: {
                    title: 'Настройка валют',
                    breadcrumb: 'Настройка валют',
                    requiresAuth: true
                },
                props: true,
            }, {
                path: 'profile-tariffs/:result?',
                name: 'profile-tariffs',
                component: profileTariffs,
                meta: {
                    title: 'Тариф',
                    breadcrumb: 'Тариф',
                    requiresAuth: true
                },
                props: true,
            }
        ]
    }, {
        path: '/messages',
        name: 'messages',
        component: messages,
        meta: {
            title: 'Сообщения',
            breadcrumb: 'Сообщения',
            requiresAuth: true
        },
        props: true,
        redirect: { name: 'messages-notifications' },
        children: [
            {
                path: 'notifications',
                name: 'messages-notifications',
                component: messagesNotifications,
                meta: {
                    title: 'Уведомления',
                    breadcrumb: 'Уведомления',
                    requiresAuth: true
                },
                props: true,
            }, {
                path: 'reminders',
                name: 'messages-reminders',
                component: messagesReminders,
                meta: {
                    title: 'Напоминания',
                    breadcrumb: 'Напоминания',
                    requiresAuth: true
                },
                props: true,
            }
        ]
    }, {
        path: '/support',
        name: 'support',
        component: support,
        meta: {
            title: 'Помощь',
            breadcrumb: 'Помощь',
            requiresAuth: true
        },
        props: true,
    }, {
        path: '/info/:slug',
        name: 'page',
        component: page,
        meta: {
            title: 'Страница',
            breadcrumb: 'Страница',
            requiresAuth: true
        },
        props: true,
    }, {
        path: '/empty',
        name: 'empty',
        component: empty,
        meta: { title: 'В разработке', requiresAuth: true },
        props: true
    }, {
        path: '/:pathMatch(.*)*',
        name: 'page404',
        component: page404,
        meta: { title: 'Ошибка 404', requiresAuth: true },
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'is-subactive',
    linkExactActiveClass: 'is-active',
    routes
});

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.user && store.state.user.id) {
            next();
        } else {
            next({ name: 'auth' });
        }
    } else {
        next();
        /*
        if (store.state.user && store.state.user.id && to.name === 'home') {
            next({ name: 'home' });
        } else {
            next();
        }
        */
    }
});

export default router;
