import { createStore } from 'vuex';
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
    key: 'finprogress025',
    storage: window.localStorage
});

const store = createStore({
    state: {
        user: {},
        error: null,
        loader: null,
        alert: null,
        access: null,
        refresh: null,
        currencyCurrent: null,
        sidebarKey: Math.floor(Math.random() * 100000),
        //currencyList: []
    },
    plugins: [vuexPersist.plugin],
    mutations: {
        USER(state, user) {
            state.user = user;
        },
        TOKENS(state, tokens) {
            state.access = tokens.access;
            state.refresh = tokens.refresh;
        },
        CURRENCY(state, currency) {
            state.currencyCurrent = currency;
        },
        CURRENCY_LIST(state, currencies) {
            state.currencyList = currencies;
        },
        MODE(state, mode) {
            state.mode = mode;
        },
        ERROR(state, error) {
            state.error = error;
        },
        LOADER(state, loader) {
            state.loader = loader;
        },
        ALERT(state, alert) {
            state.alert = alert;
        },
        SIDEBAR(state, key) {
            state.sidebarKey = key;
        }
    },
    actions: {
        setUser(context, user) {
            context.commit('USER', user);
        },
        clearUser(context) {
            context.commit('USER', {});
        },
        setToken(context, tokens) {
            context.commit('TOKENS', tokens);
        },
        clearToken(context) {
            context.commit('TOKENS', {});
        },
        setCurrency(context, currency) {
            context.commit('CURRENCY', currency);
        },
        setCurrencyList(context, currencies) {
            context.commit('CURRENCY_LIST', currencies);
        },
        setMode(context, mode) {
            context.commit('MODE', mode);
        },
        clearMode(context) {
            context.commit('MODE', null);
        },
        showError(context, error) {
            if (error.response?.status !== 401) {
                context.commit('ERROR', error);
            }
        },
        hideError(context) {
            context.commit('ERROR', null);
        },
        showLoader(context, loader) {
            context.commit('LOADER', loader);
        },
        hideLoader(context) {
            context.commit('LOADER', null);
        },
        showAlert(context, alert) {
            context.commit('ALERT', alert);
        },
        hideAlert(context) {
            context.commit('ALERT', null);
        },
        refreshSidebarKey(context) {
            context.commit('SIDEBAR', Math.floor(Math.random() * 100000));
        },
    }
});

export default store;