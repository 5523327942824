<template>
    <b-dropdown
        v-if="accessAdd"
        variant="light"
        toggleClass="account__buttons-circle rounded-pill shadow"
        class="ms-3 mb-2"
        menuClass="mt-2"
        no-caret
    >
        <template #button-content>
            <div :id="`popover-block-deals-add-${portfolioId}`" class="d-flex align-items-center justify-content-center w-100 h-100">
                <b-icon-plus-lg />
            </div>
        </template>
        <b-dropdown-item-button
            variant=""
            @click.prevent="showDealAdd()"
        >
            Добавить сделку с ценными бумагами
        </b-dropdown-item-button>
        <b-dropdown-item-button
            variant=""
            @click.prevent="showCashDealAdd()"
        >
            Добавить сделку с денежными средствами
        </b-dropdown-item-button>
        <b-dropdown-item-button
            variant=""
            @click.prevent="$router.push({ name: `${portfolioType}-settings-import${routeSuffix}`, params: { portfolioId: portfolioId } })"
        >
            <template v-if="portfolio.broker_key === 'tinkoff'">
                Обновить данные о сделках (Тинькофф API)
            </template>
            <template v-else>
                Загрузить отчет брокера
            </template>
        </b-dropdown-item-button>
    </b-dropdown>
    <b-popover
        custom-class="shadow"
        :target="`popover-block-deals-add-${portfolioId}`"
        triggers="hover"
        placement="top"
    >
        <div>Добавление сделок брокерского счета</div>
    </b-popover>
    <b-button
        v-if="(!this.portfolio.access || this.portfolio.access === 'edit') && portfolioType === 'account'"
        :id="`popover-block-settings-${portfolioId}`"
        variant="light"
        class="account__buttons-circle ms-3 mb-2 shadow"
        pill
        @click.prevent="$router.push({ name: `${portfolioType}-settings${routeSuffix}`, params: { portfolioId: portfolioId } })"
    >
        <b-icon-gear></b-icon-gear>
    </b-button>
    <b-popover
        custom-class="shadow"
        :target="`popover-block-settings-${portfolioId}`"
        triggers="hover"
        placement="top"
    >
        <div>Настройки брокерского счета</div>
    </b-popover>
    <b-button
        :id="`popover-block-reminder-${portfolioId}`"
        variant="light"
        class="account__buttons-circle ms-3 mb-2 shadow"
        pill
        @click.stop="showAddReminder()"
    >
        <b-icon-bell-fill />
    </b-button>
    <b-popover
        custom-class="shadow"
        :target="`popover-block-reminder-${portfolioId}`"
        triggers="hover"
        placement="top"
    >
        <div>Добавить уведомление</div>
    </b-popover>
    <ModalReminderAdd
        :portfolioId="portfolioId"
        :portfolioType="portfolioType"
        :show="showModalAddReminder"
        @hideModal="hideAddReminder"
    />
    <ModalDealAdd
        :show="showModalDealAdd"
        :portfolioId="portfolioId"
        @hideModal="hideDealAdd"
    />
    <ModalCashDealAdd
        :show="showModalCashDealAdd"
        :portfolioId="portfolioId"
        @hideModal="hideCashDealAdd"
    />
</template>

<script>
    import ModalDealAdd from "@/components/modals/modal-deal-add";
    import ModalCashDealAdd from "@/components/modals/modal-cash-deal-add";
    import ModalReminderAdd from "@/components/modals/modal-reminder-add";
    export default {
        components: {
            ModalDealAdd,
            ModalCashDealAdd,
            ModalReminderAdd
        },
        emits: ['refreshPortfolio'],
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            accessAdd() {
                const rules = this.$store.state.user?.tariff?.rules || {};
                if (!this.portfolio.access || this.portfolio.access === 'edit') {
                    if (rules && rules.deal_add) {
                        if (rules.deal_add.limit === 0 || rules.deal_add.limit >= this.portfolio.ordering) return true;
                        return false;
                    }
                    return false;
                }
                return false;
            }
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            routeSuffix: {
                type: String,
                default() { return ''; }
            },
        },
        data() {
            return {
                showModalDealAdd: false,
                showModalCashDealAdd: false,
                showModalAddReminder: false,
            }
        },
        methods: {
            showDealAdd() {
                this.showModalDealAdd = true;
            },
            hideDealAdd(refreshData) {
                this.showModalDealAdd = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.refreshPortfolio();
                    });
                }
            },
            showCashDealAdd() {
                this.showModalCashDealAdd = true;
            },
            hideCashDealAdd(refreshData) {
                this.showModalCashDealAdd = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.refreshPortfolio();
                    });
                }
            },
            showAddReminder() {
                this.showModalAddReminder = true;
            },
            hideAddReminder() {
                this.showModalAddReminder = false;
            },
            refreshPortfolio() {
                console.log('refreshPortfolio');
                this.$emit('refreshPortfolio');
            },
        }
    };
</script>
