<template>
    <div class="app__block bond-list d-flex h-100 flex-column px-4 pt-3 pb-4">
        <BlockUserCurrencies/>
        <template v-if="!showLoaderMain">
            <div class="app__block-title bond-list__title m--help">
                <h1 class="mb-3">Список «{{ list.name }}»</h1>
                <div class="bond-list__title-info mb-4">
                    <div class="bond-list__title-currency badge rounded-pill me-3">{{ list.currency_iso_name }}, {{ list.currency_symbol }}</div>
                    Облигации
                </div>
                <router-link
                    :to="{ name: 'page', params: { slug: 'help_watchlist' }}"
                    custom
                    v-slot="{ href }"
                >
                    <a
                        :href="href"
                        class="app__block-help"
                        @click.prevent="showPage('help_watchlist')"
                    >
                        <b-icon-question-circle-fill />
                    </a>
                </router-link>
            </div>
            <div class="bond-list__buttons row mb-3">
                <div class="col-12 col-xl d-flex flex-wrap mb-2">
                    <template
                        v-for="item in menuTabs"
                        :key="item.name"
                    >
                        <b-button
                            v-if="item.show"
                            :variant="(item.name === currentMenuTab) ? 'outline-primary' : 'outline-primary'"
                            :active="item.name === currentMenuTab"
                            :disabled="item.disabled"
                            class="bond-list__buttons-item me-3 mb-2"
                            size=""
                            pill
                            @click.stop="changeMenuTab(item.name)"
                        >
                            {{ item.title }}
                        </b-button>
                    </template>
                </div>
            </div>
            <div class="bond-list__block row mb-5">
                <BlockInfo
                    title="Доход 1. ПЛАН. Купоны / Амортизация"
                    valueLabel="С текущей даты, за 12 месяцев"
                    :value="`${$helpers.toPrice(list.coupon_income, { sign: list.currency_symbol, pointer: ',' })} / ${$helpers.toPrice(list.amortization_amount, { sign: list.currency_symbol, pointer: ',' })}`"
                    classMain="bond-list__block-item"
                    helper="bond-list_1"
                />

                <BlockInfo
                    title="Доход 1. ПЛАН. Средний % год"
                    :value="list.average_year_income_percent"
                    valueToPercent
                    classMain="bond-list__block-item"
                    helper="bond-list_4"
                />


                <BlockInfo
                    title="Доход 2. ПЛАН"
                    :value="list.close_income"
                    valueToPrice
                    valueUseSign
                    :subvalue="list.close_income_percent"
                    subvalueToPercent
                    subvalueUseSign
                    classMain="bond-list__block-item"
                    :classColor="`text-${list.close_income >= 0 ? 'success' : 'danger'}`"
                    helper="bond-list_close_income"
                />

                <BlockInfo
                    title="Сумма покупки"
                    valueLabel="без НКД"
                    :value="list.purchase_amount"
                    valueToPrice
                    subvalueLabel="с учетом НКД"
                    :subvalue="list.purchase_amount_aci"
                    subvalueToPrice
                    classMain="bond-list__block-item"
                    helper="bond-list_3"
                />

                <BlockInfo
                    title="Сумма при погашении"
                    :value="list.repayment_sum"
                    valueToPrice
                    classMain="bond-list__block-item"
                    helper="bond-list_repayment_sum"
                />

                <BlockInfo
                    title="Доход к погашению (YTM), %"
                    :value="list.average_ytm_percent"
                    valueToPercent
                    classMain="bond-list__block-item"
                    helper="bond-list_average_ytm_percent"
                />

                <BlockInfo
                    :title="`Налог с купонов на 31.12.${new Date().getFullYear()}`"
                    valueLabel="13 %"
                    :value="list.resident_tax_amount"
                    valueToPrice
                    subvalueLabel="30 %"
                    :subvalue="list.non_resident_tax_amount"
                    subvalueToPrice
                    classMain="bond-list__block-item"
                    helper="bond-list_2"
                />

                <!--BlockInfo
                    title="Общий Доход. ПЛАН"
                    :value="list.total_income"
                    valueToPrice
                    classMain="bond-list__block-item"
                    helper="bond-list_total_income"
                /-->
            </div>
            <template v-if="currentMenuTab === 'actives'">
                <BlockBondListActives
                    :watchlistId="watchlistId"
                    @getWatchList="getWatchList"
                />
            </template>
            <template v-else-if="currentMenuTab === 'charts'">
                <BlockBondListCharts
                    :watchlistId="watchlistId"
                />
            </template>
        </template>
        <template v-else>
            <div class="app__loader">
                <b-spinner
                    variant="success"
                    class="me-3"
                />
                <span class="text-success">Загрузка данных ...</span>
            </div>
        </template>
        <ModalPage
            :slug="ModalPageSlug"
            :show="showModalPage"
            @hideModal="hidePage"
        />
    </div>
</template>

<script>
    import { app } from "@/services";

    import BlockInfo from "@/components/block-info";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    import BlockBondListActives from "@/components/block-bond-list-actives";
    import BlockBondListCharts from "@/components/block-bond-list-charts";
    //import Helper from "@/components/helper";
    import ModalPage from "@/components/modals/modal-page";

    export default {
        name: 'bondList',
        components: {
            BlockInfo,
            BlockUserCurrencies,
            BlockBondListActives,
            BlockBondListCharts,
            //Helper,
            ModalPage
        },
        props: {
            watchlistId: {
                type: [Number, String],
                default() { return null; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        watch: {
        },
        data() {
            return {
                menuTabs: [{
                    name: 'actives',
                    title: 'Активы',
                    icon: null,
                    show: true,
                }, {
                    name: 'charts',
                    title: 'График выплат',
                    icon: null,
                    show: true,
                }],
                currentMenuTab: 'actives',
                bonds: [],
                item: {},
                list: {},
                showLoaderMain: false,
                ModalPageSlug: null,
                showModalPage: false,
            };
        },
        created() {
        },
        mounted() {
            this.getWatchList();
        },
        methods: {
            getWatchList(lazy = false, params = {}) {
                if (!lazy) this.showLoaderMain = true;
                params.watchlistId = this.watchlistId;
                delete params.ordering;
                app.getWatchList(params).then(res => {
                    if (!res.error) {
                        this.list = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.$breadcrumbs.value.at(-1).label = this.list.name;
                    this.showLoaderMain = false;
                }).catch(err => {
                    this.showLoaderMain = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            changeMenuTab(name) {
                this.currentMenuTab = name;
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
        }
    };
</script>
