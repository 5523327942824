<template>
    <b-modal
        id="modal-account-delete"
        v-model="showModal"
        classes="modal__container" 
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Удаление информации о БС"
        centered
        hide-footer
        scrollable
        @hidden="hideModal"
    >
        <div class="modal__content auth__form">

            <template
                v-if="accountDeleted"
            >
                <b-alert
                    class="mb-3"
                    variant="success"
                    show
                >
                    <div class="form__content">
                        {{ accountDeleted }}
                    </div>
                </b-alert>
            </template>
            <template
                v-else-if="formErrors.detail"
            >
                <b-alert
                    class="mb-3"
                    variant="danger"
                    show
                >
                    <div class="form__content">
                        {{ formErrors.detail }}
                    </div>
                </b-alert>
            </template>
            <template
                v-else
            >
                <b-form
                    class="form d-flex flex-column"
                    @submit="onSubmit"
                >
                    <div class="form__content mb-3">
                        Вы удаляете информацию о брокерском счете <strong>«{{ portfolio.name }}»</strong> из системы "Капитал". Данные у брокера затронуты не будут.
                    </div>
                    <b-button 
                        type="submit" 
                        variant="success"
                        class="d-block mx-auto"
                        pill
                    >
                        Подтвердить
                    </b-button>
                    <b-overlay 
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-form>                
            </template>
        </div>
        <b-overlay 
            :show="showLoaderModal"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-modal>    
</template>

<script>
    import { app } from "@/services";
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        //computed: {
        //    showModal() {
        //        return this.show;
        //    },
        //},
        props: {
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            show: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                formData: {
                    id: this.portfolio.id,
                },
                formErrors: {},
                showModal: this.show,
                showLoaderModal: false,
                showLoaderSending: false,
                accountDeleted: '',
                refreshData: false,
            };
        },
        created() {
        },
        methods: {
            onSubmit() {
                this.formErrors = {};
                let params = this.formData;
                console.log(params);
                this.showLoaderSending = true;
                app.deletePortfolio(params).then(res => {
                    this.showLoaderSending = false;
                    this.accountDeleted = res.detail || 'Портфель успешно удален!';
                    console.log(res);
                    this.refreshData = true;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response);
                        this.formErrors = err.response.data.error;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            hideModal() {
                this.accountDeleted = '';
                this.$emit('hideModal', this.refreshData);
            },
        }
    };
</script>
