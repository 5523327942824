<template>
    <b-modal
        id="modal-managment-edit"
        v-model="showModal"
        size="lg"
        classes="modal__container"
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Изменеие комментария"
        centered
        hide-footer
        scrollable
        @hidden="hideModal()"
    >
        <div class="modal__content auth__form">
            <template
                v-if="managmentEdited"
            >
                <b-alert
                    class="mb-3"
                    variant="success"
                    show
                >
                    <div class="form__content">
                        {{ managmentEdited }}
                    </div>
                </b-alert>
            </template>
            <template
                v-else-if="formErrors.detail"
            >
                <b-alert
                    class="mb-3"
                    variant="danger"
                    show
                >
                    <div class="form__content">
                        {{ formErrors.detail }}
                    </div>
                </b-alert>
            </template>
            <template
                v-else
            >
                <b-form
                    class="form d-flex flex-column"
                    @submit="onSubmit"
                >
                    <div class="form__content mb-3">
                        Изменение комментари к портфелю <strong>{{ portfolio.trust_name || portfolio.portfolio_name }}</strong>.
                    </div>
                    <b-form-group
                        id="input-group-comment"
                        label="Комментарий управляющего"
                        class="col-12"
                    >
                        <b-form-textarea
                            id="input-comment"
                            v-model="formData.comment"
                            placeholder="При необходимости введите ваш комментарий"
                            size="sm"
                            class="shadow-sm m--no-pill"
                            :disabled="showLoaderSending"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-button
                        type="submit"
                        variant="success"
                        class="d-block mx-auto"
                        size="lg"
                        pill
                    >
                        Изменить
                    </b-button>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-form>
            </template>
        </div>
        <b-overlay
            :show="showLoaderModal"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-modal>
</template>

<script>
    import { app } from "@/services";
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        //computed: {
        //    showModal() {
        //        return this.show;
        //    },
        //},
        props: {
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            show: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                formData: {},
                formErrors: {},
                showModal: this.show,
                showLoaderModal: false,
                showLoaderSending: false,
                managmentEdited: '',
                refreshData: false,
            };
        },
        created() {
        },
        mounted() {
            this.formData = {
                trust_id: this.portfolio.id,
                comment: this.portfolio.trust_user_comment,
            };
        },
        methods: {
            onSubmit() {
                this.formErrors = {};
                let params = this.formData;
                console.log(params);
                this.showLoaderSending = true;
                app.updateTrust(params).then(res => {
                    this.showLoaderSending = false;
                    this.managmentEdited = 'Информация успешно обновлена!';
                    console.log(res);
                    this.refreshData = true;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response);
                        this.formErrors = err.response.data.error;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            hideModal() {
                this.formErrors = {};
                this.managmentEdited = '';
                this.$emit('hideModal', this.refreshData);
            },
        }
    };
</script>
