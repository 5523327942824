import { createApp } from 'vue'
import * as Sentry from '@sentry/vue';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import '@vuepic/vue-datepicker/dist/main.css';
import breadcrumbs from 'vue-3-breadcrumbs';
import BootstrapVue3 from 'bootstrap-vue-3';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import { vMaska } from 'maska';
//import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import VueDatePicker from '@vuepic/vue-datepicker';
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from 'highcharts-vue';
import VueYandexMetrika from 'vue3-yandex-metrika';

import router from './router/router';
import store from './store/store';
import helpers from './utils/helpers';

import App from './App.vue'

const app = createApp(App);

Sentry.init({
    app,
    dsn: 'https://7c9eb1aa83254b32b98faf69151540cc@glitchtip.flexidev.ru/14',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(BootstrapVue3);
app.use(BootstrapIconsPlugin);
app.component('VueDatePicker', VueDatePicker);
Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        exportButtonTitle: "Экспорт",
        printButtonTitle: "Печать",
        rangeSelectorFrom: "С",
        rangeSelectorTo: "По",
        rangeSelectorZoom: "Период",
        downloadPNG: 'Скачать PNG',
        downloadJPEG: 'Скачать JPEG',
        downloadPDF: 'Скачать PDF',
        downloadSVG: 'Скачать SVG',
        printChart: 'Напечатать график'
    },
});
Stock(Highcharts);
app.use(HighchartsVue);
//app.use(Maska);
app.directive('maska', vMaska);
app.use(store);
app.use(router);
app.use(breadcrumbs, {
    includeComponent: true // {boolean} [includeComponent=false] - Include global breadcrumbs component or not
});
app.use(helpers);
app.use(VueYandexMetrika, {
    id: 93219323,
    router: router,
    env: process.env.NODE_ENV,
    debug: false,
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
    }
});

app.mount('#app');
