<template>
    <b-modal
        id="modal-password-reset"
        v-model="showModal"
        classes="modal__container"
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Сброс пароля"
        centered
        hide-footer
        scrollable
        @hidden="hideModal"
    >
        <div class="modal__content auth__form">

            <template
                v-if="passwordSended"
            >
                <div class="form__content mb-3">
                    {{ passwordSended }}
                </div>
                <b-button
                    type="submit"
                    variant="success"
                    class="d-block col-12"
                    size="lg"
                    pill
                    @click.stop="showAuth"
                >
                    Войти
                </b-button>
            </template>
            <template
                v-else
            >
                <b-form
                    class="form"
                    @submit="onSubmit"
                >
                    <div class="form__content mb-3">
                        Введите email, указанный при регистрации. Мы отправим вам пароль, который вы сможете изменить в личном профиле после авторизации.
                    </div>
                    <b-form-group
                        id="input-group-email"
                        label=""
                        class="mb-4"
                    >
                        <b-form-input
                            id="input-email"
                            v-model="form.email"
                            type="email"
                            placeholder="E-mail"
                            required
                            size="lg"
                            :state="formErrors && formErrors.email ? false : null"
                        ></b-form-input>
                        <b-form-invalid-feedback
                            class="text-center"
                            :state="formErrors && formErrors.detail ? false : null"
                        >
                            {{ formErrors ? formErrors.detail : '' }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-button
                        type="submit"
                        variant="success"
                        class="d-block col-12"
                        size="lg"
                        pill
                    >
                        Сбросить пароль
                    </b-button>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-form>
            </template>
        </div>
        <b-overlay
            :show="showLoaderModal"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-modal>
</template>

<script>
    import { app } from "@/services";
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        //computed: {
        //    showModal() {
        //        return this.show;
        //    },
        //},
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            }
        },
        data() {
            return {
                form: {
                    email: '',
                },
                formErrors: {},
                showModal: this.show,
                showLoaderModal: false,
                showLoaderSending: false,
                passwordSended: '',
            };
        },
        created() {
        },
        methods: {
            showAuth() {
                this.hideModal();
                this.$emit('showAuth');
            },
            onSubmit() {
                this.formErrors = {};
                let params = this.form;
                this.showLoaderSending = true;
                app.resetUser(params).then(res => {
                    this.showLoaderSending = false;
                    this.passwordSended = res.detail;
                    console.log(res);
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response);
                        this.formErrors = err.response.data.error;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            hideModal() {
                this.$emit('hideModal');
            },
            next() {
                //this.$router.push({ name: 'home' });
                //this.$router.push({ name: 'portfolio' });
            },
        }
    };
</script>
