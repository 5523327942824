<template>
    <b-modal
        id="modal-password-change"
        v-model="showModal"
        classes="modal__container"
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Смена пароля"
        centered
        hide-footer
        scrollable
        @hidden="hideModal"
    >
        <div class="modal__content auth__form form">
            <template
                v-if="passwordChanged"
            >
                <b-alert
                    class="mb-3"
                    variant="success"
                    show
                >
                    <div class="form__content">
                        {{ passwordChanged }}
                    </div>
                </b-alert>
            </template>
            <template
                v-else
            >
                <b-form
                    class="mt-2"
                    @submit="onSubmit"
                >
                    <b-form-group
                        id="input-group-password"
                        label=""
                    >
                        <div class="auth__password">
                            <b-form-input
                                id="input-password"
                                v-model="formData.password"
                                type="password"
                                placeholder="Пароль"
                                required
                                size="lg"
                                :state="formErrors && formErrors.password ? false : null"
                            ></b-form-input>
                        </div>
                    </b-form-group>
                    <b-form-group
                        id="input-group-password_confirm"
                        label=""
                        class="mb-4"
                    >
                        <div class="auth__password">
                            <b-form-input
                                id="input-password_confirm"
                                v-model="formData.password_confirm"
                                type="password"
                                placeholder="Пароль повторно"
                                required
                                size="lg"
                                :state="formErrors && formErrors.password_confirm ? false : null"
                            ></b-form-input>
                        </div>
                        <b-form-invalid-feedback
                            class="text-center"
                            :state="formErrors && formErrors.detail ? false : null"
                        >
                            {{ formErrors ? formErrors.detail : '' }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-button
                        type="submit"
                        variant="success"
                        class="d-block col-12"
                        size="lg"
                        pill
                    >
                        Изменить пароль
                    </b-button>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-form>
            </template>
        </div>
        <b-overlay
            :show="showLoaderModal"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-modal>
</template>

<script>
    import { app } from "@/services";
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        //computed: {
        //    showModal() {
        //        return this.show;
        //    },
        //},
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        },
        data() {
            return {
                formData: {
                    password: ''
                },
                formErrors: {},
                showModal: this.show,
                showLoaderModal: false,
                showLoaderSending: false,
                passwordChanged: '',
            };
        },
        created() {
        },
        methods: {
            onSubmit() {
                this.passwordChanged = '';
                this.formErrors = {};
                if (this.formData.password !== this.formData.password_confirm) {
                    this.formErrors = {
                        password: 1,
                        password_confirm: 1,
                        detail: 'Пароли не совпадают'
                    }
                    return true;
                }
                let params = {
                    email: this.user.email,
                    password: this.formData.password
                };
                this.showLoaderSending = true;
                app.updateUser(params).then(res => {
                    console.log(res);
                    this.passwordChanged = res.detail || 'Пароль успешно изменен!';
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response);
                        this.formErrors = err.response.data.error;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            hideModal() {
                this.$emit('hideModal');
            },
            /*
            next() {
                this.$router.push({ name: 'stock' });
            },
            */
        }
    };
</script>
