<template>
    <div class="notifications">
        <div class="app__block-title">
            <h1 class="mb-5">{{ currentType.title }}</h1>
        </div>
        <div class="filters notifications__filters d-flex">
            <div class="d-flex mb-4 w-100">
                <b-button
                    v-for="type in reminderTypes"
                    :key="`type-${type.key}`"
                    :variant="(type.key === currentType.key) ? 'primary' : 'outline-primary'"
                    :class="['filters__item col-auto me-3 mb-3']"
                    size=""
                    pill
                    @click.prevent="changeType(type)"
                >
                    {{ type.title }}
                </b-button>

                <b-button
                    variant="light"
                    :class="['filters__item shadow-sm col-auto ms-auto mb-3']"
                    size=""
                    pill
                    @click.prevent="next('messages-notifications')"
                >
                    К списку уведомлений
                </b-button>
                <b-button
                    variant="light"
                    :class="['filters__item shadow-sm col-auto ms-3 mb-3']"
                    pill
                    @click.stop="showAddReminder()"
                >
                    Поставить напоминание <b-icon-bell-fill class="ms-1" />
                </b-button>
                <ModalReminderAdd
                    :show="showModalAddReminder"
                    @hideModal="hideAddReminder"
                />
            </div>
        </div>
        <b-card
            class="notifications__list col-12 shadow mb-5"
            bodyClass="p-1"
        >
            <b-table
                :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                :items="prepareTableData()"
                sticky-header
                responsive
                hover
                class="table__data b-table-sticky-header"
            >
                <template #head()="data">
                    <TableHeaderSlot
                        :data="data"
                        :currentSorting="currentSorting"
                        @changeSorting="changeSorting"
                    />
                </template>
                <template #cell(reminder_date)="data">
                    <div class="small">
                        {{ $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') }}<br>
                        {{ $helpers.formatDate(new Date(data.value), 'HH:mm') }}
                    </div>
                </template>
                <template #cell(content)="data">
                    <div 
                        class="small"
                        v-html="data.value"
                    />
                </template>
                <template #cell(portfolio)="data">
                    <div v-if="data.item.portfolio">
                        <strong>{{ data.item.portfolio.trust_name || data.item.portfolio.name }}</strong>
                    </div>
                    <div v-else>—</div>
                    <div v-if="data.item.instrument">
                        {{ data.item.instrument.name }}
                    </div>
                    <div v-else>—</div>
                </template>
                <template #cell(action)="data">
                    <BlockActionsReminder
                        :reminder="data.item"
                        @getReminders="getReminders"
                    />
                </template>
            </b-table>

            <BlockNavigateTable
                :totalRows="currentTotalRows"
                :currentPage="currentPage"
                :currentPerPage="currentPerPage"
                @changeNavigateTable="changeNavigateTable"
            />
            <b-overlay 
                :show="showLoaderSending"
                variant="transparent"
                no-wrap
                spinner-variant="success"
            />
        </b-card>
    </div>
</template>

<script>
    import { fieldNames } from "@/settings/tables";
    import { app } from "@/services";

    import BlockNavigateTable from "@/components/block-navigate-table";
    import TableHeaderSlot from "@/components/table-header-slot";
    import BlockActionsReminder from "@/components/block-actions-reminder";
    import ModalReminderAdd from "@/components/modals/modal-reminder-add";
    //import Helper from "@/components/helper";
    //import ModalBondGroupAdd from "@/components/modals/modal-bond-group-add";
    export default {
        name: 'messagesReminders',
        components: {
            BlockNavigateTable,
            TableHeaderSlot,
            BlockActionsReminder,
            ModalReminderAdd,
            //ModalBondGroupAdd
        },
        props: {
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        watch: {
        },
        data() {
            return {
                reminderTypes: [{
                    key: 'my',
                    title: 'Напоминания для меня'
                }, {
                    key: 'trust',
                    title: 'Напоминания для других'
                }],
                currentType: {},
                tableDataFilters: {
                    seen: false
                },
                tableFilters: [],
                tableHeaderName: 'reminderItem',
                tableHeader: fieldNames.reminderItem,
                //currentFilter: this.dataFiltersProps || 'all',
                currentSorting: {
                    key: 'reminder_date',
                    direction: 'desc'
                },
                reminders: [],
                currentPerPage: 10,
                currentPage: 1,
                showModalAddReminder: false,
                showLoaderSending: false
            };
        },
        created() {
            this.currentType = this.reminderTypes[0];
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            //this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else {
                    this.tableFilters.push(key);
                }
            });
            this.getReminders();
        },
        methods: {
            getReminders() {
                this.showLoaderSending = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                    ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key}`
                });
                if (this.currentType.key === 'my') {
                    app.getRemindersMyList(params).then(res => {
                        console.log(res);
                        this.currentTotalRows = res.count;
                        this.reminders = res.results;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                } else if (this.currentType.key === 'trust') {
                    app.getRemindersTrustList(params).then(res => {
                        console.log(res);
                        this.currentTotalRows = res.count;
                        this.reminders = res.results;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                }
            },
            prepareTableData() {
                let list = [...this.reminders];
                /*
                if (list.length) {
                    console.log('prepareTableData');
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            a[this.currentSorting.key] = isNaN(a[this.currentSorting.key]) ? a[this.currentSorting.key] : +a[this.currentSorting.key];
                            b[this.currentSorting.key] = isNaN(b[this.currentSorting.key]) ? b[this.currentSorting.key] : +b[this.currentSorting.key];
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                */
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                return list;
            },
            changeFilterTableData(data = null) {
                console.log('changeFilterTableData');
                if (data) this.tableDataFilters = Object.assign(this.tableDataFilters, data);
                this.currentPage = 1;
                this.useFilters = true;
                this.getReminders();
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
                this.getReminders();
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
                this.getReminders();
            },
            changeType(type) {
                this.currentType = type;
                this.getReminders();
            },
            showAddReminder() {
                this.showModalAddReminder = true;
            },
            hideAddReminder(refreshData = false) {
                this.showModalAddReminder = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.getReminders();
                    });
                }
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
