<template>
    <div
        class="app__block page404 m--full-width d-flex h-100 flex-column p-4"
        :class="$route.name"
    >
        <BlockUserCurrencies/>
        <b-alert
            variant="danger"
            show
            class="mt-4"
        >
            <p class="h2 mb-0 pb-0 text-danger">Страница не найдена.</p>
        </b-alert>
    </div>
</template>

<script>
    //import { app } from "@/services";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    export default {
        name: 'profile',
        components: {
            BlockUserCurrencies
        },
        watch: {
            link: {
                immediate: true,
                handler() {
                    /*
                    this.showLoaderSending = true;
                    app.getPortfolio(this.id).then(res => {
                        if (!res.error) {
                            this.portfolio = res;
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                    */
                }
            },
        },
        props: {
        },
        data() {
            return {
                menu: [{
                    name: 'profile-user',
                    title: 'Профиль',
                    icon: null,
                }, {
                    name: 'profile-accounts',
                    title: 'Брокерские счета',
                    icon: null,
                }, {
                    name: 'profile-currencies',
                    title: 'Валюты клиента',
                    icon: null,
                }, {
                    name: 'profile-tariffs',
                    title: 'Тариф',
                    icon: null,
                }],
                portfolio: {},
                showLoaderSending: false
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
