<template>
    <div class="profile__tariffs">
        <template v-if="!showLoaderSending">
            <!--div class="row profile__tariffs-form">
                <b-form-group
                    label="Период подписки"
                    class="profile__tariffs-payment col-auto"
                >
                    <b-card
                        class="shadow p-0 mb-4"
                        body-class="px-4 py-1 my-1"
                    >
                        <b-form-checkbox
                            variant="success"
                            v-model="tariffYearPeriod"
                            name="check-button"
                            switch
                        >
                            На год <strong>-25%</strong>
                        </b-form-checkbox>
                    </b-card>
                </b-form-group>
            </div-->
            <div class="profile__tariffs-block row">
                <div
                    v-for="tariff in tariffs"
                    :key="`tariff-${tariff.id}`"
                    class="profile__tariffs-item mb-5 col-12 col-lg-6 col-xl-4"
                >
                    <b-card
                        class="px-2 py-3 w-100 h-100 shadow"
                        :class="tariff.id === currentTariff.id ? 'border-success' : ''"
                        body-class="d-flex flex-column"
                    >
                        <div
                            v-if="tariff.postscript"
                            class="profile__tariffs-item-label"
                        >
                            <div class="profile__tariffs-item-label-inner">{{ tariff.postscript }}</div>
                        </div>
                        <div class="profile__tariffs-item-title">
                            {{ tariff.name }}
                        </div>
                        <div class="profile__tariffs-item-date">
                            <template v-if="!tariff.default_free && tariff.id === currentTariff.id">
                                <template v-if="currentTariff.canceled">
                                    <strong>Подписка отменена.</strong><br>
                                    Дата окончания действия {{ currentTariff.subscription_end ? $helpers.formatDate(new Date(currentTariff.subscription_end), 'DD.MM.YYYY') : '—' }}
                                </template>
                                <template v-else-if="currentTariff.status === 'trial'">
                                    Тестовый период до {{ currentTariff.subscription_end ? $helpers.formatDate(new Date(currentTariff.subscription_end), 'DD.MM.YYYY') : '—' }}
                                </template>
                                <template v-else>
                                    Дата следующего списания {{ currentTariff.subscription_end ? $helpers.formatDate(new Date(currentTariff.subscription_end), 'DD.MM.YYYY') : '—' }}
                                </template>
                            </template>
                        </div>
                        <div
                            v-if="showTariffAlert && !tariff.default_free && tariff.id === currentTariff.id && diffDays < diffDaysDelta"
                            class="profile__tariffs-item-extend text-danger"
                        >
                            Продлите ваш тариф
                        </div>
                        <!--div class="profile__tariffs-item-info">
                            {{ tariff.about }}
                        </div-->
                        <div class="profile__tariffs-item-price">
                            <template v-if="istariffYear(tariff)">
                                <template v-if="tariff.old_price">
                                    <span class="m--old">{{ tariff.old_price }}</span>
                                </template>
                                {{ $helpers.toPrice(tariff.price_year, { sign: '₽'}) }} <span>/ год</span>
                            </template>
                            <template v-else>
                                <template v-if="tariff.old_price">
                                    <span class="m--old">{{ tariff.old_price }}</span>
                                </template>
                                {{ $helpers.toPrice(tariff.price_month, { sign: '₽'}) }} <span>/ месяц</span>
                            </template>
                        </div>
                        <div :class="['profile__tariffs-item-button', tariff.default_free ? 'invisible' : '']">
                            <b-button
                                class="w-100"
                                size="lg"
                                :variant="tariff.id === this.currentTariff.id && !this.currentTariff.canceled ? 'outline-danger' : 'primary'"
                                pill
                                :disabled="tariff.disabled"
                                @click.stop="(tariff.id === currentTariff.id && currentTariff.status !== 'trial') ? onCancelTariff() : onSubmitTariff(tariff)"
                            >
                                {{ tariffButtonLabel(tariff) }}
                            </b-button>
                        </div>
                        <div class="profile__tariffs-item-params mt-4">
                            <div
                                v-for="(param, index) in tariff.elements"
                                :key="`param-${index}`"
                                :class="['profile__tariffs-item-param', param.is_active && 'is-active']"
                            >
                                <span>{{ param.name }} {{ param.value }}</span>
                                <Helper
                                    v-if="param.key_help"
                                    :id="`tariff-${tariff.id}-${param.key_help}`"
                                    :helper="param.key_help"
                                    info
                                    classParent="profile__tariffs-item"
                                />
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
            <div class="row profile__tariffs-form">
                <div class="col-12">
                    <b-alert
                        variant="success"
                        :show="result === 'success'"
                    >
                        <template
                            v-if="orders[0]?.has_payment"
                        >
                            Оплата прошла успешно.
                        </template>
                        <template
                            v-else
                        >
                            {{ orders[0]?.action_code_name }}
                        </template>
                    </b-alert>
                    <b-alert
                        variant="danger"
                        :show="result === 'fail'"
                    >
                        При проведении платежа произошла ошибка. Повторите операцию оплаты.
                    </b-alert>
                </div>
            </div>

            <div class="profile__tariffs-orders">
                <h2 class="mb-4">
                    История платежей
                </h2>
                <b-card
                    class="profile__tariffs-orders-table col-12 shadow mb-5"
                    bodyClass="p-1"
                >
                    <b-table
                        :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                        :items="prepareTableData()"
                        sticky-header
                        responsive
                        hover
                        class="table__data b-table-sticky-header"
                    >
                        <template #head()="data">
                            <TableHeaderSlot
                                :data="data"
                                :currentSorting="currentSorting"
                                @changeSorting="changeSorting"
                            />
                        </template>
                        <template #cell(created)="data">
                            <div class="small">
                                {{ $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY HH:mm') }}
                            </div>
                        </template>
                        <template #cell(price)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(tariff)="data">
                            <div class="text-nowrap">
                                {{ data.item.custom_text }}
                            </div>
                        </template>
                        <template #cell(period)="data">
                            <div
                                class="small"
                            >
                                {{ data.item.subscription_start ? $helpers.formatDate(new Date(data.item.subscription_start), 'DD.MM.YYYY') : '—' }} - {{ data.item.subscription_end ? $helpers.formatDate(new Date(data.item.subscription_end), 'DD.MM.YYYY') : '—' }}
                            </div>
                        </template>
                    </b-table>
                    <BlockNavigateTable
                        :totalRows="currentTotalRows"
                        :currentPage="currentPage"
                        :currentPerPage="currentPerPage"
                        @changeNavigateTable="changeNavigateTable"
                    />
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-card>
            </div>
        </template>
        <template v-else>
            <div class="app__loader">
                <b-spinner
                    variant="success"
                    class="me-3"
                />
                <span class="text-success">Загрузка данных ...</span>
            </div>
        </template>
        <!--
        <b-form
            @submit="onSubmitProfile"
            class="row profile__tariffs-form pt-3"
        >
            <b-form-group
                label="Платежи"
                class="profile__tariffs-payment col-12"
            >
                <b-card
                    class="shadow p-0 mb-4"
                    body-class="px-4 py-1 my-1"
                >
                    <b-form-checkbox
                        variant="success"
                        v-model="formData.notification"
                        name="check-button"
                        switch
                    >
                        Включить автоплатеж
                    </b-form-checkbox>
                </b-card>
            </b-form-group>
            <div class="col">
                <b-button
                    type="submit"
                    variant="primary"
                    class="w-100 py-2"
                    pill
                >
                    Сохранить настройки
                </b-button>
            </div>
            <div class="col">
                <b-button
                    type="reset"
                    variant="outline-secondary"
                    class="col-6 py-2 mt-auto mb-3"
                    pill
                >
                    Отменить
                </b-button>
            </div>
        </b-form>
        -->
        <ModalTariffCancel
            :key="`tariff-${currentTariff.id}`"
            :show="showModalTariffCancel"
            :tariff="currentTariff"
            @hideModal="hideTariffCancel"
        />
    </div>
</template>

<script>
    import { fieldNames } from "@/settings/tables";

    import { app } from "@/services";
    import TableHeaderSlot from "@/components/table-header-slot";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import ModalTariffCancel from "@/components/modals/modal-tariff-cancel";
    import Helper from "@/components/helper";
    export default {
        name: 'profileTariffs',
        components: {
            TableHeaderSlot,
            BlockNavigateTable,
            ModalTariffCancel,
            Helper
        },
        props: {
            result: {
                type: String,
                default() { return null; }
            },
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            currentTariff() {
                return this.$store.state.user.tariff || {};
            },
            diffDays() {
                let currDate = new Date();
                var endSubDate = new Date(this.$store.state.user.tariff.subscription_end);
                var timeDiff = endSubDate.getTime() - currDate.getTime();
                return Math.ceil(timeDiff / (1000 * 3600 * 24));
            }
        },
        data() {
            return {
                showTariffAlert: false,
                diffDaysDelta: 14,
                formData: {},
                tariffs: [],
                tariffYearPeriod: false,
                currentSorting: {
                    key: 'created',
                    direction: 'desc'
                },
                tableFilters: [],
                tableHeader: fieldNames.tariffOrderItem,
                orders: [],
                currentPerPage: 50,
                currentPage: 1,
                showModalTariffCancel: false,
                showLoaderSending: false,
            };
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                this.tableFilters.push(key);
            });
            if (this.result && Object.keys(this.$route.query).length) {
                this.verifyOrder();
            } else {
                this.getTariffs();
            }
        },
        methods: {
            istariffYear(tariff) {
                if (tariff.price_year) return true;
                return false;
            },
            prepareTableData() {
                let list = [...this.orders];
                if (this.currentSorting.key) {
                    list = list.sort((a, b) => {
                        if (a[this.currentSorting.key] > b[this.currentSorting.key])
                            return this.currentSorting.direction === 'asc' ? 1 : -1;
                        if (a[this.currentSorting.key] < b[this.currentSorting.key])
                            return this.currentSorting.direction === 'asc' ? -1 : 1;
                        return 0;
                    });
                }
                //_rowVariant: 'danger'
                this.currentTotalRows = list.length;
                if (this.currentPerPage) {
                    list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                }
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            verifyOrder() {
                this.showLoaderSending = true;
                let params = this.$route.query;
                app.verifyBillingOrder(params).then(res => {
                    console.log(res);
                    //this.$route.query = null;
                    //this.$router.push({ name: 'profile-tariffs', params: { result: this.result } });
                    this.$router.replace({});
                    this.getTariffs();
                }).catch(err => {
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
            getTariffs() {
                this.showLoaderSending = true;
                app.getBilling().then(res => {
                    console.log(res);
                    this.tariffs = res.detail;
                    //this.showLoaderSending = false;
                    this.getOrders();
                }).catch(err => {
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
            getOrders() {
                this.showLoaderSending = true;
                app.getBillingOrders().then(res => {
                    console.log(res);
                    this.orders = res.detail;
                    this.orders = this.orders.map(item => {
                        if (!item.has_payment) {
                            if ((item.action_code === 'checking' || item.action_code === 'pending')) {
                                item._rowVariant = 'warning';
                            }
                        }
                        return item;
                    });
                    //this.showLoaderSending = false;
                    this.getUser();
                }).catch(err => {
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
            getUser() {
                app.getUser().then(res => {
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
            onSubmitTariff(tariff) {
                let params = {
                    period: this.istariffYear(tariff) ? 'year' : 'month',
                    tariff: tariff.id
                };
                app.addBillingOrder(params).then(res => {
                    console.log(res);
                    this.showLoaderSending = false;
                    if (res.detail?.payment_link && res.detail?.signature) {
                        window.location.href = res.detail.payment_link;
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
            onCancelTariff() {
                this.showModalTariffCancel = true;
            },
            hideTariffCancel(refresh) {
                this.showModalTariffCancel = false;
                if (refresh) {
                    this.getTariffs();
                }
            },
            tariffButtonLabel(tariff) {
                if (tariff.disabled) return 'Скоро';
                if (tariff.id !== this.currentTariff.id) return 'Подключить';
                if (tariff.id === this.currentTariff.id && this.currentTariff.canceled) return 'Подключить';
                if (this.currentTariff.status === 'trial') return 'Оплатить';
                if (this.currentTariff.trial_days_count > 0) {
                    return 'Подключить';
                } else {
                    return 'Отменить подписку';
                }
            },

        }
    };
</script>
