const ajax = {
    timeout: 45000,
    responseType: 'json',
    responseEncoding: 'utf8'
};

const serviceUrl = {
    url: '//kapital.finprogress.pro',
    localPath: '//kapital.finprogress.pro',
    protocol: 'https',
    port: '80',
    api: '/api',
    onLocal: false
}

let urlPath = `${serviceUrl.url}${serviceUrl.api}`;
if (serviceUrl.onLocal || window.location.hostname === 'localhost') {
    urlPath = `${serviceUrl.localPath}:${serviceUrl.port}${serviceUrl.api}`;
}

let servicePath = `${serviceUrl.protocol}:${window.location.hostname === 'localhost' ? 
                serviceUrl.localPath + ':' + serviceUrl.port : serviceUrl.url }`

const app = {
    url: `${urlPath}`,
    token: 'd53d94913abf172aed172eb875bf546fcfdd5cef'
};

const cache = {
    //storage: 'sessionStorage'
    storage: 'tempStorage'
};

const logger = {
    url: `${urlPath}/logger`,
    level: 'debug',
    token: 'd53d94913abf172aed172eb875bf546fcfdd5cef'
};

const sidebarMenu = [
    {
        name: 'stock',
        role: 'all',
        title: 'Фондовый рынок',
        icon: null,
        items: [
            {
                name: 'accounts',
                childName: 'account',
                role: 'all',
                title: 'Брокерские счета',
                dynamic: true
            }, {
                name: 'bond-screener',
                role: 'all',
                title: 'Скринер облигаций',
            }, {
                name: 'bond-lists',
                role: 'all',
                title: 'Watchlist',
            }, {
                name: 'accounts-demo',
                childName: 'account-demo',
                role: 'all',
                title: 'Демонстрационные счета',
                dynamic: true
            }, {
                name: 'trust-management',
                role: 'all',
                title: 'Доверительное управление',
            }, {
                name: 'public-accounts',
                role: 'all',
                title: 'Публичные счета',
            }, {
                name: 'investment-planning',
                role: 'all',
                title: 'Планирование инвестиций',
                disabled: true
            }, {
                name: 'decisionmaking',
                role: 'all',
                title: 'Система принятия решений',
                disabled: true
            }, {
                name: 'companies',
                role: 'all',
                title: 'Каталог компаний',
                disabled: true
            }
        ]
    }, {
        name: 'empty',
        role: 'all',
        title: 'Банк',
        icon: null,
        disabled: true,
        hidden: true
    }, {
        name: 'empty',
        role: 'all',
        title: 'Недвижимость',
        icon: null,
        disabled: true,
        hidden: true
    }, {
        name: 'empty',
        role: 'all',
        title: 'Криптовалюта',
        icon: null,
        disabled: true,
        hidden: true
    }, {
        href: 'https://fond-300000.finprogress.ru/',
        role: 'all',
        title: 'Обучение',
        icon: null,
    //}, {
    //    name: 'account',
    //    role: 'all',
    //    title: 'Брокерский счет',
    //    icon: null,
    }
];

const accountMenu = [
    {
        link: '/profile',
        name: 'profile',
        role: 'all',
        icon: 'profile',
        title: 'Профиль'
    }, {
        link: '/alerts',
        name: 'alerts',
        role: 'all',
        icon: 'alerts',
        title: 'Уведомления'
    }
]

const topMenu = [
    {
        link: '/profile',
        name: 'profile',
        role: 'all',
        icon: 'settings',
        title: 'Профиль'
    }, {
        link: '/alerts',
        name: 'alerts',
        role: 'all',
        icon: 'alerts',
        title: 'Уведомления'
    }
]

export {
    ajax,
    app,
    cache,
    serviceUrl,

    logger,

    //mainMenu,
    accountMenu,
    topMenu,
    sidebarMenu,
    servicePath
};