<template>
    <div class="account__settings-trust account__edit">
        <div class="account__edit-form">
            <div class="mb-3">
                Доверительное управление портфелем
                <Helper
                    :id="`popover-managers-${portfolio.id}`"
                    helper="account_profile_managers"
                    classModifier="m--dark"
                    sup
                />
            </div>
            <BlockProfileAccountManager
                v-for="(item, index) in portfolio.trust_users"
                :key="`trust-user-${item.user}`"
                :index="index"
                :portfolio="portfolio"
                :trustUser="item"
                @refreshPortfolio="refreshPortfolio"
            />
            <div class="account__settings-trust-manager row">
                <b-form-group
                    label="ID"
                    class="account__settings-trust-manager-id col-2"
                >
                    <b-form-input
                        v-model="formData.user"
                        type="text"
                        autocomplete="off"
                        size="sm"
                        class="shadow-sm m--no-pill"
                        :disabled="showLoaderSending"
                        :state="formErrors && formErrors.user ? false : null"
                    ></b-form-input>
                </b-form-group>
                <div
                    class="col-6 mt-auto mb-3"
                >
                    <b-button
                        class="account__settings-trust-manager-save"
                        variant="outline-success"
                        pill
                        :disabled="showLoaderSending"
                        @click.stop="addAccountManager"
                    >
                        Добавить пользователя
                        <b-overlay
                            :show="showLoaderSending"
                            no-wrap
                            spinner-variant="success"
                            spinner-small
                        />
                    </b-button>
                </div>
                <div
                    v-if="formErrors && formErrors.detail"
                    class="col-12 mb-3"
                >
                    <b-alert
                        variant="danger"
                        show
                    >
                        <div class="form__content">
                            {{ formErrors.detail }}
                        </div>
                    </b-alert>
                </div>
            </div>
            <b-overlay
                :show="showLoaderSending"
                no-wrap
                spinner-variant="success"
            />
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Helper from "@/components/helper";
    import BlockProfileAccountManager from "@/components/block-profile-account-manager";
    //import ModalAccountDelete from "@/components/modals/modal-account-delete";
    export default {
        name: 'accountSettingsTrust',
        emits: ['refreshPortfolio'],
        components: {
            Helper,
            BlockProfileAccountManager
            //ModalAccountDelete
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
        },
        data() {
            return {
                formData: {},
                formErrors: {},
                showLoaderSending: false,
            }
        },
        created() {
        },
        mounted() {
            this.$breadcrumbs.value.at(-3).label = this.portfolio.name;
            this.formData = {
                portfolio_id: this.portfolio.id,
            };
            //this.getPortfolio();
        },
        methods: {
            addAccountManager() {
                this.formErrors = {};
                let params = {
                    portfolio: this.formData.portfolio_id,
                    user: this.formData.user,
                };
                this.showLoaderSending = true;
                app.addTrustUser(params).then(res => {
                    console.log(res);
                    this.formData = {
                        portfolio_id: this.portfolio.id,
                    };
                    this.showLoaderSending = false;
                    this.refreshPortfolio();
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response.data);
                        //this.formErrors = err.response.data.error;
                        //this.formErrors.token = 1;
                        this.formErrors.user = 1;
                        if (typeof err.response.data.detail === 'string' || err.response.data.detail instanceof String) {
                            this.formErrors.detail = err.response.data.detail;
                        } else {
                            this.formErrors.detail = err.response.data.detail?.user[0];
                        }
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            refreshPortfolio() {
                console.log('refreshPortfolio');
                this.$emit('refreshPortfolio', true);
            },
        }
    };
</script>
