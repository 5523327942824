const accountData = {
    0: {
        invested: 10000,
        current_value: 12000,
        free_cash: 1000,
        free_cash_percent: 10,
        total_value: 9000,
        total_value_percent: 90,
        total_profit: 2000,
        total_profit_percent: 10,
        current_profit: 1000,
        current_profit_percent: 1,
        average_profit: 1
    },
    2: {
        invested: 70071,
        current_value: 53150.39,
        free_cash: 1262.84,
        free_cash_percent: 25,
        total_value: 51887.55,
        total_value_percent: 75,
        total_profit: -13256.63,
        total_profit_percent: -19.96,
        current_profit: -10394.43,
        current_profit_percent: 14.83,
        average_profit: -19.96
    },
    3: {
        invested: 10000,
        current_value: 12000,
        free_cash: 1000,
        free_cash_percent: 10,
        total_value: 9000,
        total_value_percent: 90,
        total_profit: 2000,
        total_profit_percent: 10,
        current_profit: 1000,
        current_profit_percent: 1,
        average_profit: 1
    },
    4: {
        invested: 80000,
        current_value: 90000,
        free_cash: 10000,
        free_cash_percent: 12.5,
        total_value: 90000,
        total_value_percent: 10,
        total_profit: 10000,
        total_profit_percent: 12.5,
        current_profit: +1000,
        current_profit_percent: 2,
        average_profit: 5
    },
    5: {
        invested: 100000,
        current_value: 120000,
        free_cash: 30000,
        free_cash_percent: 25,
        total_value: 90000,
        total_value_percent: 75,
        total_profit: 20000,
        total_profit_percent: 20,
        current_profit: -10000,
        current_profit_percent: -10,
        average_profit: 10
    },
    6: {
        invested: 140000,
        current_value: 125000,
        free_cash: 2100,
        free_cash_percent: 2,
        total_value: 98500,
        total_value_percent: 45,
        total_profit: 23400,
        total_profit_percent: 10,
        current_profit: -10500,
        current_profit_percent: -12.5,
        average_profit: 4
    },
    7: {
        invested: 10000,
        current_value: 12000,
        free_cash: 3000,
        free_cash_percent: 5,
        total_value: 9000,
        total_value_percent: 7,
        total_profit: 2000,
        total_profit_percent: 2,
        current_profit: -1000,
        current_profit_percent: -1,
        average_profit: 1
    },
};
/*
const activesData = [{
    name: 'Газпром',
    ticker: 'GAZP',
    exchange: 'MOEX',
    quantity: 100,
    share: 12,
    _share: 'danger',
    price: 1000,
    currency: 'RUB',
    amount: 20000,
    profit_loss: 1400,
    _profit_loss: 'danger',
    profit_loss_percent: 14,
    _profit_loss_percent: 'danger'
}, { 
    name: 'Сбербанк',
    ticker: 'SBER',
    exchange: 'SPB',
    quantity: 20,
    share: 6,
    price: 1200,
    currency: 'RUB',
    amount: 24000,
    profit_loss: 1400,
    profit_loss_percent: 8
}, { 
    name: 'Сбербанк',
    ticker: 'SBER',
    exchange: 'MODEX',
    quantity: 10,
    share: 7,
    price: 1250,
    currency: 'RUB',
    amount: 12500,
    profit_loss: 1000,
    profit_loss_percent: 6
}];
*/
const activesData = [{
    name: 'Сбербанк',
    ticker: 'SBER',
    exchange: 'MCX',
    quantity: 60,
    share: 13.32,
    _share: 'danger',
    price: 126.67,
    currency: 'RUB',
    amount: 9357.6,
    profit_loss: -1330.7,
    _profit_loss: 'danger',
    profit_loss_percent: -14.22,
    _profit_loss_percent: 'danger'
}, {
    name: 'Yandex',
    ticker: 'YNDX',
    exchange: 'MCX',
    quantity: 1,
    share: 2.93,
    price: 4700,
    currency: 'RUB',
    amount: 4700,
    profit_loss: -2623.6,
    _profit_loss: 'danger',
    profit_loss_percent: -55.82,
    _profit_loss_percent: 'danger'
}, {
    name: 'Объединенная авиастроительная корпорация',
    ticker: 'UNAC',
    exchange: 'MCX',
    quantity: 5000,
    share: 4.95,
    price: 0.565,
    currency: 'RUB',
    amount: 2322.5,
    profit_loss: 667.5,
    profit_loss_percent: 28.74,
}, {
    name: 'АФК Система',
    ticker: 'AFKS',
    exchange: 'MCX',
    quantity: 500,
    share: 11.6,
    price: 13.313,
    currency: 'RUB',
    amount: 7468.5,
    profit_loss: -356.5,
    _profit_loss: 'danger',
    profit_loss_percent: -4.76,
    _profit_loss_percent: 'danger'
}, {
    name: 'Магнитогорский металлургический комбинат',
    ticker: 'MAGN',
    exchange: 'MCX',
    quantity: 100,
    share: 4.66,
    price: 26.58,
    currency: 'RUB',
    amount: 3324.5,
    profit_loss: -568.99,
    _profit_loss: 'danger',
    profit_loss_percent: -17.12,
    _profit_loss_percent: 'danger'
}, {
    name: 'VEON',
    ticker: 'VEON',
    exchange: 'US',
    quantity: 40,
    share: 5.82,
    price: 1.4,
    currency: 'USD',
    amount: 3324.16,
    profit_loss: -2231.94,
    _profit_loss: 'danger',
    profit_loss_percent: -67.14,
    _profit_loss_percent: 'danger'
}, {
    name: 'Polymetal',
    ticker: 'POLY',
    exchange: 'MCX',
    quantity: 2,
    share: 1.13,
    price: 1589.1,
    currency: 'RUB',
    amount: 3178.2,
    profit_loss: -2644.2,
    _profit_loss: 'danger',
    profit_loss_percent: -83.2,
    _profit_loss_percent: 'danger'
}, {
    name: 'VK',
    ticker: 'VKCO',
    exchange: 'MCX',
    quantity: 1,
    share: 0.55,
    price: 1768.8,
    currency: 'RUB',
    amount: 1768.8,
    profit_loss: -1362.6,
    _profit_loss: 'danger',
    profit_loss_percent: -77.04,
    _profit_loss_percent: 'danger'
}, {
    name: 'ОФЗ 26227',
    ticker: 'SU26227RMFS7',
    exchange: 'MCX',
    quantity: 1,
    share: 1.73,
    price: 987.13,
    currency: 'RUB',
    amount: 987.13,
    profit_loss: 12.5,
    profit_loss_percent: 1.27,
}, {
    name: 'РЖД 001P выпуск 8',
    ticker: 'RU000A0ZZGT5',
    exchange: 'MCX',
    quantity: 1,
    share: 1.75,
    price: 999.99,
    currency: 'RUB',
    amount: 999.99,
    profit_loss: 10.1,
    profit_loss_percent: 1.01,
}, {
    name: 'ГК Самолет выпуск 10',
    ticker: 'RU000A103L03',
    exchange: 'MCX',
    quantity: 1,
    share: 1.75,
    price: 940.7,
    currency: 'RUB',
    amount: 935,
    profit_loss: -5.7,
    _profit_loss: 'danger',
    profit_loss_percent: -0.61,
    _profit_loss_percent: 'danger'
}, {
    name: 'Беларусь04',
    ticker: 'RU000A100D63',
    exchange: 'MCX',
    quantity: 5,
    share: 0,
    price: 996.8,
    currency: 'RUB',
    amount: 4984.0,
    profit_loss: 114.25,
    profit_loss_percent: 2.29,
}, {
    name: 'OZON Holdings PLC',
    ticker: 'OZON',
    exchange: 'MCX',
    quantity: 5,
    share: 10.04,
    _share: 'danger',
    price: 909,
    currency: 'RUB',
    amount: 4545,
    profit_loss: 2477.5,
    profit_loss_percent: 54.51,
}, {
    name: 'Transocean',
    ticker: 'RIG',
    exchange: 'US',
    quantity: 6,
    share: 2.11,
    price: 3.38,
    currency: 'RUB',
    amount: 1203.82,
    profit_loss: -3.72,
    _profit_loss: 'danger',
    profit_loss_percent: -18.34,
    _profit_loss_percent: 'danger'
}];

const cashData = [{
    currency: 'usd',
    balance: 500,
    balance_currency_user: 45382.43,
}, {
    currency: 'eur',
    balance: -10,
    balance_currency_user: -1400.23,
}, {
    currency: 'rub',
    balance: -7200,
    balance_currency_user: -3831.98,
}];

const flowData = {
    day: [{
            period: '06.11.2020',
            funds: 0,
            funds_enrolled: 5000,
            funds_withdrawn: 0,
            currencies_purchased: 4648.95,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 0,
            balance_period_end: 5000,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '10.11.2020',
            funds: 5000,
            funds_enrolled: 15000,
            funds_withdrawn: 0,
            currencies_purchased: 7643,
            currencies_sold: 0,
            withheld_commissions: 0.38,
            return_commissions: 0,
            balance_period_start: 5000,
            balance_period_end: 15299.62,
            assets_purchased: 4700,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '11.11.2020',
            funds: 20000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0.2,
            return_commissions: 0,
            balance_period_start: 15299.62,
            balance_period_end: 10475.34,
            assets_purchased: 4824.26,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '12.11.2020',
            funds: 20000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 10475.34,
            balance_period_end: 6195.26,
            assets_purchased: 4280.08,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '19.11.2020',
            funds: 20000,
            funds_enrolled: 10000,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 6195.26,
            balance_period_end: 16195.26,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '20.11.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0.65,
            return_commissions: 0,
            balance_period_start: 16195.26,
            balance_period_end: 4148.43,
            assets_purchased: 12046.18,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '26.11.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0.63,
            return_commissions: 0,
            balance_period_start: 4148.43,
            balance_period_end: 969.6,
            assets_purchased: 3178.2,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '04.12.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 29,
            return_commissions: 0,
            balance_period_start: 969.6,
            balance_period_end: 1165.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 225,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '08.01.2021',
            funds: 30000,
            funds_enrolled: 2276.85,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1165.6,
            balance_period_end: 3442.45,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '28.01.2021',
            funds: 32276.85,
            funds_enrolled: 794.15,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 3442.45,
            balance_period_end: 4236.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '04.02.2021',
            funds: 33071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 28,
            return_commissions: 0,
            balance_period_start: 4236.6,
            balance_period_end: 4424.25,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 215.65,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '18.02.2021',
            funds: 33071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 294.6,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 4424.25,
            balance_period_end: 1226.75,
            assets_purchased: 3197.5,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '10.03.2021',
            funds: 33071,
            funds_enrolled: 15000,
            funds_withdrawn: 0,
            currencies_purchased: 7398.75,
            currencies_sold: 0,
            withheld_commissions: 46.74,
            return_commissions: 0,
            balance_period_start: 1226.75,
            balance_period_end: 5813.05,
            assets_purchased: 10366.96,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '15.03.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 5813.05,
            balance_period_end: 2901.55,
            assets_purchased: 2911.5,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '17.03.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 8.73,
            return_commissions: 0,
            balance_period_start: 2901.55,
            balance_period_end: 6539.32,
            assets_purchased: 0,
            assets_sold: 3646.5,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '18.03.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 17.91,
            return_commissions: 0,
            balance_period_start: 6539.32,
            balance_period_end: 4198.91,
            assets_purchased: 2322.5,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '26.03.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 4.44,
            return_commissions: 0,
            balance_period_start: 4198.91,
            balance_period_end: 2694.16,
            assets_purchased: 1500.31,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '13.04.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 5.31,
            return_commissions: 0,
            balance_period_start: 2694.16,
            balance_period_end: 920.05,
            assets_purchased: 1768.8,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '28.05.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 24,
            return_commissions: 0,
            balance_period_start: 920.05,
            balance_period_end: 1083.05,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 187,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '02.06.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1083.05,
            balance_period_end: 1281.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 198.27,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '02.07.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 29,
            return_commissions: 0,
            balance_period_start: 1281.31,
            balance_period_end: 1477.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 225,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '06.08.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 28,
            return_commissions: 0,
            balance_period_start: 1477.31,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 215.65,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '21.10.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 18.15,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 6050,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }],
    week : [{
            period: '05.11.2020 - 11.11.2020',
            funds: 0,
            funds_enrolled: 20000,
            funds_withdrawn: 0,
            currencies_purchased: 12291.95,
            currencies_sold: 0,
            withheld_commissions: 0.4,
            return_commissions: 0,
            balance_period_start: 15299.62,
            balance_period_end: 10475.34,
            assets_purchased: 9524.26,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '12.11.2020 - 18.11.2020',
            funds: 20000,
            funds_enrolled: 10000,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 10475.34,
            balance_period_end: 16195.26,
            assets_purchased: 4280.08,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '19.11.2020 - 25.11.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0.65,
            return_commissions: 0,
            balance_period_start: 16195.26,
            balance_period_end: 4148.43,
            assets_purchased: 12046.18,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '26.11.2020 - 02.12.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0.63,
            return_commissions: 0,
            balance_period_start: 4148.43,
            balance_period_end: 969.6,
            assets_purchased: 3178.2,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '03.12.2020 - 09.12.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 29,
            return_commissions: 0,
            balance_period_start: 969.6,
            balance_period_end: 1165.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 225,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '10.12.2020 - 16.12.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1165.6,
            balance_period_end: 1165.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '17.12.2020 - 23.12.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1165.6,
            balance_period_end: 1165.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '24.12.2020 - 30.12.2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1165.6,
            balance_period_end: 1165.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '31.12.2020 - 06.01.2021',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1165.6,
            balance_period_end: 1165.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '07.01.2021 - 13.01.2021',
            funds: 30000,
            funds_enrolled: 2276.85,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1165.6,
            balance_period_end: 3442.45,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '14.01.2021 - 20.01.2021',
            funds: 32276.85,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 3442.45,
            balance_period_end: 3442.45,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '21.01.2021 - 27.01.2021',
            funds: 32276.85,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 3442.45,
            balance_period_end: 3442.45,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '28.01.2021 - 03.02.2021',
            funds: 32276.85,
            funds_enrolled: 794.15,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 3442.45,
            balance_period_end: 4236.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '04.02.2021 - 10.02.2021',
            funds: 33071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 28,
            return_commissions: 0,
            balance_period_start: 4236.6,
            balance_period_end: 4424.25,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 215.65,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '11.02.2021 - 17.02.2021',
            funds: 33071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 4424.25,
            balance_period_end: 4424.25,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '18.02.2021 - 24.02.2021',
            funds: 33071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 294.6,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 4424.25,
            balance_period_end: 1226.75,
            assets_purchased: 3197.5,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '25.02.2021 - 03.03.2021',
            funds: 33071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1226.75,
            balance_period_end: 1226.75,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '04.03.2021 - 10.03.2021',
            funds: 33071,
            funds_enrolled: 15000,
            funds_withdrawn: 0,
            currencies_purchased: 7398.75,
            currencies_sold: 0,
            withheld_commissions: 46.74,
            return_commissions: 0,
            balance_period_start: 1226.75,
            balance_period_end: 5813.05,
            assets_purchased: 10366.96,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '11.03.2021 - 17.03.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 8.73,
            return_commissions: 0,
            balance_period_start: 5813.05,
            balance_period_end: 6539.32,
            assets_purchased: 2911.5,
            assets_sold: 3646.5,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '18.03.2021 - 24.03.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 17.91,
            return_commissions: 0,
            balance_period_start: 6539.32,
            balance_period_end: 4198.91,
            assets_purchased: 2322.5,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '25.03.2021 - 31.03.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 4.44,
            return_commissions: 0,
            balance_period_start: 4198.91,
            balance_period_end: 2694.16,
            assets_purchased: 1500.31,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '01.04.2021 - 07.04.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 2694.16,
            balance_period_end: 2694.16,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '08.04.2021 - 14.04.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 5.31,
            return_commissions: 0,
            balance_period_start: 2694.16,
            balance_period_end: 920.05,
            assets_purchased: 1768.8,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '15.04.2021 - 21.04.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 920.05,
            balance_period_end: 920.05,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '22.04.2021 - 28.04.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 920.05,
            balance_period_end: 920.05,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '29.04.2021 - 05.05.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 920.05,
            balance_period_end: 920.05,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '06.05.2021 - 12.05.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 920.05,
            balance_period_end: 920.05,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '13.05.2021 - 19.05.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 920.05,
            balance_period_end: 920.05,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '20.05.2021 - 26.05.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 920.05,
            balance_period_end: 920.05,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '27.05.2021 - 02.06.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 24,
            return_commissions: 0,
            balance_period_start: 920.05,
            balance_period_end: 1281.31,
            assets_purchased: 0,
            assets_sold: 385.27,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '03.06.2021 - 09.06.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1281.31,
            balance_period_end: 1281.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '10.06.2021 - 16.06.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1281.31,
            balance_period_end: 1281.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '17.06.2021 - 23.06.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1281.31,
            balance_period_end: 1281.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '24.06.2021 - 30.06.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1281.31,
            balance_period_end: 1281.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '01.07.2021 - 07.07.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 29,
            return_commissions: 0,
            balance_period_start: 1281.31,
            balance_period_end: 1477.31,
            assets_purchased: 0,
            assets_sold: 225,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '08.07.2021 - 14.07.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1477.31,
            balance_period_end: 1477.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '15.07.2021 - 21.07.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1477.31,
            balance_period_end: 1477.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '22.07.2021 - 28.07.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1477.31,
            balance_period_end: 1477.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '29.07.2021 - 04.08.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1477.31,
            balance_period_end: 1477.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '05.08.2021 - 11.08.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 28,
            return_commissions: 0,
            balance_period_start: 1477.31,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 215.65,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '12.08.2021 - 18.08.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '19.08.2021 - 25.08.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '26.08.2021 - 01.09.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '02.09.2021 - 08.09.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '09.09.2021 - 15.09.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '16.09.2021 - 22.09.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '23.09.2021 - 29.09.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '30.09.2021 - 06.10.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '07.10.2021 - 13.10.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '14.10.2021 - 20.10.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '21.10.2021 - 27.10.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 18.15,
            return_commissions: 0,
            balance_period_start: 1664.96,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 6050,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '28.10.2021 - 03.11.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 7696.81,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '04.11.2021 - 10.11.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 7696.81,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '11.11.2021 - 17.11.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 7696.81,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '18.11.2021 - 24.11.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 7696.81,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '25.11.2021 - 01.12.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 7696.81,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '02.12.2021 - 08.12.2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 7696.81,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }],
    month : [{
            period: 'Ноябрь 2020',
            funds: 30000,
            funds_enrolled: 30000,
            funds_withdrawn: 0,
            currencies_purchased: 12291.95,
            currencies_sold: 0,
            withheld_commissions: 1.68,
            return_commissions: 0,
            balance_period_start: 4148.4284,
            balance_period_end: 969.6,
            assets_purchased: 29028.72,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Декабрь 2020',
            funds: 30000,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 29,
            return_commissions: 0,
            balance_period_start: 969.5984,
            balance_period_end: 1165.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 225,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Январь 2021',
            funds: 30000,
            funds_enrolled: 3071,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 3442.4484,
            balance_period_end: 4236.6,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Февраль 2021',
            funds: 33071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 294.6,
            currencies_sold: 0,
            withheld_commissions: 28,
            return_commissions: 0,
            balance_period_start: 4424.2484,
            balance_period_end: 1226.75,
            assets_purchased: 3197.5,
            assets_sold: 0,
            dividends_received_total: 215.65,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Март 2021',
            funds: 33071,
            funds_enrolled: 15000,
            funds_withdrawn: 0,
            currencies_purchased: 7398.75,
            currencies_sold: 0,
            withheld_commissions: 77.82,
            return_commissions: 0,
            balance_period_start: 4198.9108,
            balance_period_end: 2694.16,
            assets_purchased: 17101.28,
            assets_sold: 3646.5,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Апрель 2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 5.31,
            return_commissions: 0,
            balance_period_start: 2694.1576,
            balance_period_end: 920.05,
            assets_purchased: 1768.8,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Май 2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 24,
            return_commissions: 0,
            balance_period_start: 920.0476,
            balance_period_end: 1083.05,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 187,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Июнь 2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1083.0476,
            balance_period_end: 1281.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 198.27,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Июль 2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 29,
            return_commissions: 0,
            balance_period_start: 1291.314,
            balance_period_end: 1477.31,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 225,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Август 2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 28,
            return_commissions: 0,
            balance_period_start: 1477.314,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 215.65,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Сентябрь 2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 0,
            return_commissions: 0,
            balance_period_start: 1477.314,
            balance_period_end: 1664.96,
            assets_purchased: 0,
            assets_sold: 0,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: 'Октябрь 2021',
            funds: 48071,
            funds_enrolled: 0,
            funds_withdrawn: 0,
            currencies_purchased: 0,
            currencies_sold: 0,
            withheld_commissions: 18.15,
            return_commissions: 0,
            balance_period_start: 1664.964,
            balance_period_end: 7696.81,
            assets_purchased: 0,
            assets_sold: 6050,
            dividends_received_total: 0,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }],
    year : [{
            period: '2020',
            funds: 30000,
            funds_enrolled: 30000,
            funds_withdrawn: 0,
            currencies_purchased: 12291.95,
            currencies_sold: 0,
            withheld_commissions: 30.68,
            return_commissions: 0,
            balance_period_start: 969.5984,
            balance_period_end: 1165.6,
            assets_purchased: 29028.72,
            assets_sold: 0,
            dividends_received_total: 225,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }, {
            period: '2021',
            funds: 48071,
            funds_enrolled: 18071,
            funds_withdrawn: 0,
            currencies_purchased: 7693.35,
            currencies_sold: 0,
            withheld_commissions: 210.28,
            return_commissions: 0,
            balance_period_start: 1664.964,
            balance_period_end: 7696.81,
            assets_purchased: 22067.58,
            assets_sold: 9696.5,
            dividends_received_total: 1041.57,
            valuation_assets_start: null,
            valuation_assets_end: null,
            pu_assets_period: null,
            pu_assets_period_percent: null,
            pu_account_period: null,
            pu_account_period_percent: null,
        }],
};

const flowTotalData = [{
    currency: 'RUB',
    funds: 50000,
    funds_enrolled: 50000,
    funds_withdrawn: 10000,
    balance_period_start: 0,
    balance_period_end: 423597,
    assets_purchased: 50000,
    assets_sold: 0,
    dividends_received_total: 5000,
}, {
    currency: 'USD',
    funds: 50000,
    funds_enrolled: 50000,
    funds_withdrawn: 10000,
    balance_period_start: 0,
    balance_period_end: 423597,
    assets_purchased: 50000,
    assets_sold: 0,
    dividends_received_total: 5000,
}];

const assetTransactionData = [{
    date_buy: '2022-04-19 10:01:00',
    price_buy: 530,
    currency: 'rub',
    date_sell: '2022-05-01',
    price_sell: 227.43,
    pu_currency: 12227.23,
    pu_percent: 6.94,
    amount_currency: 127.43,
    profit_currency: 12227.23,
    profit_percent: 6.94
}, {
    date_buy: '2022-04-20 10:01:00',
    price_buy: 531,
    currency: 'rub',
    date_sell: '2022-05-01',
    price_sell: 227.43,
    pu_currency: 12227.23,
    pu_percent: 6.94,
    amount_currency: 127.43,
    profit_currency: 12227.23,
    profit_percent: 6.94
}];

const assetDividentData = [{
    date: '2022-04-24 12:21:00',
    assets_nums: 10,
    currency: 'rub',
    amount_declared: 127.43,
    amount_received: 127.43
}, {
    date: '2022-04-25 12:21:00',
    assets_nums: 100,
    currency: 'rub',
    amount_declared: 128.03,
    amount_received: 128.13
}];

const assetScheduleData = [{
    number: 1,
    date_start: '23.02.2022',
    date_end: '23.05.2022',
    date_fixed: '22.05.2022',
    amount_single: 22.81,
}, {
    number: 2,
    date_start: '25.05.2022',
    date_end: '25.08.2022',
    date_fixed: '24.08.2022',
    amount_single: 22.81,
}];

const trustManagerData = [{
    owner: 'Иванов Иван - Супер инвестор',
    user: {
        user_id: 134
    },
    portfolio: {
        portfolio_id: 323,
        portfolio_name: 'Облигации Тинькофф 2023',
        access: 'view'
    },
    comment: 'Стратегия равномерного усредненного инвестирования. Покупка акций стоимости и циклических бумаг. Долгосрочный инвестиционный портфель. 2000 ₽ в неделю. Покупка акций стоимости и циклических бумаг. Долгосрочный инвестиционный портфель. 2000 ₽ в неделю'
}, {
    owner: 'Иван Петрович',
    user: {
        user_id: 134
    },
    portfolio: {
        portfolio_id: 33,
        portfolio_name: 'Пятый счет',
        access: 'edit'
    },
    comment: 'Осторожная стратегия'
}];

const bondScreenerData = [{
    ticker_isin: 'RU000A103901',
    ticker_name: 'ОФЗ 26239',
    currency: 'RUB',
    price: 826.81,
    price_percent: 92.30,
    aci: 7.75,
    coupon: 34.41,
    coupon_tax: 33.21,
    freq_payout: 2,
    income_1: 8.32,
    income_2: 8.32,
    income_total: 29.27,
    date: '2023-04-01',
    days_after: 24,
    days_next: 154,
    date_fix: '2023-08-01',
    years_fix: 8.4,
    valume: '464,8 млн. ₽',
    duration: 6.24,
    special: '—',
    type: 'Государственный',
    qualified: 'Нет',
    offer: '—',
}, {
    ticker_isin: 'RU000A103901',
    ticker_name: 'ОФЗ 26212',
    currency: 'RUB',
    price: 826.81,
    price_percent: 92.30,
    aci: 7.75,
    coupon: 34.41,
    coupon_tax: 33.21,
    freq_payout: 2,
    income_1: 8.32,
    income_2: 8.32,
    income_total: 29.27,
    date: '2023-04-01',
    days_after: 24,
    days_next: 154,
    date_fix: '2023-08-01',
    years_fix: 8.4,
    valume: '464,8 млн. ₽',
    duration: 6.24,
    special: '—',
    type: 'Государственный',
    qualified: 'Нет',
    offer: '—',
}];
const bondScreenerListData = [{
    name: 'Рискованный доход',
    updated: '2023-04-01',
    counts: 8,
}, {
    name: 'Интересные ОФЗ',
    updated: '2023-02-13',
    counts: 0,
}, {
    name: 'Рекомендации Елены Коваленко',
    updated: '2022-08-30',
    counts: 3,
}]
export {
    accountData,
    activesData,
    cashData,
    flowData,
    flowTotalData,
    assetTransactionData,
    assetDividentData,
    assetScheduleData,
    trustManagerData,
    bondScreenerData,
    bondScreenerListData
};