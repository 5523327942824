<template>
    <div class="navigate">
        <div class="navigate__total">
            Всего строк:<span>{{ totalRows }}</span>
        </div>
        <div class="navigate__select">
            На странице:
            <b-dropdown id="dropdown-onpage" variant="link" size="" class="navigate__select-button">
                <template #button-content>
                    {{ perPage.name }}
                </template>
                <b-dropdown-item-button 
                    v-for="item in onPageList"
                    :key="`onpage-list-${item.value}`"
                    buttonClass="navigate__select-item"
                    :active="perPage.value === item.value ? true : false"
                    @click="changePerPage(item)"
                >
                    {{ item.name }}
                </b-dropdown-item-button>
            </b-dropdown>
        </div>
        <b-pagination
            v-if="totalRows && totalRows > perPage.value"
            v-model="page"
            size="sm"
            class="navigate__pagination ms-auto"
            :total-rows="totalRows"
            :per-page="perPage.value"
            :limit="6"
            first-text="❮❮"
            last-text="❯❯"
            next-text="❯"
            prev-text="❮"
            @page-click="changePage"
        ></b-pagination>
    </div>
</template>

<script>
    import { onPageList } from "@/settings/tables";
    export default {
        components: {
        },
        watch: {
            currentPage: {
                immediate: true,
                handler() {
                    this.page = this.currentPage;
                }
            }
        },
        props: {
            currentPerPage: {
                type: Number,
                default() { return 50; }
            },
            currentPage: {
                type: Number,
                default() { return 1; }
            },
            totalRows: {
                type: Number,
                default() { return 0; }
            },
        },
        data() {
            return {
                onPageList: onPageList,
                page: this.currentPage,
                perPage: onPageList.filter(item => { return item.value === this.currentPerPage ? true : false; })[0]
            }
        },
        mounted() {
        },
        methods: {
            changePage(BvEvent, page) {
                this.page = page;
                this.$emit('changeNavigateTable', this.page, this.perPage.value);
            },
            changePerPage(item) {
                this.page = 1;
                this.perPage = item;
                this.$emit('changeNavigateTable', this.page, this.perPage.value);
            },

        }
    };
</script>
