<template>
    <b-modal
        id="modal-auth"
        v-model="showModal"
        classes="modal__container"
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Войти в систему"
        centered
        hide-footer
        scrollable
        @hidden="hideModal"
    >
        <div class="modal__content auth__form">

            <b-form
                class="form mt-2"
                @submit="onSubmit"
            >
                <template v-if="authVk">
                    <b-button
                        variant="outline-success"
                        class="d-block col-12 mb-3"
                        size="lg"
                        pill
                        @click.stop="onSubmitVk"
                    >
                        <svg width="1.25em" height="1.25em" viewBox="0 0 24 24" fill="currentColor" role="img" focusable="false">
                            <path d="M23.45 5.948C23.616 5.402 23.45 5 22.655 5H20.03C19.362 5 19.054 5.347 18.887 5.73C18.887 5.73 17.552 8.926 15.661 11.002C15.049 11.604 14.771 11.795 14.437 11.795C14.27 11.795 14.019 11.604 14.019 11.057V5.948C14.019 5.292 13.835 5 13.279 5H9.151C8.734 5 8.483 5.304 8.483 5.593C8.483 6.214 9.429 6.358 9.526 8.106V11.904C9.526 12.737 9.373 12.888 9.039 12.888C8.149 12.888 5.984 9.677 4.699 6.003C4.45 5.288 4.198 5 3.527 5H0.9C0.15 5 0 5.347 0 5.73C0 6.412 0.89 9.8 4.145 14.281C6.315 17.341 9.37 19 12.153 19C13.822 19 14.028 18.632 14.028 17.997V15.684C14.028 14.947 14.186 14.8 14.715 14.8C15.105 14.8 15.772 14.992 17.33 16.467C19.11 18.216 19.403 19 20.405 19H23.03C23.78 19 24.156 18.632 23.94 17.904C23.702 17.18 22.852 16.129 21.725 14.882C21.113 14.172 20.195 13.407 19.916 13.024C19.527 12.533 19.638 12.314 19.916 11.877C19.916 11.877 23.116 7.451 23.449 5.948H23.45Z"></path>
                        </svg>
                        Войти через ВКонтакте
                    </b-button>
                    <div class="h4 text-center mb-3">или</div>
                </template>
                <b-form-group
                    id="input-group-email"
                    label=""
                >
                    <b-form-input
                        id="input-email"
                        v-model="form.email"
                        type="email"
                        placeholder="E-mail"
                        required
                        size="lg"
                        :state="formErrors && formErrors.email ? false : null"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-password"
                    label=""
                >
                    <div class="auth__password">
                        <b-form-input
                            id="input-password"
                            v-model="form.password"
                            :type="showPass ? 'text' : 'password'"
                            placeholder="Пароль"
                            required
                            size="lg"
                            :state="formErrors && formErrors.password ? false : null"
                        ></b-form-input>
                        <b-button
                            variant="link"
                            class="auth__password-btn"
                            :class="showPass ? 'm--show' : ''"
                            @click="showPassword"
                        />
                    </div>
                    <b-form-invalid-feedback
                        class="text-center"
                        :state="formErrors && formErrors.password ? false : null"
                    >
                        {{ formErrors ? formErrors.detail : '' }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                    type="submit"
                    variant="success"
                    class="d-block col-12 mb-3"
                    size="lg"
                    pill
                >
                    Войти
                </b-button>
                <div class="d-flex">
                    <a
                        href="#"
                        class="auth__form-link me-auto"
                        @click.stop="showRegistration"
                    >
                        Регистрация
                    </a>
                    <a
                        href="#"
                        class="auth__form-link ms-auto"
                        @click.stop="showPasswordReset"
                    >
                        Забыли пароль?
                    </a>
                </div>
                <b-overlay
                    :show="showLoaderSending"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                />
            </b-form>
        </div>
        <div class="small pt-2">
            <a href="https://docs.google.com/document/d/15bg6k7U56eQqiydjseSFdHJbkgCFBEHJRdBcgp16FYo/edit" class="text-muted text-secondary" target="_blank">Политика конфиденциальности</a><br>
            <a href="https://docs.google.com/document/d/1_mE3oN5Y-EPalBJ3JqNhx4EeCB-qpVsSAeoWVYuqZ5w/edit" class="text-muted text-secondary" target="_blank">Согласие на обработку персональных данных</a>
        </div>
        <b-overlay
            :show="showLoaderModal"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-modal>
</template>

<script>
    import { serviceUrl } from '@/settings';
    import { app } from "@/services";
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        //computed: {
        //    showModal() {
        //        return this.show;
        //    },
        //},
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            }
        },
        data() {
            return {
                authVk: false,
                serviceUrl: serviceUrl,
                form: {
                    email: '',
                    password: ''
                },
                formErrors: {},
                showPass: false,
                showModal: this.show,
                showLoaderModal: false,
                showLoaderSending: false
            };
        },
        created() {
        },
        methods: {
            showPassword() {
                this.showPass = !this.showPass;
            },
            showPasswordReset() {
                this.hideModal();
                this.$emit('showPasswordReset');
            },
            showRegistration() {
                this.hideModal();
                this.$emit('showRegistration');
            },
            onSubmit() {
                this.formErrors = {};
                let params = this.form;
                this.showLoaderSending = true;
                app.obtainToken(params).then(res => {
                    if (res.access && res.refresh) {
                        this.$store.dispatch('setToken', res);
                        app.getUser().then(res => {
                            let currencies = res.currencies;
                            let currency = currencies.filter(item => { return item.default })[0];
                            console.log(currencies);
                            this.$store.dispatch('setCurrencyList', currencies);
                            this.$store.dispatch('setCurrency', currency);
                            delete res.currencies;
                            this.$store.dispatch('setUser', res);
                            if (res.tariff.status !== 'free' && res.tariff.subscription_end) {
                                let delta = Math.ceil(((new Date(res.tariff.subscription_end)).getTime() - (new Date()).getTime()) / (1000 * 3600 * 24));
                                console.log('Delta', delta);
                                if (delta <= 7) this.$store.dispatch('showAlert', 1);
                            }
                            app.getPortfolioMenuList({}).then(res => {
                                this.showLoaderSending = false;
                                this.$emit('hideModal');
                                if (!res.error) {
                                    this.next(res.length ? 'accounts' : 'stock');
                                } else {
                                    this.$store.dispatch('showError', {err: { message: res.error }});
                                }
                            }).catch(err => {
                                this.showLoaderSending = false;
                                //this.$store.dispatch('showError', err);
                                console.error(err);
                            });
                        }).catch(err => {
                            this.showLoaderSending = false;
                            this.formErrors = {
                                email: 1,
                                password: 1,
                                detail: err.detail
                            }
                            //this.$store.dispatch('showError', err);
                            console.error(err);
                        });
                    } else {
                        this.showLoaderSending = false;
                        this.formErrors = {
                            detail: 'Ошибка получения токена'
                        }
                        //this.$store.dispatch('showError', { detail: 'Ошибка получения токена' });
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    //this.$store.dispatch('showError', err);
                    console.error(err);
                    this.formErrors = {
                        email: 1,
                        password: 1,
                        detail: err.detail
                    }
                });
            },
            onSubmitVk() {
                this.showLoaderSending = true;
                app.getUserVkCode().then(res => {
                    this.showLoaderSending = false;
                    console.log(res);
                    let location = window.location;
                    let uri_full = `${res.url}&display=${res.display}&redirect_uri=${this.serviceUrl.url}/auth`;
                    location.href = uri_full;
                }).catch(err => {
                    this.showLoaderSending = false;
                    //this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            hideModal() {
                this.$emit('hideModal');
            },
            next(name) {
                this.$router.push({ name: name });
            },
        }
    };
</script>
