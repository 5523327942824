<template>
    <div class="app__block-top mb-3">
        <ol
            v-if="$breadcrumbs.value"
            class="breadcrumb"
        >
            <li
                v-for="(item, index) in $breadcrumbs.value"
                :key="`breadcrumbs-${index}`"
                class="breadcrumb-item small"

            >
                <router-link
                    v-if="!item.current"
                    :to="item.link"
                >
                    {{ item.label }}
                </router-link>
                <span
                    v-else
                >
                    {{ item.label }}
                </span>
            </li>
        </ol>
        <div
            v-if="$route.meta.requiresAuth"
            class="currencies m--top d-flex justify-content-end mb-auto ms-auto"
        >
            <b-button
                variant=""
                size="sm"
                class="currencies__select"
                @click="$router.push({ name: 'profile-currencies' })"
            >
                <b-icon-gear /> <span>Валюты</span>
            </b-button>

            <template
                v-for="(item, index) in currencyList"
                :key="`currency-user-${index}`"
            >
                <b-button
                    v-if="item.iso_name"
                    class="currencies__item ms-2"
                    variant="outline-success"
                    size="sm"
                    :active="item.default ? true : false"
                    pill
                    @click="changeCurrency(item)"
                >
                    {{ item.iso_name }}<span>, {{ item.symbol }}</span>
                </b-button>
            </template>
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    export default {
        props: {
        },
        computed: {
            currencyCurrent() {
                return this.$store.state.currencyCurrent || this.$store.state.currencyList.filter(c => { return c.default })[0];
            },
            currencyList() {
                return this.$store.state.currencyList;
            }
        },
        data() {
            return {}
        },
        mounted() {
        },
        methods: {
            changeCurrency(currency) {
                let params = {
                    currency: currency.currency
                };
                app.setProfileCurrencyDefault(params).then(res => {
                    this.$store.dispatch('setCurrency', res.detail.filter(c => { return c.default })[0]);
                    this.$store.dispatch('setCurrencyList', res.detail);
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            }
        }
    };
</script>
