<template>
    <div class="account__settings">
        <div class="app__block-title">
            <h1 class="mb-3">Настройки брокерского счета</h1>
            <template
                v-if="portfolioType === 'trust'"
            >
                <div class="account__trust mb-2">
                    <div class="account__trust-header">
                        <span class="account__trust-access text-success text-uppercase">Доверительное управление</span>
                        <span class="account__trust-user">{{ portfolio.user_public_name }} (ID: {{ portfolio.user_id }})</span>
                    </div>
                </div>
            </template>
        </div>
        <div class="account__buttons d-flex pt-4 mb-4">
            <div class="d-flex flex-wrap">
                <template
                    v-for="item in menu"
                    :key="item.name"
                >
                    <b-button
                        v-if="item.show"
                        :variant="(item.name === $route.name) ? 'outline-primary' : 'outline-primary'"
                        :active="item.name === $route.name"
                        :disabled="item.disabled"
                        class="account__buttons-item me-3 mb-2"
                        size=""
                        pill
                        @click.stop="next(item.name)"
                    >
                        {{ item.title }}
                    </b-button>
                </template>
            </div>
        </div>
        <template v-if="!showLoaderSending">
            <routerView
                :portfolioId="portfolioId"
                :portfolio="portfolioProfile"
                :portfolioType="portfolioType"
                @refreshPortfolio="refreshPortfolio"
                @refreshPortfolioAll="refreshPortfolioAll"
            />
            <div class="pt-4">
                <b-button
                    variant="primary"
                    size=""
                    pill
                    @click.stop="next('account')"
                >
                    Закрыть настройки
                </b-button>
            </div>
        </template>
        <template v-else>
            <div class="app__loader">
                <b-spinner
                    variant="success"
                    class="me-3"
                />
                <span class="text-success">Загрузка данных ...</span>
            </div>
        </template>
    </div>
</template>

<script>
    import { app } from "@/services";
    export default {
        name: 'accountSettings',
        emits: ['refreshPortfolio', 'refreshPortfolioAll'],
        components: {
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            routeSuffix: {
                type: String,
                default() { return ''; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
        },
        data() {
            return {
                portfolioProfile: {},
                menu: [{
                    name: `${this.portfolioType}-settings-main${this.routeSuffix}`,
                    title: 'Общие настройки',
                    icon: null,
                    disabled: (this.portfolioType !== 'account') ? true : false,
                    show: true
                }, {
                    name: `${this.portfolioType}-settings-public${this.routeSuffix}`,
                    title: 'Публичный доступ',
                    icon: null,
                    disabled: (this.portfolioType !== 'account') ? true : false,
                    show: true
                }, {
                    name: `${this.portfolioType}-settings-trust${this.routeSuffix}`,
                    title: 'Доверительное управление',
                    icon: null,
                    disabled: (this.portfolioType !== 'account') ? true : false,
                    show: true
                }, {
                    name: `${this.portfolioType}-settings-import${this.routeSuffix}`,
                    title: 'Импорт сделок',
                    icon: null,
                    //disabled: true,
                    show: true
                }],
                showLoaderSending: false
            }
        },
        created() {
        },
        mounted() {
            //this.$breadcrumbs.value.at(-3).label = this.portfolio.name;
            this.getPortfolio();
        },
        methods: {
            getPortfolio(lazy = false) {
                if (!lazy) this.showLoaderSending = true;
                app.getPortfolioProfile(this.portfolioId, this.portfolioType).then(res => {
                    if (!res.error) {
                        this.portfolioProfile = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    console.error(err);
                    if (err.response.status === 404) {
                        this.$router.push({ name: 'page404' });
                    } else {
                        this.$store.dispatch('showError', err);
                    }
                    this.showLoaderSending = false;
                });
            },
            refreshPortfolio(lazy) {
                console.log('refreshPortfolio');
                this.getPortfolio(lazy);
                //this.$emit('refreshPortfolio');
            },
            refreshPortfolioAll() {
                this.$emit('refreshPortfolioAll');
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
