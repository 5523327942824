<template>
    <div 
        class="app__block page d-flex h-100 flex-column px-4 pt-3 pb-4"
    >
        <BlockUserCurrencies />

        <template
            v-if="showLoaderSending"
        >
            <b-spinner 
                variant="success"
                class="me-3"
            />
            <span class="h4 text-success">Загрузка данных ...</span>
        </template>
        <template
            v-else
        >
            <div 
                class="app__block-title"
            >
                <h1 class="mb-5">{{ page.title }}</h1>
            </div>
            <div
                class="content"
                v-html="page.content || `Создайте страницу с полем slug: ${slug}`"
            />
        </template>
    </div>
</template>

<script>
    import { app } from "@/services";

    import BlockUserCurrencies from "@/components/block-user-currencies";
    export default {
        name: 'support',
        components: {
            BlockUserCurrencies,
        },
        props: {
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        watch: {
        },
        data() {
            return {
                page: {},
                showLoaderSending: true
            };
        },
        created() {
        },
        mounted() {
            this.getPageContent('sidebar_support');
        },
        methods: {
            getPageContent(slug) {
                this.showLoaderSending = true;
                app.getPage(slug).then(res => {
                    //console.log(res);
                    this.page = res;
                    this.showLoaderSending = false;
                }).catch(err => {
                    console.error(err);
                    this.showLoaderSending = false;
                });
            },
        }
    };
</script>
