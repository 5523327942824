<template>
    <div
        v-if="$route.name === 'stock'"
        class="app__block stock d-flex h-100 flex-column px-4 pt-3 pb-4"
        :class=[$route.name]
    >
        <BlockUserCurrencies/>
        <div class="app__block-title">
            <h1 class="mb-5">Приветствуем вас в системе "Капитал"!</h1>
        </div>
        <b-card class="shadow px-3 py-4 mb-5">
            <div class="h3 text-uppercase mb-4">Предлагаем вам ознакомиться с обучающими материалами:</div>
            <div class="stock__block">
                <template
                    v-for="item in helpList"
                    :key="item.slug"
                >
                    <router-link
                        :to="{ name: 'page', params: { slug: item.slug }}"
                        custom
                        v-slot="{ href }"
                    >
                        <a
                            :href="href"
                            class="stock__card"
                            @click.prevent="showPage(item.slug)"
                        >
                            <div class="stock__card-icon">
                                <img :src="getImgUrl(item.icon)" :alt="item.label">
                            </div>
                            <div class="stock__card-info">
                                {{ item.label }}
                                <span v-if="item.sublabel">{{ item.sublabel }}</span>
                            </div>
                        </a>
                    </router-link>
                </template>
            </div>
        </b-card>
        <b-card class="shadow px-3 py-4 mb-5">
            <template v-if="!showLoaderSending">
                <template v-if="portfolioList.length">
                    <b-button
                        variant="primary"
                        pill
                        @click.prevent="next('accounts')"
                    >
                        Открыть брокерские счета
                    </b-button>
                </template>
                <template v-else>
                    <div class="h3 text-uppercase mb-4">Или вы можете сразу перейти к созданию портфеля:</div>
                    <b-button
                        variant="primary"
                        pill
                        @click.prevent="next('account-add')"
                    >
                        Создать портфель
                    </b-button>
                </template>
            </template>
        </b-card>
        <ModalPage
            :slug="ModalPageSlug"
            :show="showModalPage"
            @hideModal="hidePage"
        />
    </div>
    <routerView v-else />
</template>

<script>
    import { app } from "@/services";

    import BlockUserCurrencies from "@/components/block-user-currencies";
    import ModalPage from "@/components/modals/modal-page";

    export default {
        name: 'stock',
        components: {
            BlockUserCurrencies,
            ModalPage
        },
        props: {
        },
        data() {
            return {
                helpList: [{
                    label: 'Создание портфеля',
                    sublabel: 'Брокерские счета',
                    icon: 'stock-info-1.png',
                    slug: 'help_create_acc',
                }, {
                    label: 'Загрузка отчетов брокера',
                    sublabel: 'Брокерские счета',
                    icon: 'stock-info-2.png',
                    slug: 'help_add_deal_broker',
                }, {
                    label: 'Добавление сделок вручную',
                    sublabel: 'Брокерские счета',
                    icon: 'stock-info-3.png',
                    slug: 'help_add_deal',
                }, {
                    label: 'Удаление сделки',
                    sublabel: 'Брокерские счета',
                    icon: 'stock-info-4.png',
                    slug: 'help_del_deal',
                }, {
                    label: 'Удаление счета',
                    sublabel: 'Брокерские счета',
                    icon: 'stock-info-5.png',
                    slug: 'help_del_acc',
                }, {
                    label: 'Скринер облигаций',
                    icon: 'stock-info-6.png',
                    slug: 'help_screener',
                }, {
                    label: 'Watchlist',
                    icon: 'stock-info-7.png',
                    slug: 'help_watchlist',
                }],
                portfolioList: [],
                ModalPageSlug: null,
                showModalPage: false,
                showLoaderSending: false
            };
        },
        watch: {
            '$route.name': {
                immediate: true,
                handler(to) {
                    if (to === 'stock') this.getPortfolioList();
                }
            },
        },
        computed: {
        },
        created() {
        },
        mounted() {
            this.getPortfolioList();
        },
        methods: {
            getImgUrl: function (imagePath) {
                return require('../assets/img/pics/' + imagePath);
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            getPortfolioList() {
                let params = {};
                this.showLoaderSending = true;
                app.getPortfolioMenuList(params).then(res => {
                    if (!res.error) {
                        this.portfolioList = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
