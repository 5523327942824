<template>
    <b-dropdown 
        :id="`action-${portfolio.portfolio_id}`" 
        class="float-end"
        dropleft 
        variant="link-primary" 
        no-caret
    >
        <template #button-content>
            <b-icon-three-dots-vertical />
        </template>
        <b-dropdown-item-button
            variant=""
            @click.prevent="showEditManagment"
        >
            Изменить комментарий
        </b-dropdown-item-button>
        <b-dropdown-item-button
            variant="danger"
            @click.prevent="showCancelManagment"
        >
            Отказаться от управления
        </b-dropdown-item-button>
    </b-dropdown>
    <ModalManagmentEdit
        :show="showModalEditManagment"
        :portfolio="portfolio"
        @hideModal="hideEditManagment"
    />
    <ModalManagmentCancel
        :show="showModalCancelManagment"
        :portfolio="portfolio"
        @hideModal="hideCancelManagment"
    />
</template>

<script>
    import ModalManagmentEdit from "@/components/modals/modal-managment-edit";
    import ModalManagmentCancel from "@/components/modals/modal-managment-cancel";
    export default {
        emits: ['getPortfolioList'],
        components: {
            ModalManagmentEdit,
            ModalManagmentCancel
        },
        props: {
            portfolio: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                showModalEditManagment: false,
                showModalCancelManagment: false,
            }
        },
        mounted() {
        },
        methods: {
            showEditManagment() {
                this.showModalEditManagment = true;
            },
            hideEditManagment(refreshData = false) {
                this.showModalEditManagment = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getPortfolioList');
                    });
                }
            },
            showCancelManagment() {
                this.showModalCancelManagment = true;
            },
            hideCancelManagment(refreshData = false) {
                this.showModalCancelManagment = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getPortfolioList');
                    });
                }
            },
        }
    };
</script>
