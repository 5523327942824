<template>
    <div class="account__info">
        <BlockAccountBlocks
            :portfolio="portfolio"
            :currentFilter="currentFilter"
        />
        <div
            v-if="rules.chart"
            class="account__info-chart chart mb-4 pb-2"
        >
            <h2 class="mb-4">
                График доходности брокерского счета
                <Helper
                    id="popover-chart"
                    helper="account_profit_chart"
                    classModifier="m--dark"
                    sup
                />
            </h2>
            <b-card class="shadow mb-5 py-4">
                <template v-if="!showLoaderCharts">
                    <div class="chart__filter row mb-3">
                        <div
                            class="chart__filter-item col-12 col-lg-6 col-xl-5 pe-3 mb-2"
                            v-for="item in chartFilterOptions"
                            :key="`chart-filter-${item.value}`"
                        >
                            <b-form-radio
                                v-model="chartFilter"
                                name="chartFilter"
                                :value="item.value"
                            >
                                {{ item.text }}
                            </b-form-radio>
                        </div>
                    </div>
                    <highcharts
                        v-if="chartFilter === 'capital'"
                        :id="`chart-capital-${portfolioId}`"
                        :constructorType="'stockChart'"
                        :options="chartOptions[chartFilter] || {}"
                    >
                    </highcharts>
                    <highcharts
                        v-else-if="chartFilter === 'profit'"
                        :id="`chart-profit-${portfolioId}`"
                        :constructorType="'stockChart'"
                        :options="chartOptions[chartFilter] || {}"
                    >
                    </highcharts>
                </template>
                <b-overlay
                    :show="showLoaderCharts"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                />
            </b-card>
        </div>
        <BlockAccountCash
            v-if="portfolioType !== 'public'"
            :portfolioId="portfolioId"
            :portfolio="portfolio"
            :portfolioType="portfolioType"
        />
        <div class="account__info-actives">
            <h2 class="mb-4">Ценные бумаги</h2>
            <div class="filters account__filters d-flex">
                <div class="row mb-4 me-1 w-100">
                    <div class="col-auto mb-3">
                        <b-card
                            class="filters__item shadow"
                        >
                            <b-input-group>
                                <b-form-input
                                    v-model="tableDataFilters.instrument_ticker"
                                    placeholder="Поиск по тикеру"
                                    size="sm"
                                    class="border-transparent py-0"
                                >
                                </b-form-input>
                                <b-input-group-append>
                                    <a
                                        v-if="tableDataFilters.instrument_ticker"
                                        href="#"
                                        class="btn-icon"
                                        @click.prevent="clearInputQ"
                                    >
                                        <b-icon-x-lg />
                                    </a>
                                    <div
                                        v-else
                                        class="btn-icon"
                                    >
                                        <b-icon-search />
                                    </div>
                                </b-input-group-append>
                            </b-input-group>
                        </b-card>
                    </div>
                    <!--div class="col-auto mb-3">
                        <b-card
                            class="filters__item shadow"
                        >
                            <b-form-checkbox variant="success" v-model="tableDataFilters.currency_user" name="check-button" switch>
                                Данные в валюте клиента
                            </b-form-checkbox>
                        </b-card>
                    </div-->
                    <template
                        v-if="portfolioType !== 'public'"
                    >
                        <div class="col-auto mb-3">
                            <b-card
                                class="filters__item shadow"
                            >
                                <b-form-checkbox variant="success" v-model="tableDataFilters.sold" name="check-button" switch>
                                    Проданные активы
                                </b-form-checkbox>
                            </b-card>
                        </div>
                        <div class="col-auto mb-3">
                            <b-card
                                class="filters__item shadow"
                                body-class="text-nowrap"
                            >
                                Макс. доля в портфеле
                                <span class="text-success">
                                    <input
                                        v-maska
                                        data-maska="##"
                                        v-model="tableDataFilters.share_in_portfolio"
                                        class="form-control form-control-sm m--small border-transparent p-0"
                                    />
                                    %
                                </span>
                            </b-card>
                        </div>
                    </template>
                </div>
                <BlockFilterTable
                    :tableHeader="tableHeader"
                    :accountId="portfolioId"
                    :tableHeaderName="tableHeaderName"
                    :tableFilters="tableFilters"
                    @changeFilterTable="changeFilterTable"
                />
            </div>
            <b-card
                :class="['account__info-table col-12 shadow mb-5', showLoaderSending ? 'py-4' : '']"
                bodyClass="p-1"
            >
                <b-table
                    :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                    :items="prepareTableData()"
                    sticky-header
                    responsive
                    hover
                    class="table__data b-table-sticky-header m--table-account-info"
                    @row-selected="onRowSelected"
                >
                    <template #head()="data">
                        <TableHeaderSlot
                            :data="data"
                            :currentSorting="currentSorting"
                            @changeSorting="changeSorting"
                        />
                    </template>
                    <template #cell(currency)="data">
                        {{ data.value.toUpperCase() }}
                    </template>
                    <template #cell(instrument_name)="data">
                        <div :class="this.$store.state.mode === 'blur' && 'm--blur'">
                            <router-link
                                v-if="data.item.instrument_slug && portfolioType !== 'public'"
                                :to="{ name: `${portfolioType}-assets`, params: { ticker: data.item.instrument_slug } }"
                                class="d-block"
                            >
                                <strong>{{ data.item.instrument_name }}</strong>
                                <br>
                                <small class="text-muted">{{ data.item.instrument_ticker }}</small>
                            </router-link>
                            <template
                                v-else
                            >
                                <strong>{{ data.item.instrument_name }}</strong>
                                <br>
                                <small class="text-muted">{{ data.item.instrument_ticker }}</small>
                            </template>
                        </div>
                    </template>
                    <template #cell(instrument_exchange_name)="data">
                        <div class="small">
                            <template
                                v-if="data.value"
                            >
                                {{ data.value }}
                            </template>
                            <template
                                v-else
                            >
                                {{ data.item.instrument_exchange }}
                            </template>
                        </div>
                    </template>
                    <template #cell(quantity)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(price)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(average_annual_income)="data">
                        <div class="text-end text-nowrap">
                            {{ (data.item.days_long <= 30) ? '—' : $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(aci)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(actual_sum_aci)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(average_price)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(sum_buy_user_currency)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(income)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(income_percent)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(income_aci)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(income_aci_percent)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(actual_sum)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(payment_sum)="data">
                        <div
                            class="text-end text-nowrap"
                            :class="data.item._amount ? `text-${data.item._amount}` : ''"
                        >
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(share_in_portfolio)="data">
                        <div
                            class="text-end text-nowrap"
                            :class="(data.item.share_in_portfolio >= tableDataFilters.share_in_portfolio && portfolioType !== 'public') ? 'text-danger' : ''"
                        >
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(profit_loss)="data">
                        <div
                            class="text-end text-nowrap"
                            :class="data.item._profit_loss ? `text-${data.item._profit_loss}` : ''"
                        >
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(profit_loss_percent)="data">
                        <div
                            class="text-end text-nowrap"
                            :class="data.item._profit_loss_percent ? `text-${data.item._profit_loss_percent}` : ''"
                        >
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                </b-table>
                <BlockNavigateTable
                    :totalRows="currentTotalRows"
                    :currentPage="currentPage"
                    :currentPerPage="currentPerPage"
                    @changeNavigateTable="changeNavigateTable"
                />
                <b-overlay
                    :show="showLoaderSending"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                    z-index="1010"
                />
            </b-card>
        </div>
    </div>
</template>

<script>
    import { fieldNames } from "@/settings/tables";
    import { accountProfit } from "@/settings/charts";

    import { app } from "@/services";
    import BlockAccountBlocks from "@/components/block-account-blocks";
    import BlockAccountCash from "@/components/block-account-cash";
    import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import Helper from "@/components/helper";
    import TableHeaderSlot from "@/components/table-header-slot";
    //import ModalDealAdd from "@/components/modals/modal-deal-add";
    //import ModalCashDealAdd from "@/components/modals/modal-cash-deal-add";
    export default {
        name: 'accountInfo',
        components: {
            BlockAccountBlocks,
            BlockAccountCash,
            BlockFilterTable,
            BlockNavigateTable,
            Helper,
            TableHeaderSlot,
            //ModalDealAdd,
            //ModalCashDealAdd
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            currentFilter: {
                type: Boolean,
                default() { return false; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        data() {
            return {
                //currentFilter: false,
                dataFilters: [{
                    /*
                        name: 'shorts',
                        title: 'Краткая информация',
                        show: true
                    }, {
                        name: 'full',
                        title: 'Полная информация с разбивкой по валютам',
                        show: true
                    }, {
                    */
                        name: 'add',
                        title: 'Добавить сделку с ЦБ',
                        modal: 'showDealAdd',
                        show: (!this.portfolio.access || this.portfolio.access === 'edit') ? true : false
                    }, {
                        name: 'edit',
                        title: 'Редактировать брокерский счет',
                        route: { name: `${this.portfolioType}-edit`, params: { id: this.portfolioId } },
                        show: (!this.portfolio.access || this.portfolio.access === 'edit') ? true : false
                }],
                currentSorting: {
                    key: 'instrument_ticker',
                    direction: 'asc'
                },
                tableFilters: [],
                tableDataFilters: {
                    instrument_ticker: null,
                    currency_user: false,
                    sold: false,
                    share_in_portfolio: 10
                },
                tableHeaderName: 'activeItem',
                tableHeader: fieldNames.activeItem,
                //fields: [],
                actives: [],
                currentPerPage: 25,
                currentPage: 1,
                chartFilterOptions: [{
                        text: 'Собственные средства в Инвестированный капитал',
                        value: 'capital'
                    /*
                    }, {
                        text: 'Прибыль БС и прибыль ЦБ, %',
                        value: 'profit'
                    */
                }],
                chartFilter: 'capital',
                chartOptions: {},
                showLoaderSending: false,
                showLoaderCharts: false,
            };
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            if (this.$route.name === 'account-info' || this.$route.name === 'trust-info') {
                this.$breadcrumbs.value.at(-2).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
            } else {
                if (this.$route.name === 'publics') {
                    this.$breadcrumbs.value.unshift({
                        label: 'Главная',
                        link: '/'
                    });
                }
                this.$breadcrumbs.value.at(-1).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
            }

            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.accounts?.[this.portfolioId]?.[this.tableHeaderName]) {
                    if (this.userSettings.accounts[this.portfolioId][this.tableHeaderName].indexOf(key) !== -1 || this.userSettings.accounts[this.portfolioId][this.tableHeaderName].indexOf(this.tableHeader[key].synonim) !== -1) this.tableFilters.push(key);
                } else if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1 || this.userSettings.tableHeader[this.tableHeaderName].indexOf(this.tableHeader[key].synonim) !== -1) this.tableFilters.push(key);
                } else {
                    this.tableFilters.push(key);
                }
            });
            //this.fields = this.$helpers.prepareTableFields(this.tableHeader, this.tableFilters);
            this.getPortfolioChartsList();
            this.getPortfolioAssetsList();
        },
        methods: {
            getPortfolioAssetsList() {
                this.showLoaderSending = true;
                app.getPortfolioAssetsList(this.portfolioId, this.portfolioType).then(res => {
                    if (!res.error) {
                        this.actives = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status !== 404) {
                        this.$store.dispatch('showError', err);
                    }
                    console.error(err);
                });
            },
            getPortfolioChartsList() {
                console.log('TYPE: ', this.portfolioType);
                this.chartOptions = Object.assign({}, accountProfit);
                let params = {
                    id: this.portfolioId,
                    period: 'day'
                };
                this.showLoaderCharts = true;
                app.getPortfolioChartsList(params, this.portfolioType).then(res => {
                    if (!res.error) {
                        console.log('getPortfolioChartsList', res);
                        /*
                        this.chartOptions.capital.xAxis.categories = res.map(item => {
                            return new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric', month: 'long' });
                        });
                        this.chartOptions.profit.xAxis.categories = res.map(item => {
                            return new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric', month: 'long' });
                        });
                        */
                        this.chartOptions.capital.tooltip.valueSuffix = ` ${res[0]?.currency}`;
                        this.chartOptions.capital.series[0].name = this.chartOptions.capital.series[0].nameTemplate + res[0]?.currency;
                        this.chartOptions.capital.series[1].name = this.chartOptions.capital.series[1].nameTemplate + res[0]?.currency;
                        this.chartOptions.capital.series[2].name = this.chartOptions.capital.series[2].nameTemplate + res[0]?.currency;

                        this.chartOptions.capital.series[0].data = [];
                        this.chartOptions.capital.series[1].data = [];
                        this.chartOptions.capital.series[2].data = [];

                        this.chartOptions.profit.series[0].data = [];
                        this.chartOptions.profit.series[1].data = [];

                        res.forEach(item => {
                            const date = new Date(item.date).getTime();
                            this.chartOptions.capital.series[0].data.push([
                                date,
                                item.own_cash,
                            ]);
                            this.chartOptions.capital.series[1].data.push([
                                date,
                                item.close_assets_sum,
                            ]);
                            this.chartOptions.capital.series[2].data.push([
                                date,
                                item.close_sum,
                            ]);
                            /*
                            this.chartOptions.profit.series[0].data.push([
                                date,
                                item.asset_income_percent_sum,
                            ]);
                            this.chartOptions.profit.series[1].data.push([
                                date,
                                item.income_percent_sum,
                            ]);
                            */
                        });
                        /*
                        this.chartOptions.capital.series[0].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                item.own_cash,
                            ]
                        });
                        this.chartOptions.capital.series[1].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                item.close_assets_sum,
                            ]
                        });
                        this.chartOptions.capital.series[2].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                item.close_sum,
                            ]
                        });
                        this.chartOptions.profit.series[0].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                item.asset_income_percent_sum,
                            ]
                        });
                        this.chartOptions.profit.series[1].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                item.income_percent_sum,
                            ]
                        });
                        */
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderCharts = false;
                }).catch(err => {
                    this.showLoaderCharts = false;
                    if (err.response.status !== 404) {
                        this.$store.dispatch('showError', err);
                    }
                    console.error(err);
                });
            },
            infoAccountParam(param) {
                if (this.portfolio.summary) {
                    console.log(this.portfolio.summary);
                    return this.portfolio.summary[param];
                }
                return null;
            },
            prepareTableData() {
                let list = [...this.actives];
                if (list.length) {
                    console.log('prepareTableData');
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            a[this.currentSorting.key] = isNaN(a[this.currentSorting.key]) ? a[this.currentSorting.key] : +a[this.currentSorting.key];
                            b[this.currentSorting.key] = isNaN(b[this.currentSorting.key]) ? b[this.currentSorting.key] : +b[this.currentSorting.key];
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                console.log('Actvives data:', list);
                list = list.filter(item => {
                    item._rowVariant = item.sold ? 'secondary text-muted' : '';
                    if (this.tableDataFilters.instrument_ticker) {
                        if (item.instrument_ticker.toLowerCase().indexOf(this.tableDataFilters.instrument_ticker.toLowerCase()) !== -1 || item.instrument_name.toLowerCase().indexOf(this.tableDataFilters.instrument_ticker.toLowerCase()) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                list = list.filter(item => {
                    if (this.tableDataFilters.sold) {
                        return true;
                    } else {
                        return (item.sold) ? false : true;
                    }
                });
                /*
                list = list.filter(item => {
                    if (this.tableDataFilters.share_in_portfolio) {
                        if (item.share_in_portfolio <= this.tableDataFilters.share_in_portfolio) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                */
                return list;
            },
            clearInputQ() {
                this.tableDataFilters.instrument_ticker = null;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            /*
            changeFilter(item) {
                console.log(item);
                if (item.route) {
                    console.log(item.route);
                    this.$router.push(item.route);
                }
                if (item.modal) {
                    console.log(item.modal);
                    this[item.modal]();
                } else {
                    this.currentFilter = item.name;
                }
            },
            */
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            rowClass(item, type) {
                console.log(item);
                if (!item || type !== 'row') return;
                if (item.slod) return 'table-success';
            },
            onRowSelected(item) {
                console.log(item);
            },
        }
    };
</script>
