<template>
    <b-modal
        id="modal-reminder-add"
        v-model="showModal"
        classes="modal__container" 
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        :title="reminderId ? 'Редактирование напоминания' : 'Добавление напоминания'"
        centered
        hide-footer
        no-stacking
        @hidden="hideModal()"
    >
        <div class="modal__content">
            <div class="row">
                <FormReminderAdd
                    v-if="show"
                    :reminderId="reminderId"
                    :portfolioId="portfolioId"
                    :portfolioType="portfolioType"
                    @hideModal="hideModal"
                />
            </div>
        </div>
    </b-modal> 
</template>

<script>
    import FormReminderAdd from "@/components/form-reminder-add";
    export default {
        components: {
            FormReminderAdd,
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            reminderId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'all'; }
            },
        },
        data() {
            return {
                showModal: this.show,
            };
        },
        created() {
        },
        mounted() {
        },
        methods: {
            hideModal(refreshData = false) {
                this.$emit('hideModal', refreshData);
            },
        }
    };
</script>
