<template>
    <b-dropdown
        :id="`emitter-share-${id}`"
        class="float-end"
        :container="`#app`"
        variant="link-primary px-2"
        no-caret
        lazy
        menuClass="ms-2 m--z-1000"
        boundary="viewport"
        @show="getEmitterPortfolioShares"
    >
        <template #button-content>
            {{ $helpers.stringForNumber(shares, ['портфель', 'портфеля', 'портфелей']) }}
        </template>
        <template v-if="!showLoaderSending">
            <template
                v-for="item in sharesList"
                :key="`emitter-share-item-${item.portfolio}`"
            >
                <b-dropdown-item-button
                    @click.prevent="$router.push({ name: `account`, params: { portfolioId: item.portfolio } })"
                >
                    <span>{{ item.portfolio_name }}</span>
                    <div class="small text-muted">Доля: {{ $helpers.toPrice(item.share_in_portfolio, { pointer: ',', sign: '%' }) }} ({{ $helpers.toPrice(item.actual_sum, { pointer: ',', sign: sign }) }})</div>
                </b-dropdown-item-button>
                <!--b-dropdown-item-button
                    v-for="bond in item.portfolio_shares"
                    :key="`emitter-share-item-bond-${bond.instrument_slug}`"
                    @click.prevent="$router.push({ name: `bond`, params: { slug: bond.instrument_slug } })"
                >
                    <span class="small ps-2">{{ bond.instrument_name }}</span>
                    <div class="small text-muted ps-2">Доля: {{ $helpers.toPrice(bond.share_in_portfolio, { pointer: ',', sign: '%' }) }} ({{ $helpers.toPrice(bond.actual_sum, { pointer: ',', sign: sign }) }})</div>
                </b-dropdown-item-button-->
            </template>
            <template v-if="showEmitter">
                <b-dropdown-divider />
                <b-dropdown-item-button
                    @click.prevent="$router.push({ name: `emitter`, params: { inn: inn } })"
                >
                    Подробнее об эмитенте
                </b-dropdown-item-button>
            </template>
        </template>
        <template v-else>
            <div class="app__loader px-3 py-1">
                <b-spinner
                    variant="success"
                    class="me-3"
                    small
                />
            </div>
        </template>
    </b-dropdown>
</template>

<script>
    import { app } from '@/services';
    export default {
        components: {
        },
        props: {
            id: {
                type: [Number, String],
                default() { return null; }
            },
            inn: {
                type: [Number, String],
                default() { return null; }
            },
            shares: {
                type: [Number, String],
                default() { return null; }
            },
            sign: {
                type: String,
                default() { return ''; }
            },
            showEmitter: {
                type: Boolean,
                default() { return false; }
            }
        },
        data() {
            return {
                sharesList: [],
                showLoaderSending: false,
            }
        },
        mounted() {
        },
        methods: {
            getEmitterPortfolioShares() {
                this.showLoaderSending = true;
                app.getEmitterPortfolioShares(this.inn).then(res => {
                    if (!res.error) {
                        this.sharesList = res.emitter_in_portfolio_shares;
                        console.log('Shares', res);
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
        }
    };
</script>
