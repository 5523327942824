<template>
    <div
        v-if="$route.name === 'bond-lists'"
        class="app__block bond-lists d-flex h-100 flex-column px-4 pt-3 pb-4"
    >
        <BlockUserCurrencies/>

        <div class="app__block-title m--help">
            <h1 class="mb-5">{{ $route.meta.title }}</h1>
            <router-link
                :to="{ name: 'page', params: { slug: 'help_watchlist_all' }}"
                custom
                v-slot="{ href }"
            >
                <a
                    :href="href"
                    class="app__block-help"
                    @click.prevent="showPage('help_watchlist_all')"
                >
                    <b-icon-question-circle-fill />
                </a>
            </router-link>
        </div>
        <div class="bond-lists__buttons d-flex pt-4 mb-4">
            <div class="d-flex flex-wrap">
                <b-button
                    :variant="'light'"
                    class="bond-lists__buttons-item me-3 mb-2 shadow"
                    size=""
                    pill
                    @click.prevent="showAddWatchlist"
                >
                    Добавить список
                    <b-icon-plus-lg class="ms-2" />
                </b-button>
            </div>
        </div>
        <template v-if="!showLoaderSending">
            <div class="bond-lists__block row">
                <div
                    v-for="(item, index) in lists"
                    :key="`list-${index}`"
                    class="col-12 col-lg-6 col-xl-4 col-xxl-3 mb-4"
                >
                    <b-card
                        class="bond-lists__block-item"
                        @click.prevent=""
                    >
                        <div class="bond-lists__block-item-top">
                            Облигации ({{ item.instrument_count }})
                            <div class="bond-lists__block-item-currency badge rounded-pill ms-auto mb-auto">{{ item.currency_iso_name }}, {{ item.currency_symbol }}</div>
                        </div>
                        <router-link
                            :to="{ name: 'bond-screener-list', params: { watchlistId: item.id } }"
                            class="bond-lists__block-item-title mb-1"
                        >
                            {{ item.name }}
                        </router-link>
                        <div class="bond-lists__block-item-bottom mt-auto">
                            <div class="bond-lists__block-item-date">Изменен {{ $helpers.formatDate(new Date(item.updated), 'DD.MM.YYYY') }}</div>
                            <BlockActionsBondList
                                :list="item"
                                @getWatchLists="getWatchLists"
                            />
                        </div>
                    </b-card>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="app__loader">
                <b-spinner
                    variant="success"
                    class="me-3"
                />
                <span class="text-success">Загрузка данных ...</span>
            </div>
        </template>
        <ModalWatchlistAdd
            v-if="showModalAddWatchlist"
            :show="showModalAddWatchlist"
            @hideModal="hideAddWatchlist"
        />
        <ModalPage
            v-if="showModalPage"
            :slug="ModalPageSlug"
            :show="showModalPage"
            @hideModal="hidePage"
        />
    </div>
    <RouterView v-else />
</template>

<script>
    import { app } from "@/services";

    import BlockUserCurrencies from "@/components/block-user-currencies";
    import BlockActionsBondList from "@/components/block-actions-bond-list";
    import ModalWatchlistAdd from "@/components/modals/modal-watchlist-add";
    import ModalPage from "@/components/modals/modal-page";

    export default {
        name: 'bondLists',
        components: {
            BlockUserCurrencies,
            BlockActionsBondList,
            ModalWatchlistAdd,
            ModalPage
        },
        props: {
        },
        computed: {
        },
        watch: {
            '$route.name': {
                //immediate: true,
                handler(to) {
                    if (to === 'bond-lists') this.getWatchLists();
                }
            },
        },
        data() {
            return {
                lists: [],
                currentPerPage: 50,
                currentPage: 1,
                showFilters: true,
                ModalPageSlug: null,
                showModalPage: false,
                showModalAddWatchlist: false,
                showLoaderSending: false
            };
        },
        created() {
        },
        mounted() {
            this.getWatchLists();
        },
        methods: {
            getWatchLists() {
                let params = {};
                this.showLoaderSending = true;
                app.getWatchLists(params).then(res => {
                    if (!res.error) {
                        this.lists = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            showAddWatchlist() {
                this.showModalAddWatchlist = true;
            },
            hideAddWatchlist(refreshData = false) {
                console.log('hideAddWatchlist', refreshData);
                this.showModalAddWatchlist = false;
                if (refreshData) {
                    console.log('refreshData');
                    this.getWatchLists();
                }
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
        }
    };
</script>
