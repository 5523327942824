<template>
    <div class="account__coupons">
        <template v-if="rules.portfolio_coupons && (rules.portfolio_coupons.limit === 0 || rules.portfolio_coupons.limit >= portfolio.ordering)">
            <div class="col-12 mb-4">
                <b-nav tabs class="m--dark">
                    <template
                        v-for="item in menu"
                        :key="item.name"
                    >
                        <li class="nav-item">
                            <b-button
                                v-if="item.show"
                                :active="item.name === $route.name"
                                :disabled="item.disabled"
                                class="nav-link"
                                @click.stop="changeTabMenuItem(item)"
                            >
                                {{ item.title }}
                            </b-button>
                        </li>
                    </template>
                </b-nav>
            </div>
            <routerView
                :portfolioId="portfolioId"
                :portfolio="portfolio"
                :portfolioType="portfolioType"
                :routeSuffix="routeSuffix"
                @refreshPortfolio="refreshPortfolio"
                @refreshPortfolioAll="refreshPortfolioAll"
            />
        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
    </div>
</template>

<script>
    import BlockTariffLimited from "@/components/block-tariff-limited";

    export default {
        name: 'accountCoupons',
        components: {
            BlockTariffLimited
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            routeSuffix: {
                type: String,
                default() { return ''; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
        },
        data() {
            return {
                menu: [{
                    name: `${this.portfolioType}-coupons-future${this.routeSuffix}`,
                    title: 'Будущие купоны',
                    icon: null,
                    show: true
                }, {
                    name: `${this.portfolioType}-coupons-payments${this.routeSuffix}`,
                    title: 'Анализ прошлых выплат',
                    icon: null,
                    show: true,
                }],
                currentTabMenuItem: {},
                ModalPageSlug: null,
                showModalPage: false,
                showLoaderSending: false
            };
        },
        created() {
        },
        mounted() {
            this.$breadcrumbs.value.at(-3).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
        },
        methods: {
            refreshPortfolio() {
                this.$emit('refreshPortfolio');
            },
            refreshPortfolioAll() {
                this.$emit('refreshPortfolioAll');
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            changeTabMenuItem(item) {
                this.currentTabMenuItem = item;
                if (item.name) this.next(item.name);
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
