<template>
    <div class="account__info-cash mb-4 pb-2">
        <h2 class="mb-4">
            Свободные денежные средства
        </h2>
        <b-card
            :class="['account__info-table col-12 col-lg-10 col-xl-8 shadow mb-5', showLoaderSending ? 'py-4' : '']"
            bodyClass="p-1"
        >
            <b-table
                v-if="!showLoaderSending"
                :fields="$helpers.prepareTableFields(tableHeader, tableFilters)"
                :items="prepareTableData()"
                select-mode="single"
                footClone
                responsive
                hover
                class="table__data b-table-sticky-header m--no-border-b"
            >
                <template #head()="data">
                    <TableHeaderSlot
                        :data="data"
                        :currentSorting="currentSorting"
                        @changeSorting="changeSorting"
                    />
                </template>
                <template #foot()="data">
                    <template
                        v-if="data.label.key === 'amount_user_currency'"
                    >
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.label.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template
                        v-else-if="data.label.key === 'amount'"
                    >
                    </template>
                    <template
                        v-else
                    >
                        {{ data.label.value }}
                    </template>
                </template>
                <template #cell(currency)="data">
                    <div class="text-start">
                        <strong>{{ data.item.currency_iso_name.toUpperCase() }}, {{ data.item.currency_symbol }}</strong>
                    </div>
                    <div class="text-muted small">
                        {{ data.item.currency_name }}
                    </div>
                </template>
                <template #cell(amount)="data">
                    <div class="text-end text-nowrap">
                        {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                    </div>
                </template>
                <template #cell(amount_user_currency)="data">
                    <div class="text-end text-nowrap">
                        {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                    </div>
                </template>
            </b-table>
            <b-overlay
                :show="showLoaderSending"
                variant="transparent"
                no-wrap
                spinner-variant="success"
            />
        </b-card>
        <!--div
            v-if="!this.portfolio.access || this.portfolio.access === 'edit'"
            class="col-12 mb-5"
        >
            <b-button
                variant="outline-success"
                class="account__edit-import-button px-5"
                pill
                @click="showCashDealAdd()"
            >
                Добавить сделку с ДС
            </b-button>
            <ModalCashDealAdd
                :show="showModalCashDealAdd"
                :portfolioId="portfolioId"
                @hideModal="hideCashDealAdd"
            />
        </div-->
    </div>
</template>

<script>
    //import { cashData } from "@/settings/data";

    import { fieldNames } from "@/settings/tables";

    import { app } from "@/services";
    //import ModalCashDealAdd from "@/components/modals/modal-cash-deal-add";
    import TableHeaderSlot from "@/components/table-header-slot";
    export default {
        components: {
            //ModalCashDealAdd,
            TableHeaderSlot,
        },
        watch: {
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                cashData: [],

                //currencyCurrent: this.$store.state.currencyCurrent,
                currencyList: this.$store.state.currencyList,
                currentSorting: {
                    key: 'currency',
                    direction: 'asc'
                },
                tableFilters: [],
                tableHeader: fieldNames.cashItem,
                //fields: [],
                cash: [],
                //showModalCashDealAdd: false,
                showLoaderSending: false
            }
        },
        mounted() {
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                this.tableFilters.push(key);
            });
            this.getPortfolioCash();
        },
        methods: {
            getPortfolioCash() {
                this.showLoaderSending = true;
                app.getPortfolioCash(this.portfolioId, this.portfolioType).then(res => {
                    this.showLoaderSending = false;
                    if (!res.error) {
                        this.cash = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.cash];
                //list = this.filterTableData(list);
                //this.currentTotalRows = list.length;
                if (this.currentSorting.key) {
                    list = list.sort((a, b) => {
                        if (a[this.currentSorting.key] > b[this.currentSorting.key])
                            return this.currentSorting.direction === 'asc' ? 1 : -1;
                        if (a[this.currentSorting.key] < b[this.currentSorting.key])
                            return this.currentSorting.direction === 'asc' ? -1 : 1;
                        return 0;
                    });
                }

                Object.keys(this.tableHeader).map((key) => {
                    if (this.tableHeader[key].total && key === 'amount_user_currency') {
                        let value = 0;
                        list.map(item => {
                            value += item[key] * 1;
                        });
                        this.tableHeader[key].value = value;
                        //this.tableHeader['amount_user_currency'].value = value;
                    }
                });

                //if (this.currentPerPage) {
                //    list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                //}
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            /*
            showCashDealAdd() {
                this.showModalCashDealAdd = true;
            },
            hideCashDealAdd(refreshData = false) {
                this.showModalCashDealAdd = false;
                if (refreshData) {
                    this.getPortfolioCash();
                }
            },
            */
        }
    };
</script>
