<template>
    <div 
        class="app__block d-flex h-100 flex-column px-4 pt-3 pb-4"
        :class="$route.name === 'accounts-demo' ? 'accounts' : 'account'"
    >
        <BlockUserCurrencies/>
        <template
            v-if="$route.name === 'accounts-demo'"
        >
            <div class="app__block-title">
                <h1 class="mb-5">Демо брокерским счетам</h1>
            </div>
            <template
                v-if="!showLoaderSending"
            >
                <div class="accounts__account account">
                    <div class="account__info">
                        <div class="account__info-filters d-flex mb-4">
                            <div class="d-flex flex-wrap ms-auto">
                                <!--b-card
                                    class="text-nowrap ms-3 mb-2 shadow"
                                    bodyClass="py-0 ps-3 pe-2 d-flex align-items-center"
                                >
                                    <b-form-checkbox variant="success" size="sm" v-model="currentFilter" :value="true" name="check-button" switch>
                                        Все валюты
                                    </b-form-checkbox>
                                </b-card-->
                                <template
                                    v-for="item in dataFilters"
                                    :key="item.name"
                                >
                                    <b-button
                                        v-if="item.show"
                                        :variant="(item.name === currentFilter) ? 'success' : 'light'"
                                        class="text-nowrap px-3 ms-3 mb-2 shadow"
                                        :class="(item.name === currentFilter) ? 'active' : ''"
                                        size="sm"
                                        pill
                                        @click.stop="changeFilter(item)"
                                    >
                                        {{ item.title }}
                                    </b-button>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <BlockAccount
                    v-for="portfolio in portfolioList"
                    :key="`portfolio-${portfolio.id}`"
                    :portfolioId="portfolio.id"
                    :portfolio="portfolio"
                    :portfolioType="portfolioType"
                    routeSuffix="-demo"
                    @refreshPortfolio="refreshPortfolio"
                />
            </template>
            <template
                v-else
            >
                <div class="app__loader">
                    <b-spinner 
                        variant="success"
                        class="me-3"
                    />
                    <span class="text-success">Загрузка данных ...</span>
                </div>
            </template>            
        </template>
        <template
            v-else
        >
            <routerView
                routeSuffix="-demo"
                @refreshPortfolioAll="refreshPortfolio"
            />
        </template>
    </div>
</template>

<script>
    //import { accountsProfit } from "@/settings/charts";

    import { app } from "@/services";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    //import BlockInfo from "@/components/block-info";
    import BlockAccount from "@/components/block-account";
    //import Helper from "@/components/helper";
    export default {
        name: 'accounts',
        components: {
            BlockUserCurrencies,
            //BlockInfo,
            BlockAccount,
            //Helper
        },
        props: {
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        watch: {
            'currencyCurrent': {
                immediate: true,
                handler() {
                    //this.getPortfolioListSummary();
                    //this.getPortfolioChartsSummary();
                    this.getPortfolioList();
                }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
        },
        data() {
            return {
                accountData: {},
                currentFilter: false,
                dataFilters: [{
                /*
                    name: 'shorts',
                    title: 'Краткая информация',
                    show: true
                }, {
                    name: 'full',
                    title: 'Полная информация с разбивкой по валютам',
                    show: true
                }, {
                */
                    name: 'add',
                    title: 'Создать портфель',
                    route: { name: 'account-add' },
                    show: this.$store.state.user?.tariff?.rules.portfolio ? true : false 
                }],
                portfolioList: [],
                chartFilterOptions: [{
                        text: 'Собственные средства в Инвестированный капитал',
                        value: 'capital'
                    }, {
                        text: 'Прибыль БС и прибыль ЦБ, %',
                        value: 'profit'
                }],
                chartFilter: 'capital',
                chartOptions: {},
                showLoaderSending: false,
                showLoaderCharts: false,
            };
        },
        created() {
        },
        mounted() {
            this.getPortfolioList();
        },
        methods: {
            infoAccountParam(param) {
                if (this.accountData.summary) {
                    return this.accountData.summary[param];
                }
                return null;
            },
            infoAccountParams(param) {
                if (this.accountData.currency_summary) {
                    let data = {};
                    Object.keys(this.accountData.currency_summary).forEach(key => {
                        data[key] = this.accountData.currency_summary[key][param];
                    });
                    return data;
                }
                return {};
            },
            getPortfolioList() {
                let params = {};
                this.showLoaderSending = true;
                app.getPortfolioDemoList(params).then(res => {
                    if (!res.error) {
                        if (!res.length) {
                            this.next('stock');
                        }
                        if (this.rules.portfolio) {
                            if (this.rules.portfolio.limit !== 0) {
                                res.splice(this.rules.portfolio.limit);
                            }
                        } else {
                            res = [];
                        }
                        this.portfolioList = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            changeFilter(item) {
                console.log(item);
                if (item.route) {
                    console.log(item.route);
                    this.$router.push(item.route);
                }
                if (item.modal) {
                    console.log(item.modal);
                    this[item.modal]();
                } else {
                    this.currentFilter = !this.currentFilter;
                }
            },
            refreshPortfolio() {
                console.log('refreshPortfolios All');
                this.getPortfolioList();
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
