<template>
    <div
        class="table__data-head-info"
        :class="[data.label.sort ? 'm--cursor' : '', `m--${data.label.key}`]"
        @click.prevent="data.label.sort ? changeSorting(data.label.key) : null"
    >
        {{ data.label.name }}{{ data.label.nameSuffix ? nameSuffix(data.label.nameSuffix) : '' }}
        <template v-if="data.label.icon === 'action'">
            <b-icon-pencil class="ms-3 mt-3"></b-icon-pencil>
        </template>
        <span
            v-if="data.label.helper"
            :id="`popover-${data.label.key}`"
            variant="link"
            class="helper px-1 py-0"
            @mouseover.stop="getHelperContent(data.label.helper)"
        >
            <b-icon-question-circle></b-icon-question-circle>
        </span>
        <b-popover
            v-if="data.label.helper"
            :target="`popover-${data.label.key}`"
            triggers="hover"
            placement="top"
        >
            <template v-if="showLoaderSending">
                <b-spinner
                    variant="success"
                    small
                    class="me-2"
                />
                <span class="text-success">Загрузка подсказки ...</span>
            </template>
            <template v-else>
                <div
                    v-html="hint.content || `Создайте подсказку с полем slug: ${data.label.helper}`"
                />
            </template>
        </b-popover>
        <span
            v-if="data.label.sort"
            class="table__data-head-sort"
        >
            <template v-if="currentSorting.key !== data.label.key">
                <span
                    class="table__data-head-sort-arrow"
                >
                    <b-icon-arrow-down-up></b-icon-arrow-down-up>
                </span>
            </template>
            <template v-else>
                <span
                    v-if="currentSorting.key === data.label.key && currentSorting.direction === 'desc'"
                    class="table__data-head-sort-arrow active"
                >
                    <b-icon-arrow-down></b-icon-arrow-down>
                </span>
                <span
                    v-if="currentSorting.key === data.label.key && currentSorting.direction === 'asc'"
                    class="table__data-head-sort-arrow active"
                >
                    <b-icon-arrow-up></b-icon-arrow-up>
                </span>
            </template>
        </span>
    </div>
</template>

<script>
    import { app } from "@/services";
    export default {
        computed: {
            showModal() {
                return this.show;
            },
        },
        props: {
            data: {
                type: Object,
                default() { return null; }
            },
            currentSorting: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                hint: {},
                requested: false,
                showLoaderSending: false,
            }
        },
        mounted() {
        },
        methods: {
            getHelperContent(helper) {
                if (!this.requested) {
                    this.showLoaderSending = true;
                    app.getHint(helper).then(res => {
                        this.hint = res;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        console.error(err);
                        this.showLoaderSending = false;
                    });
                    this.requested = true;
                }
            },
            changeSorting(key) {
                let sorting = {};
                if (this.currentSorting.key !== key) {
                    sorting = {
                        key: key,
                        direction: 'asc'
                    };
                } else {
                    if (this.currentSorting.direction === 'asc') {
                        sorting = {
                            key: key,
                            direction: 'desc'
                        };
                    } else {
                        sorting = {
                            key: key,
                            direction: 'asc'
                        };
                    }
                }
                this.$emit('changeSorting', sorting);
            },
            nameSuffix(fnName) {
                if (typeof this[fnName] === 'function') {
                    return this[fnName]();
                }
            },
            currencySign() {
                let currency = this.$store.state.currencyCurrent;
                return `, ${currency.symbol}`;
            },
            currencyAssetSign() {
                let currency = this.data.label.currency;
                return `, ${currency.symbol}`;
            },
            currencyIsoSign() {
                let currency = this.$store.state.currencyCurrent;
                return `, (${currency.iso_name}, ${currency.symbol})`;
            },
        }
    };
</script>
