<template>
    <b-modal
        id="modal-filter-delete"
        v-model="showModal"
        size="md"
        classes="modal__container"
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Удалить фильтр"
        centered
        hide-footer
        no-stacking
        @hidden="hideModal()"
    >
        <div class="modal__content">
            <b-form
                @submit="onSubmitFilter"
                class="bond-screener__filter-form row"
            >
                <div class="col-12">
                    Фильтр «{{ userSettings.filters?.[this.filterInterface]?.[this.selectedFilterKey]?.name }}» будет удалён!
                </div>
                <div
                    class="col-12 pt-3"
                >
                    <b-button
                        variant="outline-success"
                        class="bond-screener__group-form-button mb-3 me-auto"
                        type="submit"
                        pill
                        :disabled="showLoaderSending"
                    >
                        Подтвердить
                    </b-button>
                </div>
                <b-overlay
                    :show="showLoaderSending"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                />
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import { app } from '@/services';
    //import FormDealAdd from "@/components/form-deal-add";
    //import BlockTariffLimited from "@/components/block-tariff-limited";
    //import Multiselect from '@vueform/multiselect';
    export default {
        components: {
            //FormDealAdd,
            //BlockTariffLimited
            //Multiselect
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        props: {
            filterInterface: {
                type: String,
                default() { return 'bondScreener'; }
            },
            selectedFilterKey: {
                type: String,
                default() { return null; }
            },
            filterData: {
                type: Object,
                default() { return {}; }
            },
            show: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                showModal: this.show,
                showLoaderSending: false,
                addError: null,
                refreshData: false,
            };
        },
        created() {
        },
        mounted() {
        },
        methods: {
            onSubmitFilter() {
                this.showLoaderSending = true;
                this.addSuccess = false;
                this.addError = null;
                let settings = this.userSettings;
                //if (!settings.filters) settings.filters = {};
                //if (!settings.filters[this.filterInterface]) settings.filters[this.filterInterface] = {};
                delete settings.filters?.[this.filterInterface]?.[this.selectedFilterKey];
                delete settings.filters?.[this.filterInterface]?.current;
                app.updateUser({ user_settings: settings }).then(res => {
                    console.log(res);
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                    this.showLoaderSending = false;
                    this.hideModal(true);
                }).catch(err => {
                    console.error(err);
                    this.showLoaderSending = false;
                });
            },
            hideModal(refreshData) {
                this.addSuccess = false;
                this.addError = null;
                this.$emit('hideModal', refreshData);
            },
        }
    };
</script>
