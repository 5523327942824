<template>
    <div class="account__settings-public account__edit">
        <div class="account__edit-form mb-5">
            <div class="mb-3">
                Публичный доступ по ссылке
                <Helper
                    id="popover-public"
                    helper="account_profile_public"
                    classModifier="m--dark"
                    sup
                />
            </div>
            <b-form-group
                v-if="portfolio.public_url"
                class="account__settings-public-link col-12"
            >
                <input
                    :value="publicLink"
                    type="text"
                    placeholder=""
                    class="form-control form-control-sm shadow-sm m--no-pill"
                    readonly
                    :disabled="showLoaderSending"
                />
            </b-form-group>
            <div class="col-12 mb-3">
                <b-button
                    class="account__settings-public-link-create me-3"
                    variant="outline-success"
                    pill
                    :disabled="showLoaderSending"
                    @click.stop="createAccountPublic"
                >
                    Создать новую ссылку
                </b-button>
                <b-button
                    v-if="portfolio.public_url"
                    variant="outline-danger"
                    class="account__settings-public-link-delete"
                    pill
                    :disabled="showLoaderSending"
                    @click.stop="deleteAccountPublic"
                >
                    Удалить
                </b-button>
            </div>
        </div>
        <div class="account__edit-form mb-3">
            <b-form-group
                label="Публичное название счета"
                description="Отображается другим людям в ваших публичных брокерских счетах"
                class="col-12"
            >
                <b-form-input
                    v-model="formData.trust_name"
                    type="text"
                    placeholder=""
                    size="sm"
                    class="shadow-sm m--no-pill"
                    :disabled="showLoaderSending"
                    :state="formErrors && formErrors.trust_name ? false : null"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Публичный комментарий к счету"
                class="col-12"
            >
                <b-form-textarea
                    v-model="formData.trust_comment"
                    type="text"
                    placeholder=""
                    size="sm"
                    class="shadow-sm m--no-pill"
                    :disabled="showLoaderSending"
                    :state="formErrors && formErrors.trust_comment ? false : null"
                ></b-form-textarea>
            </b-form-group>
            <div
                v-if="formErrors && formErrors.detail"
                class="col-12 mb-3"
            >
                <b-alert
                    variant="danger"
                    show
                >
                    <div class="form__content">
                        {{ formErrors.detail }}
                    </div>
                </b-alert>
            </div>
            <div class="col-12">
                <b-button
                    class="account__settings-public-save"
                    variant="outline-success"
                    pill
                    :disabled="showLoaderSending"
                    @click.stop="updateTrustInfo"
                >
                    Сохранить
                </b-button>
            </div>
            <b-overlay
                :show="showLoaderSending"
                no-wrap
                spinner-variant="success"
            />
        </div>
    </div>
</template>

<script>
    import { app } from "@/services";
    import Helper from "@/components/helper";
    export default {
        name: 'accountSettingsPublic',
        emits: ['refreshPortfolio'],
        components: {
            Helper
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            publicLink() {
                return `${location.protocol}//${location.host}/public/${this.portfolio.public_url}`;
            }
        },
        data() {
            return {
                formData: {},
                formErrors: {},
                showLoaderSending: false,
            }
        },
        created() {
        },
        mounted() {
            this.$breadcrumbs.value.at(-3).label = this.portfolio.name;
            /*
            this.formData = {
                id: this.portfolioId,
                trust_name: this.portfolio.trust_name,
                trust_comment: this.portfolio.trust_comment,
            };
            */
            this.formData = this.portfolio;
            //this.getPortfolio();
        },
        methods: {
            getPortfolio() {
                if (this.formData.id) {
                    this.showLoaderSending = true;
                    let id = this.formData.id;
                    app.getPortfolio(id, this.portfolioType).then(res => {
                        this.showLoaderSending = false;
                        if (!res.error) {
                            this.formData = res;
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                }
            },
            updateTrustInfo() {
                this.formErrors = {};
                let params = {
                    portfolio: this.formData.id,
                    trust_name: this.formData.trust_name,
                    trust_comment: this.formData.trust_comment,
                }
                this.showLoaderSending = true;
                app.updateTrustInfo(params).then(res => {
                    console.log(res);
                    this.showLoaderSending = false;
                    // this.getPortfolioList(true);
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response.data);
                        //this.formErrors = err.response.data.error;
                        //this.formErrors.token = 1;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            createAccountPublic() {
                this.formErrors = {};
                let params = {
                    portfolio: this.formData.id,
                }
                this.showLoaderSending = true;
                app.addPortfolioPublicLink(params).then(res => {
                    console.log(res);
                    this.$emit('refreshPortfolio', true);
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response.data);
                        //this.formErrors = err.response.data.error;
                        //this.formErrors.token = 1;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            deleteAccountPublic() {
                this.formErrors = {};
                let params = {
                    portfolio: this.formData.id,
                }
                this.showLoaderSending = true;
                app.deletePortfolioPublicLink(params).then(res => {
                    console.log(res);
                    this.$emit('refreshPortfolio', true);
                    this.showLoaderSending = false;
                    //this.getPortfolioList(true);
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response.data);
                        //this.formErrors = err.response.data.error;
                        //this.formErrors.token = 1;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
        }
    };
</script>
