<template>
    <template v-if="!showLoaderSending">
        <div class="filters assets__history-filter d-flex px-3 mb-4">
            <div class="row me-1 w-100">
                <b-form-group
                    id="input-group-period"
                    label="Период"
                    class="col m--date-range"
                >
                    <b-input-group
                        class="m--with-icon"
                    >
                        <template #append>
                            <b-icon-calendar2-check class="icon"></b-icon-calendar2-check>
                        </template>
                        <VueDatePicker
                            v-model="dateRange"
                            model-type="yyyy-MM-dd"
                            format="dd.MM.yyyy"
                            locale="ru"
                            select-text="Выбрать"
                            cancel-text="Отменить"
                            range
                            :auto-apply="true"
                            :close-on-auto-apply="false"
                            :year-range="[2000, 2050]"
                            :max-date="new Date()"
                            :prevent-min-max-navigation="true"
                            text-input
                            :enable-time-picker="false"
                            :month-change-on-scroll="true"
                            :clearable="false"
                            :hide-input-icon="true"
                        >
                            <template #dp-input="{ value, onInput, onEnter, onTab }">
                                <input
                                    v-maska
                                    data-maska="##.##.####  -  ##.##.####"
                                    data-maska-eager
                                    class="form-control border-transparent shadow m--no-pill"
                                    type="text"
                                    :value="value"
                                    @keyup="onInput"
                                    @keydown.enter="onEnter"
                                    @keydown.tab="onTab"
                                />
                            </template>
                        </VueDatePicker>
                    </b-input-group>
                </b-form-group>
                <b-button
                    v-for="item in dataFilters"
                    :key="item.name"
                    :variant="(item.name === currentDataFilter) ? 'success' : 'light'"
                    class="text-nowrap col mt-auto py-2 me-3 mb-3 shadow m--btn-short"
                    :class="(item.name === currentDataFilter) ? 'active' : ''"
                    size="md"
                    pill
                    @click.stop="changeDataFilter(item.name)"
                >
                    {{ item.title }}
                </b-button>
                <div class="col-12" />
                <div class="col-auto mt-auto mb-3">
                    <b-card
                        class="filters__item shadow"
                    >
                        <b-form-checkbox
                            variant="success"
                            v-model="currentHideSold"
                            name="check-button"
                            switch
                        >
                            Скрыть проданные активы
                        </b-form-checkbox>
                    </b-card>
                </div>
                <!--div class="col-auto mt-auto mb-3">
                    <b-card
                        class="filters__item shadow"
                        body-class="text-nowrap"
                    >
                        Ставка налога в портфеле
                        <span class="text-success">
                            <input
                                v-maska
                                data-maska="##"
                                v-model="currentMaxShare"
                                class="form-control form-control-sm m--small border-transparent p-0"
                            />
                            %
                        </span>
                    </b-card>
                </div-->
            </div>
            <BlockFilterTable
                classButton="mt-auto mb-3"
                :tableHeader="tableHeader"
                :tableHeaderName="tableHeaderName"
                :tableFilters="tableFilters"
                @changeFilterTable="changeFilterTable"
            />
        </div>
        <b-table
            v-if="!showLoaderSending"
            :fields="$helpers.prepareTableFields(tableHeader, tableFilters)"
            :items="prepareTableData(history)"
            select-mode="single"
            responsive
            hover
            class="table__data b-table-sticky-header"
        >
            <template #head()="data">
                <TableHeaderSlot
                    :data="data"
                    :currentSorting="currentSorting"
                    @changeSorting="changeSorting"
                />
            </template>
            <template #cell(purchase_date)="data">
                <div class="small">
                    {{ $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') }}<br>
                    {{ $helpers.formatDate(new Date(data.value), 'HH:mm:ss') !== '00:00:00' ? $helpers.formatDate(new Date(data.value), 'HH:mm:ss') : '' }}
                </div>
            </template>
            <template #cell(purchase_price)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(sale_date)="data">
                <div
                    v-if="data.value"
                    class="small"
                >
                    {{ $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') }}<br>
                    {{ $helpers.formatDate(new Date(data.value), 'HH:mm:ss') !== '00:00:00' ? $helpers.formatDate(new Date(data.value), 'HH:mm:ss') : '' }}
                </div>
            </template>
            <template #cell(sale_price)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(price)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(commission)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(income)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                    <template v-if="instrumentType === 'bond'">
                        <sup :id="`popover-block-income-${data.value}-${data.item.purchase_date}-${data.item.quantity}-${data.item._index}`" class="ms-1">
                            <b-icon-info-circle></b-icon-info-circle>
                        </sup>
                        <b-popover
                            custom-class="shadow"
                            :target="`popover-block-income-${data.value}-${data.item.purchase_date}-${data.item.quantity}-${data.item._index}`"
                            triggers="hover"
                            placement="top"
                        >
                            <div>
                                Стоимость покупки: {{ $helpers.toPrice(data.item.income_info.purchase_sum, { pointer: ',' }) }}<br>
                                Стоимость покупки с НКД: {{ $helpers.toPrice(data.item.income_info.purchase_sum_aci, { pointer: ',' }) }}<br>
                                НКД покупки: {{ $helpers.toPrice(data.item.income_info.purchase_aci_sum, { pointer: ',' }) }}<br>
                                Стоимость продажи: {{ $helpers.toPrice(data.item.income_info.sale_sum, { pointer: ',' }) }}<br>
                                Стоимость продажи с НКД: {{ $helpers.toPrice(data.item.income_info.sale_sum_aci, { pointer: ',' }) }}<br>
                                НКД продажи: {{ $helpers.toPrice(data.item.income_info.sale_aci_sum, { pointer: ',' }) }}<br>
                                Амортизации за период: {{ $helpers.toPrice(data.item.income_info.amortization_sum, { pointer: ',' }) }}<br>
                                Доход 2 за период: {{ $helpers.toPrice(data.item.income_info.income, { pointer: ',' }) }}<br>
                                Доход 2 за период с НКД: {{ $helpers.toPrice(data.item.income_info.income_aci, { pointer: ',' }) }}<br>
                            </div>
                        </b-popover>
                    </template>
                </div>
            </template>
            <template #cell(income_user_currency)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(income_percent)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(income_percent_user_currency)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(income_security_payment)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(income_security_payment_percent)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(year_income)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(security_payment_sum)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(income_security_payment_user_currency)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(income_percent_security_payment_user_currency)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(income_percent_security_payment)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(year_income_user_currency)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(pu_last_year)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(pu_last_year_user)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(tax)="data">
                <div class="text-end text-nowrap">
                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                </div>
            </template>
            <template #cell(days_long)="data">
                <div class="text-end text-nowrap">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(years_long)="data">
                <div class="text-end text-nowrap">
                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                </div>
            </template>
            <template #cell(quantity)="data">
                <div :class="['text-end text-nowrap', data.item.is_short && 'text-danger']">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(sold)="data">
                {{ data.value ? 'ДА' : 'НЕТ' }}
            </template>
        </b-table>
        <BlockNavigateTable
            :totalRows="currentTotalRows"
            :currentPage="currentPage"
            :currentPerPage="currentPerPage"
            @changeNavigateTable="changeNavigateTable"
        />
    </template>
    <template v-else>
        <div class="app__loader">
            <b-spinner
                variant="success"
                class="me-3"
            />
            <span class="text-success">Загрузка данных ...</span>
        </div>
    </template>
</template>

<script>
    import { fieldNames } from "@/settings/tables";

    import { app } from "@/services";
    import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import TableHeaderSlot from "@/components/table-header-slot";
    export default {
        name: 'transactions',
        components: {
            BlockFilterTable,
            BlockNavigateTable,
            TableHeaderSlot
        },
        watch: {
            dateRange: {
                immediate: false,
                handler() {
                    const firstDay = new Date(this.dateRange[0]).toLocaleDateString('ru');
                    const lastDay = new Date(this.dateRange[1]);
                    if (firstDay === new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate() - 7).toLocaleDateString('ru')) {
                        this.currentDataFilter = 'week';
                    } else if (firstDay === new Date(lastDay.getFullYear(), lastDay.getMonth() - 1, lastDay.getDate()).toLocaleDateString('ru')) {
                        this.currentDataFilter = 'month';
                    } else if (firstDay === new Date(lastDay.getFullYear() - 1, lastDay.getMonth(), lastDay.getDate()).toLocaleDateString('ru')) {
                        this.currentDataFilter = 'year';
                    } else {
                        this.currentDataFilter = 'all';
                    }
                }
            }
        },
        props: {
            id: {
                type: [Number, String],
                default() { return null; }
            },
            ticker: {
                type: String,
                default() { return null; }
            },
            instrumentType: {
                type: String,
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            currency: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                masks: {
                    input: 'DD.MM.YYYY',
                },
                calendarConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD'
                },
                dateRange: [],
                dataFilters: [{
                    name: 'week',
                    title: 'Неделя'
                }, {
                    name: 'month',
                    title: 'Месяц'
                }, {
                    name: 'year',
                    title: 'Год'
                }, {
                    name: 'all',
                    title: 'Все'
                }],
                currentSorting: {
                    key: 'date',
                    direction: 'desc'
                },
                currentDataFilter: 'all',
                currentHideSold: false,
                currentMaxShare: 5,
                tableFilters: [],
                tableHeaderName: 'assetTransactionItem',
                tableHeader: fieldNames.assetTransactionItem,
                history: [],
                currentPerPage: 50,
                currentPage: 1,
                currentTotalRows: 0,
                showLoaderSending: false,
            };
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        created() {
        },
        mounted() {
            this.showLoaderSending = true;
            app.getPortfolioAssetOperations(this.id, this.ticker, this.portfolioType).then(res => {
                if (!res.error) {
                    this.history = res;
                    this.getDateRange(this.currentDataFilter);
                    console.log(res);
                } else {
                    this.$store.dispatch('showError', {err: { message: res.error }});
                }
                this.showLoaderSending = false;
            }).catch(err => {
                this.showLoaderSending = false;
                this.$store.dispatch('showError', err);
                console.error(err);
            });

            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else {
                    this.tableFilters.push(key);
                }
            });
            Object.keys(this.tableHeader).forEach(key => {
                this.tableHeader[key].currency = this.currency;
            });
        },
        methods: {
            prepareTableData(rowsData=[]) {
                this.currentTotalRows = rowsData.length;
                let list = [...rowsData];
                if (list.length) {
                    console.log('prepareTableData');
                    list.forEach((item, index) => {
                        item._index = index + 1;
                    });
                    list = list.filter(item => {
                        if (this.currentDataFilter === 'all') {
                            return true;
                        }
                        if (new Date(item.purchase_date) >= new Date(this.dateRange[0]) && new Date(item.purchase_date) <= new Date(this.dateRange[1])) {
                            return true;
                        }
                        return false;
                    });
                    list = list.filter(item => {
                        if (this.currentHideSold) {
                            return item.sold ? false : true;
                        }
                        return true;
                    });

                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeDataFilter(name) {
                this.currentDataFilter = name;
                this.getDateRange(this.currentDataFilter);
                this.currentPage = 1;
            },
            changeNavigateTable(page, perPage) {
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            getDateRange(period) {
                let firstDay, lastDay;
                const now = new Date();
                if (period === 'week') {
                    firstDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
                    lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                }
                if (period === 'month') {
                    firstDay = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
                    lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                }
                if (period === 'year') {
                    firstDay = new Date(now.getFullYear() -1, now.getMonth(), now.getDate());
                    lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                }
                if (period === 'all') {
                    let list = [...this.history];
                    firstDay = list.length ? new Date(list.reduce((prev, curr) => prev.purchase_date < curr.purchase_date ? prev : curr).purchase_date) : new Date(now.getFullYear(), now.getMonth(), now.getDate());
                    lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                }
                this.dateRange = [
                    this.$helpers.formatDate(firstDay, 'YYYY-MM-DD'),
                    this.$helpers.formatDate(lastDay, 'YYYY-MM-DD')
                ];
            },
        }
    };
</script>
