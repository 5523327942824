<template>
    <b-button
        variant="link-primary px-2"
        @click.prevent="showDeleteWatchlistInstrument"
    >
        <span class="table__data-row-index">{{ instrument._index }}</span>
        <b-icon-bookmark-fill />
    </b-button>
    <ModalWatchlistInstrumentDelete
        v-if="showModalDeleteWatchlistInstrument"
        :watchlistId="watchlistId"
        :instrument="instrument"
        :show="showModalDeleteWatchlistInstrument"
        @hideModal="hideDeleteWatchlistInstrument"
    />
</template>

<script>
    //import { app } from "@/services";
    import ModalWatchlistInstrumentDelete from "@/components/modals/modal-watchlist-instrument-delete";
    export default {
        emits: ['updateData'],
        components: {
            ModalWatchlistInstrumentDelete,
        },
        props: {
            watchlistId: {
                type: [Number, String],
                default() { return null; }
            },
            instrument: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                showModalDeleteWatchlistInstrument: false,
                showLoaderSending: false
            }
        },
        mounted() {
        },
        methods: {
            showDeleteWatchlistInstrument() {
                this.showModalDeleteWatchlistInstrument = true;
            },
            hideDeleteWatchlistInstrument(refreshData = false) {
                this.showModalDeleteWatchlistInstrument = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('updateData');
                    })
                }
            },
        }
    };
</script>
