<template>
    <div class="app">
        <appSidebar v-if="$route.meta.requiresAuth" />
        <routerView />
        <appError />
        <appLoader />
    </div>
</template>

<script>
    //import { app } from "@/services";
    //import 'v-calendar/dist/style.css';
    import '@splidejs/vue-splide/css';
    import appSidebar from '@/components/app-sidebar.vue';
    import appError from '@/components/app-error.vue';
    import appLoader from '@/components/app-loader.vue';
    export default {
        components: {
            appSidebar,
            appError,
            appLoader
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(to) {
                    document.title = to.meta.title + ' - FinProgress' || 'FinProgress';
                    if (to.query.mode) {
                        this.$store.dispatch('setMode', to.query.mode);
                    } else if (to.query.mode_clear) {
                        this.$store.dispatch('clearMode');
                    }
                }
            },
        },
        created() {
            window.onerror = (message, source, lineno, colno, err) => {
                console.error('Window error', err.message);
                this.$store.dispatch('showError', { err });
            };
            //this.currencyListDefault();
        },
        methods: {
            /*
            currencyListDefault() {
                if (!this.$store.state.currencyCurrent) {
                    app.getCurrency(1).then(res => {
                        let currencies = [res];
                        this.$store.dispatch('setCurrencyList', currencies);
                        this.$store.dispatch('setCurrency', currencies[0]);
                    });
                }
            },
            */
        },
        errorCaptured(err, vm, info) {
            console.error('Local error', err.message, vm, info);
            this.$store.dispatch('showError', { err });
            return false;
        }
    };
</script>
<style lang="sass">
    @import './assets/css/main.scss'
</style>