<template>
    <div 
        class="app__block messages d-flex h-100 flex-column px-4 pt-3 pb-4"
    >
        <BlockUserCurrencies />
        <routerView />
    </div>
</template>

<script>
    //import { app } from "@/services";

    import BlockUserCurrencies from "@/components/block-user-currencies";
    export default {
        name: 'messages',
        components: {
            BlockUserCurrencies,
        },
        props: {
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        watch: {
        },
        data() {
            return {

            };
        },
        created() {
        },
        mounted() {
        },
        methods: {
        }
    };
</script>
