<template>
    <div 
        class="currencies d-flex mb-4"
    >
        <b-dropdown 
            id="dropdown-currency" 
            no-caret variant="light" 
            class="currencies__list w-100 shadow"
        >
            <template #button-content>
                <strong>{{ currencyCurrent.iso_name }}</strong> - {{ currencyCurrent.name }}
            </template>
            <b-dropdown-item-button 
                v-for="item in currencyListFull"
                :key="`currency-list-${item.iso_name}`"
                buttonClass="currencies__select-item"
                :active="currencyCurrent.iso_name === item.iso_name ? true : false"
                :disabled="currencyListSelected.filter(c => item.iso_name === c.iso_name && currencyCurrent.iso_name !== c.iso_name).length ? true : false"
                @click="changeCurrencyList(currencyCurrent.currency, item.id, requered)"
            >
                <template
                    v-if="currencyCurrent.iso_name === item.iso_name"
                >
                    <b-icon-check-circle-fill/>
                </template>
                <span>{{ item.iso_name }}</span>, {{ item.symbol }} - {{ item.name }}
            </b-dropdown-item-button>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        props: {
            index: {
                type: Number,
                default() { return null; }
            },
            currencyListFull: {
                type: Array,
                default() { return []; }
            },
            currencyListSelected: {
                type: Array,
                default() { return []; }
            },
            currencyCurrent: {
                type: Object,
                default() { return {}; }
            },
            requered: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                currencyList: [],
            }
        },
        mounted() {
            /*
            this.currencyList = this.$store.state.currencyList || this.currencyListDefault();
            this.$store.dispatch('setCurrencyList', this.currencyList);
            this.$store.dispatch('setCurrency', this.currencyCurrent);
            */
        },
        methods: {
            changeCurrencyList(index, item, requered) {
                console.log(item);
                this.$emit('changeCurrencyList', index, item, requered);
                /*
                const maxList = 4;
                let idx = this.currencyList.findIndex(c => c.iso_name === item.iso_name);
                if (idx !== -1) {
                    this.currencyList.splice(idx, 1);
                } else {
                    if (this.currencyList.length >= maxList) {
                        this.currencyList.splice(this.currencyListDefault().length, 1);
                    }
                    this.currencyList.push(item);
                }
                this.$store.dispatch('setCurrencyList', this.currencyList);
                if (!this.currencyList.filter(item => item.iso_name === this.$store.state.currencyCurrent.iso_name).length) {
                    this.currencyCurrent = this.currencyList[0];
                    this.$store.dispatch('setCurrency', this.currencyCurrent);
                }
                */
            },
        }
    };
</script>
