<template>
    <b-button
        variant="light"
        class="me-3 mb-2 shadow"
        :class="classButton"
        pill
        @click.stop="showConfigTable()"
    >
        <b-icon-gear></b-icon-gear>
        <span 
            v-if="allFilters !== tableConfigCurrent.length"
            class="position-absolute top-1 start-100 translate-middle p-2 bg-success border border-light rounded-circle"
        />
    </b-button>
    <ModalFilterTableRows
        :show="showModalConfigTable"
        :title="title"
        :items="tableConfig"
        :itemsFiltered="tableConfigCurrent"
        @changeFilter="changeFilter"
        @hideModal="hideConfigTable"
    />
</template>

<script>
    import { app } from "@/services";
    import ModalFilterTableRows from "@/components/modals/modal-filter-table-rows";
    export default {
        emits: ['changeConfigTable'],
        components: {
            ModalFilterTableRows
        },
        props: {
            title: {
                type: String,
                default() { return null; }
            },
            classButton: {
                type: String,
                default() { return null; }
            },
            accountId: {
                type: [Number, String],
                default() { return null; }
            },
            tableConfigName: {
                type: String,
                default() { return null; }
            },
            tableConfig: {
                type: Object,
                default() { return {}; }
            },
            tableConfigCurrent: {
                type: Array,
                default() { return []; }
            },
        },
        data() {
            return {
                showModalConfigTable: false,
            }
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
            allFilters() {
                let filtersItems = 0;
                this.tableConfig.forEach(item => {
                    if (!item.disabled) {
                        filtersItems++;
                        filtersItems += item.items.filter(i => { return !i.disabled; }).length;
                        item.items.forEach(i => {
                            if (!i.disabled && i.alias) {
                                filtersItems += i.alias.length;
                            } 
                        });
                    }
                });
                return filtersItems;
            }
        },
        mounted() {
        },
        methods: {
            changeFilter(filter) {
                this.$emit('changeConfigTable', filter);
            },
            showConfigTable() {
                this.showModalConfigTable = true;
            },
            hideConfigTable(filter) {
                if (this.tableConfigName) {
                    let settings = this.userSettings;
                    if (this.accountId) {
                        if (!settings.accounts) settings.accounts = {};
                        if (!settings.accounts[this.accountId]) settings.accounts[this.accountId] = {};
                        settings.accounts[this.accountId][this.tableConfigName] = filter;
                    } else {
                        if (!settings.tableConfig) settings.tableConfig = {};
                        settings.tableConfig[this.tableConfigName] = filter;
                    }
                    app.updateUser({ user_settings: settings }).then(res => {
                        console.log(res);
                        delete res.currencies;
                        this.$store.dispatch('setUser', res);
                    }).catch(err => {
                        console.error(err);
                    });
                }
                this.showModalConfigTable = false;
            },
        }
    };
</script>
