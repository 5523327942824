<template>
    <div class="app__block auth d-flex w-100 h-100 flex-column">
        <router-link
            :to="{ name: 'home' }"
            class="auth__logo"
        />
        <div class="auth__block d-flex flex-column my-auto mx-auto">
            <b-button
                variant="success"
                class="d-block col-12 my-3"
                size="lg"
                pill
                @click.stop="showAuth"
            >
                Войти
            </b-button>
            <b-button
                type="submit"
                variant="success"
                class="d-block col-12 my-3"
                size="lg"
                pill
                @click.stop="showRegistration"
            >
                Зарегистрироваться
            </b-button>
            <b-overlay
                :show="showLoaderSending"
                no-wrap
                spinner-variant="success"
            />
        </div>
        <ModalAuth
            :show="showModalAuth"
            @hideModal="hideAuth"
            @showPasswordReset="showPasswordReset"
            @showRegistration="showRegistration"
        />
        <ModalRegistration
            :show="showModalRegistration"
            @hideModal="hideRegistration"
            @showAuth="showAuth"
        />
        <ModalPasswordReset
            :show="showModalPasswordReset"
            @hideModal="hidePasswordReset"
            @showAuth="showAuth"
        />
    </div>
</template>

<script>
    import { serviceUrl } from '@/settings';
    import { app } from "@/services";
    import ModalAuth from "@/components/modals/modal-auth";
    import ModalRegistration from "@/components/modals/modal-registration";
    import ModalPasswordReset from "@/components/modals/modal-password-reset";

    export default {
        name: 'index',
        components: {
            ModalAuth,
            ModalRegistration,
            ModalPasswordReset,
        },
        props: {
        },
        data() {
            return {
                serviceUrl: serviceUrl,
                showModalAuth: false,
                showModalRegistration: false,
                showModalPasswordReset: false,
                showModalContract: false,
                showModalPolicy: false
            };
        },
        computed: {
        },
        created() {
            let query = this.$router.currentRoute.value.query;
            if (query.code) {
                let params = {
                    code: query.code,
                    redirect_uri: `${this.serviceUrl.url}/auth`
                };
                this.showLoaderSending = true;
                app.getUserVkCallback(params).then(res => {
                    if (res.access && res.refresh) {
                        this.$store.dispatch('setToken', res);
                        app.getUser().then(res => {
                            let currencies = res.currencies;
                            let currency = currencies.filter(item => { return item.default })[0];
                            this.$store.dispatch('setCurrencyList', currencies);
                            this.$store.dispatch('setCurrency', currency);
                            delete res.currencies;
                            this.$store.dispatch('setUser', res);
                            app.getPortfolioList({}).then(res => {
                                this.showLoaderSending = false;
                                this.$emit('hideModal');
                                if (!res.error) {
                                    this.portfolioList = res;
                                    if (res.length) {
                                        this.next('accounts');
                                    } else {
                                        this.next('stock');
                                    }
                                } else {
                                    this.$store.dispatch('showError', {err: { message: res.error }});
                                }
                            }).catch(err => {
                                this.showLoaderSending = false;
                                console.error(err);
                            });
                        }).catch(err => {
                            this.showLoaderSending = false;
                            this.formErrors = {
                                email: 1,
                                password: 1,
                                detail: err.detail
                            }
                        });
                    } else {
                        this.showLoaderSending = false;
                        this.formErrors = {
                            detail: 'Ошибка получения токена'
                        }
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            }
        },
        methods: {
            showAuth() {
                this.showModalAuth = true;
            },
            hideAuth() {
                this.showModalAuth = false;
            },
            showRegistration() {
                this.showModalRegistration = true;
            },
            hideRegistration() {
                this.showModalRegistration = false;
            },
            showPasswordReset() {
                this.showModalPasswordReset = true;
            },
            hidePasswordReset() {
                this.showModalPasswordReset = false;
            },

            showContract() {
                this.showModalContract = true;
            },
            hideContract() {
                this.showModalContract = false;
            },
            showPolicy() {
                this.showModalPolicy = true;
            },
            hidePolicy() {
                this.showModalPolicy = false;
            },
            next(name) {
                this.$router.push({ name: name });
            },
        }
    };
</script>
