<template>
    <div class="profile__user">
        <b-form 
            @submit="onSubmitProfile" 
            class="row profile__user-form pt-3"
        >
            <b-form-group
                label="Ваше имя"
                class="profile__user-email col-12"
                description="Отображается только внутри системы"
            >
                <b-form-input
                    v-model="formData.first_name"
                    type="text"
                    placeholder="Укажите ваше имя"
                    size="sm"
                    class="shadow-sm m--no-pill"
                    :disabled="showLoaderSending"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Публичное имя"
                class="profile__user-email col-12"
                description="Отображается другим людям в ваших публичных портфелях"
            >
                <b-form-input
                    v-model="formData.public_name"
                    type="text"
                    placeholder="Укажите ваше публичное имя"
                    size="sm"
                    class="shadow-sm m--no-pill"
                    :disabled="showLoaderSending"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Email"
                class="profile__user-email col-8"
            >
                <b-form-input
                    v-model="formData.email"
                    type="text"
                    placeholder="Укажите ваш E-mail"
                    size="sm"
                    class="shadow-sm m--no-pill"
                    readonly
                    :disabled="showLoaderSending"
                ></b-form-input>
                <!--
                <b-form-invalid-feedback :state="validationEmail">
                    Email не подтвержден
                </b-form-invalid-feedback>
                <b-form-valid-feedback :state="validationEmail">
                    Email подтвержден
                </b-form-valid-feedback>
                -->
            </b-form-group>
            <!--div class="col-4 mt-auto mb-3 pb-1">
                <b-button
                    variant="outline-primary"
                    class="w-100 py-2 mb-4"
                    pill
                    @click.prevent="confirmEmail"
                >
                    Подтвердить
                </b-button>
            </div-->
            <b-form-group
                label="Пароль"
                class="profile__user-password col-8"
            >
                <b-button
                    variant="outline-primary"
                    class="col-12 py-2 mt-auto mb-3"
                    pill
                    @click.prevent="showPasswordChange"
                >
                    Изменить пароль
                </b-button>
            </b-form-group>
            <b-form-group
                label="ВКонтакте"
                class="profile__user-vk col-8"
            >
                <b-button
                    variant="outline-primary"
                    class="col-12 py-2 mt-auto mb-3"
                    pill
                    @click.prevent="setVK"
                >
                    Привязать аккаунт ВКонтакте
                </b-button>
            </b-form-group>
            <b-form-group
                label="Ваш часовой пояс"
                class="profile__user-timezone col-8"
            >
                <Multiselect
                    v-model="formData.timezone"
                    :options="timeZones"
                    class="shadow-sm m--no-pill"
                    placeholder="Ваш часовой пояс"
                />
            </b-form-group>

            <b-form-group
                label="Уведомления"
                class="profile__user-notification col-12"
            >
                <b-card
                    class="shadow p-0 mb-4"
                    body-class="px-4 py-1"
                >
                    <b-form-checkbox 
                        variant="success" 
                        v-model="formData.notification" 
                        name="check-button" 
                        switch
                    >
                        Разрешить присылать уведомления на вашу почту
                    </b-form-checkbox>
                </b-card>
            </b-form-group>
            <div class="col-12">
                <b-alert
                    variant="success"
                    :show="addSuccess"
                >
                    Данные успешно сохранены
                </b-alert>
                <b-alert
                    variant="danger"
                    :show="addError"
                >
                    {{ addError }}
                </b-alert>
            </div>
            <div class="col">
                <b-button
                    type="submit"
                    variant="primary"
                    class="w-100 py-2"
                    pill
                >
                    Сохранить настройки
                </b-button>
            </div>
            <div class="col">
                <b-button
                    type="reset"
                    variant="outline-secondary"
                    class="col-6 py-2 mt-auto mb-3"
                    pill
                >
                    Отменить
                </b-button>
            </div>
            <b-overlay 
                :show="showLoaderSending"
                variant="transparent"
                no-wrap
                spinner-variant="success"
            />
        </b-form>
        <ModalPasswordChange
            :show="showModalPasswordChange"
            @hideModal="hidePasswordChange"
        />
    </div>
</template>

<script>
    import { app } from "@/services";
    import { timeZones } from "@/settings/timezones";
    import Multiselect from '@vueform/multiselect';
    import ModalPasswordChange from "@/components/modals/modal-password-change";
    export default {
        name: 'profileUser',
        components: {
            Multiselect,
            ModalPasswordChange
        },
        props: {
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        },
        data() {
            return {
                timeZones: timeZones,
                formData: {},
                addSuccess: false,
                addError: null,
                validationEmail: true,
                showModalPasswordChange: false,
                showLoaderSending: false,
            };
        },
        created() {
        },
        mounted() {
            this.formData = {
                first_name: this.user.first_name,
                public_name: this.user.public_name,
                email: this.user.email
            };
        },
        methods: {
            confirmEmail() {
                console.log('confirmEmail');
            },
            showPasswordChange() {
                this.showModalPasswordChange = true;
            },
            hidePasswordChange() {
                this.showModalPasswordChange = false;
            },
            setVK() {
                console.log('setVK');
            },
            onSubmitProfile() {
                this.formErrors = {};
                this.showLoaderSending = true;
                this.addSuccess = false;
                this.addError = null;
                let params = this.formData;
                app.updateUser(params).then(res => {
                    console.log(res);
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                    this.addSuccess = true;
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.addError = err.detail || err.message;
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
        }
    };
</script>
