<template>
    <b-modal
        id="modal-alert"
        v-model="showModal"
        classes="modal__container"
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0 m--no-header"
        content-class="modal__block"
        title=""
        centered
        hide-footer
        scrollable
        @hidden="hideModal"
    >
        <div class="modal__content profile">
            <div class="profile__tariffs">
                <div class="profile__tariffs-item">
                    <template v-if="diffDays < 1">
                        <div class="profile__tariffs-item-icon m--blocked"></div>
                        <div class="profile__tariffs-item-name">Ваш тариф «{{ user.tariff?.name }}» закончился</div>
                        <div class="profile__tariffs-item-info">Вы потеряли доступ к возможностям:</div>
                        <div class="profile__tariffs-item-params my-4 m--max-width">
                            <template
                                v-for="(param, index) in tariff.elements"
                                :key="`param-${index}`"
                            >
                                <div
                                    v-if="param.is_active"
                                    :class="['profile__tariffs-item-param']"
                                >
                                    <span>{{ param.name }} {{ param.value }}</span>
                                    <!--Helper
                                        v-if="param.key_help"
                                        :id="`tariff-${tariff.id}-${param.key_help}`"
                                        :helper="param.key_help"
                                        info
                                        classParent="profile__tariffs-item"
                                    /-->
                                </div>
                            </template>
                        </div>
                        <div :class="['profile__tariffs-item-button m--max-width', tariff.default_free ? 'invisible' : '']">
                            <b-button
                                class="w-100"
                                variant="primary"
                                size="lg"
                                pill
                                :disabled="tariff.disabled"
                                @click.stop="next('profile-tariffs')"
                            >
                                Купить тариф
                            </b-button>
                        </div>
                    </template>
                    <template v-else-if="diffDays < 2">
                        <div class="profile__tariffs-item-icon m--danger"></div>
                        <div class="profile__tariffs-item-name">
                            До окончания тарифа «{{ user.tariff?.name }}»
                            <div class="profile__tariffs-item-name-days m--danger">{{ $helpers.stringForNumber(diffDays, ['день', 'дня', 'дней']) }}</div>
                        </div>
                        <div class="profile__tariffs-item-info">Продлите тариф, и не теряйте доступ к функционалу:</div>
                        <div class="profile__tariffs-item-params my-4 m--max-width">
                            <template
                                v-for="(param, index) in tariff.elements"
                                :key="`param-${index}`"
                            >
                                <div
                                    v-if="param.is_active"
                                    :class="['profile__tariffs-item-param is-active']"
                                >
                                    <span>{{ param.name }} {{ param.value }}</span>
                                </div>
                            </template>
                        </div>
                        <div :class="['profile__tariffs-item-button m--max-width', tariff.default_free ? 'invisible' : '']">
                            <b-button
                                class="w-100"
                                variant="primary"
                                size="lg"
                                pill
                                :disabled="tariff.disabled"
                                @click.stop="next('profile-tariffs')"
                            >
                                Продлить тариф
                            </b-button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="profile__tariffs-item-icon m--warning"></div>
                        <div class="profile__tariffs-item-name">
                            До окончания тарифа «{{ user.tariff?.name }}»
                            <div class="profile__tariffs-item-name-days m--warning">{{ $helpers.stringForNumber(diffDays, ['день', 'дня', 'дней']) }}</div>
                        </div>
                        <div class="profile__tariffs-item-info">Продлите тариф, и не теряйте доступ к функционалу:</div>
                        <div class="profile__tariffs-item-params my-4 m--max-width">
                            <template
                                v-for="(param, index) in tariff.elements"
                                :key="`param-${index}`"
                            >
                                <div
                                    v-if="param.is_active"
                                    :class="['profile__tariffs-item-param is-active']"
                                >
                                    <span>{{ param.name }} {{ param.value }}</span>
                                </div>
                            </template>
                        </div>
                        <div :class="['profile__tariffs-item-button m--max-width', tariff.default_free ? 'invisible' : '']">
                            <b-button
                                class="w-100"
                                variant="primary"
                                size="lg"
                                pill
                                :disabled="tariff.disabled"
                                @click.stop="next('profile-tariffs')"
                            >
                                Продлить тариф
                            </b-button>
                        </div>
                    </template>
                    <b-overlay
                        :show="showLoaderModal"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    //import { serviceUrl } from '@/settings';
    import { app } from "@/services";
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        computed: {
            currentTariff() {
                return this.$store.state.user.tariff || {};
            },
            diffDays() {
                let currDate = new Date();
                var endSubDate = new Date(this.$store.state.user.tariff.subscription_end);
                var timeDiff = endSubDate.getTime() - currDate.getTime();
                return Math.ceil(timeDiff / (1000 * 3600 * 24));
            },
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            user() {
                return this.$store.state.user;
            }
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            }
        },
        data() {
            return {
                tariff: {},
                showModal: this.show,
                showLoaderModal: false,
            };
        },
        created() {
        },
        mounted() {
            this.getTariff()
        },
        methods: {
            getTariff() {
                this.showLoaderModal = true;
                app.getBillingById(this.user.tariff.id).then(res => {
                    console.log(res);
                    this.tariff = res;
                    this.showLoaderModal = false;
                }).catch(err => {
                    this.showLoaderModal = false;
                    console.error(err);
                });
            },
            hideModal() {
                this.$store.dispatch('hideAlert');
                this.$emit('hideModal');
            },
            next(name) {
                this.hideModal();
                this.$router.push({ name: name });
            },
        }
    };
</script>
