<template>
    <div class="app__block home d-flex w-100 h-100 flex-column">
        <div class="header home__header container-fluid">
            <div class="header__inner">
                <div class="header__top">
                    <div class="header__logo me-4" />
                    <div
                        class="header__top-inner"
                        :class="openMenu ? 'is-open' : ''"
                    >
                        <div class="header__logo-mobile" />
                        <div class="header__menu">
                            <a
                                href="#"
                                class="header__menu-item"
                                @click.prevent="scrollTo('capability')"
                            >
                                Возможности
                            </a>
                            <a
                                href="#"
                                class="header__menu-item"
                                @click.prevent="scrollTo('tariffs')"
                            >
                                Тарифы
                            </a>
                            <a
                                href="#"
                                class="header__menu-item"
                            >
                                Поддержка
                            </a>
                        </div>
                        <div class="header__buttons text-end">
                            <b-button
                                :variant="openMenu ? 'outline-primary' : 'outline-light'"
                                class="ms-3 my-2"
                                size="lg"
                                pill
                                @click.stop="showAuth"
                            >
                                Войти в систему
                            </b-button>
                            <b-button
                                v-if="!user?.id"
                                type="submit"
                                :variant="openMenu ? 'primary' : 'light'"
                                class="ms-3 my-2"
                                size="lg"
                                pill
                                @click.stop="showRegistration"
                            >
                                Зарегистрироваться
                            </b-button>
                        </div>
                    </div>
                    <a
                        href="#"
                        class="header__top-burger"
                        @click.prevent="toggleMenu"
                    />
                </div>
                <div class="header__title">
                    Сервис создания пассивного дохода
                </div>
                <div class="header__info">
                    <div class="header__info-block">
                        <div class="header__info-item">Движение денежных средств</div>
                        <div class="header__info-item">Всесторонний анализ портфеля</div>
                        <div class="header__info-item">Легкий импорт данных</div>
                        <div class="header__info-item">Планирование будущих инвестиций</div>
                        <div class="header__info-item">Система аналитики</div>
                        <div class="header__info-item">Скоро — новые функции</div>
                    </div>
                    <div class="header__info-buttons">
                        <b-button
                            variant="light"
                            class=""
                            size="lg"
                            pill
                            @click.stop="showRegistration"
                        >
                            {{ user?.id ? 'Войти в систему' : 'Попробовать бесплатно' }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="home__main mb-5">
            <!--div class="home__main-block container">
                <div class="home__main-screenshot">
                    <img src="../assets/img/pics/screenshot-top.png" alt="" />
                </div>
            </div-->
            <div
                v-if="showStats"
                class="home__main-block container-fluid"
            >
                <div class="home__main-stats d-flex">
                    <div class="home__main-stats-item col-6 col-sm-4">
                        <div class="home__main-stats-item-inner">
                            <div class="home__main-stats-title">1 Месяц</div>
                            <div class="home__main-stats-info m--free">Бесплатно</div>
                        </div>
                    </div>
                    <div class="home__main-stats-item col-6 col-sm-4">
                        <div class="home__main-stats-item-inner">
                            <div class="home__main-stats-title">+100 К</div>
                            <div class="home__main-stats-info m--persons">Активных пользователей</div>
                        </div>
                    </div>
                    <div class="home__main-stats-item col-6 col-sm-4">
                        <div class="home__main-stats-item-inner">
                            <div class="home__main-stats-title">+180 М</div>
                            <div class="home__main-stats-info m--invest">Инвестиций</div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref="capability"
                class="home__main-title text-center container"
            >
                Возможности сервиса «КАПИТАЛ»
            </div>
            <div class="home__capability mb-5">
                <div class="home__capability-block container-fluid d-flex flex-wrap">
                    <div class="home__capability-screenshot col-12 col-lg-6 pt-5 pb-5">
                        <div class="home__capability-screenshot-inner m--screen-1">
                            <img src="../assets/img/pics/capability-1.png" alt="" />
                        </div>
                    </div>
                    <div class="home__capability-content col-12 col-lg-6">
                        <div class="home__capability-title m--green">Легкая и простая загрузка отчетов брокеров</div>
                        <ul>
                            <li>Поддержка различных брокеров</li>
                            <li>Возможность загружать данные по API (Тинькофф)</li>
                            <li>Импорт отчета из Excel</li>
                        </ul>
                        <p><strong>Не нашли своего брокера?<br>Напишите нам и мы его добавим.</strong></p>
                    </div>
                </div>
                <div class="home__capability-block container-fluid d-flex flex-wrap flex-row flex-lg-row-reverse m--white pt-5">
                    <div class="home__capability-screenshot col-12 col-lg-6 pb-5">
                        <div class="home__capability-screenshot-inner m--screen-2">
                            <img src="../assets/img/pics/capability-2.png" alt="" />
                        </div>
                    </div>
                    <div class="home__capability-content col-12 col-lg-6">
                        <div class="home__capability-title">Расширенная аналитика портфеля и активов</div>
                        <ul>
                            <li>Подробное движение денежных средств</li>
                            <li>Покажем реальную доходность каждого лота в портфеле с момента ее покупки до продажи</li>
                            <li>Дополнительные инструменты анализа</li>
                        </ul>
                        <p><strong>Слишком много данных для аналитики?<br>Вы можете настроить систему под себя с помощью удобных настроек.</strong></p>
                    </div>
                </div>
                <div class="home__capability-block container-fluid d-flex flex-column flex-lg-column-reverse mb-3 m--white">
                    <div class="home__capability-screenshot col-12 col-lg-9 mt-4 mx-auto">
                        <div class="home__capability-screenshot-inner m--screen-3">
                            <img src="../assets/img/pics/capability-3.png" alt="" />
                        </div>
                    </div>
                    <div class="home__capability-content col-12 col-lg-9 col-xxl-5 mx-auto">
                        <div class="home__capability-title">Планируйте вашу инвестиционную деятельность</div>
                        <ul>
                            <li>Разработка плана инвестиций</li>
                            <li>Сравнение плана инвестиций и ДДС</li>
                            <li>Преимущество сервиса планирования</li>
                        </ul>
                        <p>Поможем вам заработать, приумножить, накопить на вашу мечту или безбедную старость.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="home__banner">
            <div class="home__banner-inner">
                <a href="https://proakcii.finprogress.ru/" target="_blank">
                    <img src="../assets/img/pics/kurs-invest.jpg" alt="" />
                </a>
            </div>
        </div>
        <div class="home__main m--background mb-5">
            <div class="home__main-block container">
                <div class="home__charts d-flex">
                    <div class="home__charts-left col-12 col-lg-7 pe-4">
                        <div class="home__charts-title">
                            Система принятия решений
                        </div>
                        <div class="home__charts-content">
                            <ul>
                                <li>Сигнал "Фиксация прибыли (скользящий стоп-лосс)"</li>
                                <li>Сигнал "Минимизация убытков (рубить хвосты)"</li>
                                <li>Сигнал "Исключить медленный рост (боковик)"</li>
                                <li>Сигнал "Ребалансировка активов"</li>
                            </ul>
                            Система подскажет вам, какие стоит предпринимать действия в различных ситуациях для повышения эффективности ваших инвестиций.
                        </div>
                    </div>
                    <!--div class="home__charts-screenshot col-5">
                        <img src="../assets/img/pics/dashboard.png" alt="" />
                    </div-->
                </div>
            </div>
        </div>
        <div class="home__main mt-5">
            <div class="home__main-block container">
                <div
                    ref="tariffs"
                    class="home__tariffs"
                >
                    <div class="home__tariffs-title text-center">Тарифы</div>
                    <!--div class="home__tariffs-content text-center">
                        Оплачивайте онллайн и работайте в портфеле сколько вам удобно
                    </div-->
                    <div class="home__tariffs-switch">
                        <label class="home__tariffs-switch-label" for="time-switcher">
                            {{ periods[0].name }}
                        </label>
                        <b-form-checkbox
                            v-model="period"
                            id="time-switcher"
                            name="period"
                            switch
                            size="lg"
                        />
                        <label class="home__tariffs-switch-label" for="time-switcher">
                            {{ periods[1].name }}
                        </label>
                    </div>
                    <div class="home__tariffs-block">
                        <Splide
                            ref="tariffs"
                            :options="splideOptions"
                        >
                            <SplideSlide
                                v-for="tariff in tariffs"
                                :key="`tariff-${tariff.id}`"
                                class="pb-5"
                            >
                                <div
                                    class="home__tariffs-item h-100"
                                    :class="tariff.class"
                                >
                                    <b-card
                                        class="px-2 py-3 h-100"
                                    >
                                        <div class="home__tariffs-item-title">
                                            {{ tariff.name }}
                                        </div>
                                        <div
                                            class="home__tariffs-item-info"
                                            v-html="tariff.description"
                                        />
                                        <div class="home__tariffs-item-price">
                                            {{ $helpers.toPrice(tariff.price[periods[currentPeriod].id], { sign: '₽'}) }} <span>/{{ periods[currentPeriod].name }}</span>
                                        </div>
                                        <b-button
                                            :variant="tariff.button"
                                            class="w-100 mb-5"
                                            size="lg"
                                            pill
                                            @click.stop=""
                                        >
                                            Подключить
                                        </b-button>
                                        <ul class="home__tariffs-item-params">
                                            <li
                                                v-for="(param, index) in tariff.params"
                                                :key="`param-${index}`"
                                            >
                                                {{ param }}
                                            </li>
                                        </ul>
                                    </b-card>
                                </div>
                            </SplideSlide>
                        </Splide>
                    </div>
                </div>
            </div>
            <div class="home__main-block container">
                <b-card
                    class="shadow mb-5 py-4"
                >
                    <div class="home__charts m--last d-flex">
                        <div class="home__charts-left col-12 col-md-5 ps-0 ps-md-3">
                            <div class="home__charts-title m--uppercase">
                                Попробуйте бесплатно все возможности системы «КАПИТАЛ» в течение месяца
                            </div>
                            <b-button
                                variant="primary"
                                class=""
                                size=""
                                pill
                                @click.stop="showRegistration"
                            >
                                {{ user?.id ? 'Войти в систему' : 'Создать аккаунт' }}
                            </b-button>
                        </div>
                        <div class="home__charts-screenshot d-none d-md-block col-7 pe-4">
                            <img src="../assets/img/pics/charts.jpg" alt="" />
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
        <div class="footer home__footer container-fluid py-4 mt-0 mt-sm-2 mt-md-3 mt-lg-5">
            <div class="footer__block">
                <div class="footer__copyright">
                    <div class="footer__copyright-item">© «КАПИТАЛ» 2022 - 2024</div>
                    <a href="https://flexites.org" target="_blank" class="footer__copyright-item">Разработка сервиса - Flexites</a>
                </div>
                <div class="footer__menu">
                    <div class="footer__menu-block">
                        <a
                            href="#"
                            class="footer__menu-item"
                            @click.prevent="scrollTo('capability')"
                        >
                            Возможности
                        </a>
                        <a
                            href="#"
                            class="footer__menu-item"
                            @click.prevent="scrollTo('tariffs')"
                        >
                            Тарифы
                        </a>
                        <a href="#" class="footer__menu-item">Поддержка</a>
                    </div>
                    <div class="footer__menu-block">
                        <a href="#" class="footer__menu-item">Политика конфиденциальности</a>
                        <a href="#" class="footer__menu-item">Соглашение на обработку данных</a>
                    </div>
                </div>
                <div class="footer__buttons">
                    <b-button
                        variant="outline-primary"
                        class="ms-3 my-2"
                        size="lg"
                        pill
                        @click.stop="showAuth"
                    >
                        Войти в систему
                    </b-button>
                    <b-button
                        v-if="!user?.id"
                        type="submit"
                        variant="primary"
                        class="ms-3 my-2"
                        size="lg"
                        pill
                        @click.stop="showRegistration"
                    >
                        Зарегистрироваться
                    </b-button>
                </div>
            </div>

        </div>
        <ModalAuth
            :show="showModalAuth"
            @hideModal="hideAuth"
            @showPasswordReset="showPasswordReset"
            @showRegistration="showRegistration"
        />
        <ModalRegistration
            :show="showModalRegistration"
            @hideModal="hideRegistration"
            @showAuth="showAuth"
        />
        <ModalPasswordReset
            :show="showModalPasswordReset"
            @hideModal="hidePasswordReset"
            @showAuth="showAuth"
        />
    </div>
</template>

<script>
    //import { app } from "@/services";
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import ModalAuth from "@/components/modals/modal-auth";
    import ModalRegistration from "@/components/modals/modal-registration";
    import ModalPasswordReset from "@/components/modals/modal-password-reset";
    //import Policy from "@/components/policy";

    export default {
        name: 'index',
        components: {
            Splide,
            SplideSlide,
            ModalAuth,
            ModalRegistration,
            ModalPasswordReset,
            //Policy
        },
        props: {
        },
        data() {
            return {
                showStats: false,
                openMenu: false,
                tariffs: [{
                    id: 1,
                    class: '',
                    name: 'Базовый',
                    description: '<br>',
                    button: 'primary',
                    params: [
                        '1 портфель',
                        'Ограниченный доступ к функционалу',
                        'Ограниченный доступ к ДДС'
                    ],
                    price: {
                        month: 0,
                        year: 0
                    }
                }, {
                    id: 2,
                    class: 'm--dark',
                    name: 'Стандарт',
                    description: '<br>',
                    button: 'outline-primary',
                    params: [
                        '1 портфель',
                        'Неограниченное число активов',
                        'Доступ к полному функционалу',
                        'Доступ к ДДС'
                    ],
                    price: {
                        month: 490,
                        year: 5500
                    }
                }, {
                    id: 3,
                    class: '',
                    name: 'Премиум',
                    description: '<br>',
                    button: 'primary',
                    params: [
                        'Неограниченное число портфелей',
                        'Неограниченное число активов',
                        'Доступ к полному функционалу',
                        'Доступ к ДДС',
                        'Доступ к новым функциям'
                    ],
                    price: {
                        month: 1190,
                        year: 12000
                    }
                }],
                splideOptions: {
                    arrows     : false,
                    fixedWidth : 380,
                    gap        : 20,
                    start      : 1,
                    rewind     : true,
                    pagination : false,
                    focus      : 'center',
                },
                periods: [{
                    id: 'month',
                    name: 'Месяц'
                }, {
                    id: 'year',
                    name: 'Год'
                }],
                period: false,
                showModalAuth: false,
                showModalRegistration: false,
                showModalPasswordReset: false,
                showModalContract: false,
                showModalPolicy: false
            };
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            currentPeriod() {
                return this.period ? 1 : 0;
            }
        },
        created() {
            if (process.env.NODE_ENV !== 'development') {
                window.location.href = '//finprogress.pro';
            }
            //this.$store.dispatch('hideError');
        },
        methods: {
            toggleMenu() {
                this.openMenu = !this.openMenu;
            },
            showAuth() {
                if (this.user?.id) {
                    this.$router.push({ name: 'accounts' });
                } else {
                    this.showModalAuth = true;
                }
            },
            hideAuth() {
                this.showModalAuth = false;
            },
            showRegistration() {
                if (this.user?.id) {
                    this.$router.push({ name: 'accounts' });
                } else {
                    this.showModalRegistration = true;
                }
            },
            hideRegistration() {
                this.showModalRegistration = false;
            },
            showPasswordReset() {
                this.showModalPasswordReset = true;
            },
            hidePasswordReset() {
                this.showModalPasswordReset = false;
            },
            scrollTo(ref) {
                if (this.$refs[ref]) {
                    this.$refs[ref].scrollIntoView({ behavior: 'smooth' });
                }
            },

            showContract() {
                this.showModalContract = true;
            },
            hideContract() {
                this.showModalContract = false;
            },
            showPolicy() {
                this.showModalPolicy = true;
            },
            hidePolicy() {
                this.showModalPolicy = false;
            },
        }
    };
</script>
