<template>
    <b-button
        variant="light"
        :class="['shadow filters__btn', classButton]"
        pill
        @click.stop="showFilterTable()"
    >
        <b-icon-sliders2-vertical></b-icon-sliders2-vertical>
    </b-button>
    <ModalFilterTableCols
        :show="showModalFilterTable"
        :items="tableHeader"
        :groups="tableHeaderGroups"
        :itemsFiltered="tableFilters"
        :minCols="minCols"
        @changeFilter="changeFilter"
        @hideModal="hideFilterTable"
    />
</template>

<script>
    import { app } from "@/services";
    import ModalFilterTableCols from "@/components/modals/modal-filter-table-cols";
    export default {
        emits: ['changeFilterTable'],
        components: {
            ModalFilterTableCols
        },
        props: {
            classButton: {
                type: String,
                default() { return 'ms-auto mb-2'; }
            },
            accountId: {
                type: [Number, String],
                default() { return null; }
            },
            tableHeaderName: {
                type: String,
                default() { return null; }
            },
            tableHeader: {
                type: Object,
                default() { return {}; }
            },
            tableHeaderGroups: {
                type: Array,
                default() { return []; }
            },
            tableFilters: {
                type: Array,
                default() { return []; }
            },
            minCols: {
                type: Number,
                default() { return 1; }
            },
        },
        data() {
            return {
                showModalFilterTable: false,
            }
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        mounted() {
        },
        methods: {
            changeFilter(filter) {
                this.$emit('changeFilterTable', filter);
            },
            showFilterTable() {
                this.showModalFilterTable = true;
            },
            hideFilterTable(filter) {
                console.log('hideFilterTable');
                if (this.tableHeaderName) {
                    let settings = this.userSettings;
                    if (this.accountId) {
                        if (!settings.accounts) settings.accounts = {};
                        if (!settings.accounts[this.accountId]) settings.accounts[this.accountId] = {};
                        settings.accounts[this.accountId][this.tableHeaderName] = filter;
                    } else {
                        if (!settings.tableHeader) settings.tableHeader = {};
                        settings.tableHeader[this.tableHeaderName] = filter;
                    }
                    app.updateUser({ user_settings: settings }).then(res => {
                        console.log(res);
                        delete res.currencies;
                        this.$store.dispatch('setUser', res);
                    }).catch(err => {
                        console.error(err);
                    });
                }
                this.showModalFilterTable = false;
            },
        }
    };
</script>
