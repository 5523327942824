<template>
    <template v-if="!showLoaderSending">
        <div class="app__block-title">
            <h1 class="mb-5">История сделок {{ asset.instrument.short_name || asset.instrument.name }}</h1>
        </div>
        <div class="assets__info mb-2">
            <div class="assets__info-block row">
                <div
                    class="assets__info-block-item col-12 col-lg-10 col-xl-8 col-xxl-6 mb-4"
                >
                    <b-card
                        class="shadow py-3 h-100"
                        bodyClass="assets__info-block-item-inner"
                    >
                        <div
                            class="assets__info-block-item-title m--green h1 d-flex justify-content-between"
                        >
                             {{ asset.instrument.short_name || asset.instrument.name }}
                            <div class="ps-2">
                                {{ $helpers.toPrice(asset.instrument.price, { pointer: ',' }) }}
                            </div>
                        </div>
                        <div
                            class="assets__info-block-item-title m--green d-flex justify-content-between"
                        >
                            <span>
                                <template v-if="asset.instrument.short_name">
                                    {{ asset.instrument.name }}<br>
                                </template>
                                {{ asset.instrument.ticker }}
                            </span>
                            <span class="ps-2">{{ asset.instrument.currency.iso_name ? asset.instrument.currency.iso_name.toUpperCase() : '' }}</span>
                        </div>
                        <div class="assets__info-block-item-desc pt-3 m--green d-flex justify-content-between">
                            {{ asset.instrument.instrument_type.name }} — {{ asset.instrument.exchange_name || asset.instrument.exchange }} {{ asset.instrument.country_of_risk_name ? `— ${asset.instrument.country_of_risk_name}` : `` }}
                            <span class="ps-2">
                                {{ $helpers.stringForNumber(asset.instrument.lot, ['ЦБ', 'ЦБ', 'ЦБ']) }} / лот
                            </span>
                        </div>
                        <div
                            v-if="showTwelveMonthIncome"
                            class="assets__info-block-item-desc pt-3 d-flex justify-content-between"
                            :class="asset.instrument.twelve_month_income >= 0 ? 'm--green' : 'm--red'"
                        >
                            П/У за последние 12 мес.
                            <span class="ps-2">
                                {{ $helpers.toPrice(asset.instrument.twelve_month_income, { sign: '%', pointer: ',' }) }}
                            </span>
                        </div>
                    </b-card>
                </div>
                <div
                    v-if="asset.instrument.instrument_type.key === 'bond'"
                    class="assets__info-block-item col-12 col-lg-10 col-xl-8 col-xxl-6 mt-auto mb-4"
                >
                    <b-card
                        class="shadow py-3 h-100"
                        bodyClass="assets__info-block-item-inner"
                    >
                        <router-link :to="{ name: 'bond', params: { slug: asset.instrument.slug } }" class="assets__info-block-item-link">
                            Более подробная информация об активе
                        </router-link>
                    </b-card>
                </div>
            </div>
            <div
                v-if="asset.info && asset.instrument"
                class="assets__info-block row mb-4"
            >
                <BlockInfo
                    title="Количество ЦБ / лотов в портфеле"
                    :value="`${asset.info.quantity} / ${asset.info.quantity_lots}`"
                    classMain="assets__info-block-item"
                    classColor=""
                    helper="asset_quantity"
                />
                <BlockInfo
                    title="Инвестировано"
                    :value="asset.info.invested"
                    :valueLabel="asset.instrument.instrument_type.key === 'bond' && 'без НКД'"
                    :valueHelper="asset.instrument.instrument_type.key === 'bond' ? getHelper(asset.info.invested_info, 'invested_info', asset.instrument.currency) : null"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.instrument.instrument_type.key === 'bond' ? asset.info.invested_info.invested_aci : null"
                    :subvalueLabel="asset.instrument.instrument_type.key === 'bond' && 'с тек. НКД'"
                    subvalueToPrice
                    :subvalueUseCurrency="asset.instrument.currency"
                    classMain="assets__info-block-item"
                    classColor=""
                    helper="asset_invested"
                />
                <BlockInfo
                    title="Текущая стоимость ЦБ"
                    :value="asset.info.actual_sum"
                    :valueLabel="asset.instrument.instrument_type.key === 'bond' && 'без НКД'"
                    :valueHelper="asset.instrument.instrument_type.key === 'bond' ? getHelper(asset.info.actual_sum_info, 'actual_sum_info', asset.instrument.currency) : null"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.instrument.instrument_type.key === 'bond' ? asset.info.actual_sum_aci : null"
                    :subvalueLabel="asset.instrument.instrument_type.key === 'bond' && 'с тек. НКД'"
                    subvalueToPrice
                    :subvalueUseCurrency="asset.instrument.currency"
                    classMain="assets__info-block-item"
                    classColor=""
                    helper="asset_price_actual"
                />
                <BlockInfo
                    title="Общий доход за всю историю"
                    :value="asset.info.total_income_aci"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.info.total_income_aci_percent"
                    subvalueToPercent
                    classMain="assets__info-block-item"
                    :classColor="`text-${asset.info.total_income_aci >= 0 ? 'success' : 'danger'}`"
                    helper="asset_total_income"
                />
                <BlockInfo
                    :title="`Доход 1. ${asset.instrument.instrument_type.key === 'bond' ? 'Купоны' : 'Дивиденды'} за всю историю`"
                    :value="asset.info.security_payments_sum"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.info.security_payments_percent"
                    subvalueToPercent
                    subvalueUseSign
                    classMain="assets__info-block-item"
                    :classColor="`text-${asset.info.security_payments_sum >= 0 ? 'success' : 'danger'}`"
                    helper="asset_security_payments_sum"
                />
                <!--BlockInfo
                    :title="`Доход 1. ${asset.instrument.instrument_type.key === 'bond' ? 'Купоны' : 'Дивиденды'} по текущим ЦБ`"
                    :value="asset.info.actual_security_payments_sum"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.info.actual_security_payments_percent"
                    subvalueToPercent
                    classMain="assets__info-block-item"
                    classColor=""
                    helper="asset_actual_security_payments_sum"
                /-->
                <BlockInfo
                    title="Доход 2. Прибыль/убыток (П/У) по текущим ЦБ"
                    :value="asset.info.actual_income"
                    :valueLabel="asset.instrument.instrument_type.key === 'bond' && 'без НКД'"
                    :valueSublabel="$helpers.toPrice(asset.info.actual_income_percent, { sign: '%', pointer: ',' })"
                    :valueHelper="asset.instrument.instrument_type.key === 'bond' ? getHelper(asset.info.actual_income_info, 'actual_income_info', asset.instrument.currency) : null"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.instrument.instrument_type.key === 'bond' ? asset.info.actual_income_aci : null"
                    :subvalueLabel="asset.instrument.instrument_type.key === 'bond' && 'с тек. НКД'"
                    :subvalueSublabel="$helpers.toPrice(asset.info.actual_income_aci_percent, { sign: '%', pointer: ',' })"
                    subvalueToPrice
                    :subvalueUseCurrency="asset.instrument.currency"
                    classMain="assets__info-block-item"
                    :classColorValue="`text-${asset.info.actual_income >= 0 ? 'success' : 'danger'}`"
                    :classColorSubvalue="`text-${asset.info.actual_income_aci >= 0 ? 'success' : 'danger'}`"
                    helper="asset_actual_income"
                />
                <BlockInfo
                    title="Доход 2. Прибыль/убыток (П/У) по проданным ЦБ"
                    :value="asset.info.sold_income"
                    :valueLabel="asset.instrument.instrument_type.key === 'bond' && 'без НКД'"
                    :valueSublabel="$helpers.toPrice(asset.info.sold_income_percent, { sign: '%', pointer: ',' })"
                    :valueHelper="asset.instrument.instrument_type.key === 'bond' ? getHelper(asset.info.sold_income_info, 'sold_income_info', asset.instrument.currency) : null"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.instrument.instrument_type.key === 'bond' ? asset.info.sold_income_aci : null"
                    :subvalueLabel="asset.instrument.instrument_type.key === 'bond' && 'с тек. НКД'"
                    :subvalueSublabel="$helpers.toPrice(asset.info.sold_income_aci_percent, { sign: '%', pointer: ',' })"
                    subvalueToPrice
                    :subvalueUseCurrency="asset.instrument.currency"
                    subvalueUseSign
                    classMain="assets__info-block-item"
                    :classColorValue="`text-${asset.info.sold_income >= 0 ? 'success' : 'danger'}`"
                    :classColorSubvalue="`text-${asset.info.sold_income_aci >= 0 ? 'success' : 'danger'}`"
                    helper="asset_sold_incom"
                />
                <BlockInfo
                    title="Доход 2 за всю историю. Прибыль/убыток (П/У) за всю историю"
                    :value="asset.info.income"
                    :valueLabel="asset.instrument.instrument_type.key === 'bond' && 'без НКД'"
                    :valueSublabel="$helpers.toPrice(asset.info.income_percent, { sign: '%', pointer: ',' })"
                    :valueHelper="asset.instrument.instrument_type.key === 'bond' ? getHelper(asset.info.income_info, 'income_info', asset.instrument.currency) : null"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.instrument.instrument_type.key === 'bond' ? asset.info.income_aci : null"
                    :subvalueLabel="asset.instrument.instrument_type.key === 'bond' && 'с тек. НКД'"
                    :subvalueSublabel="$helpers.toPrice(asset.info.income_aci_percent, { sign: '%', pointer: ',' })"
                    subvalueToPrice
                    :subvalueUseCurrency="asset.instrument.currency"
                    subvalueUseSign
                    classMain="assets__info-block-item"
                    :classColorValue="`text-${asset.info.income >= 0 ? 'success' : 'danger'}`"
                    :classColorSubvalue="`text-${asset.info.income_aci >= 0 ? 'success' : 'danger'}`"
                    helper="asset_income"
                />
                <BlockInfo
                    v-if="asset.instrument.instrument_type.key !== 'share'"
                    title="Амортизация"
                    :value="asset.info.amortization_sum"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.info.amortization_percent"
                    subvalueToPercent
                    subvalueUseSign
                    classMain="assets__info-block-item"
                    :classColor="`text-${asset.info.amortization_sum >= 0 ? 'success' : 'danger'}`"
                    helper="asset_amortization"
                />
                <BlockInfo
                    v-if="asset.instrument.instrument_type.key === 'bond'"
                    title="Доход 2. План"
                    :value="asset.info.planed_close_income"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    :subvalue="asset.info.planed_close_income_percent"
                    subvalueToPercent
                    subvalueUseSign
                    classMain="assets__info-block-item"
                    :classColor="`text-${asset.info.planed_close_income >= 0 ? 'success' : 'danger'}`"
                    helper="asset_planed_close_income"
                />
                <BlockInfo
                    v-if="asset.instrument.instrument_type.key === 'bond'"
                    title="Сумма при погашении"
                    :value="asset.info.planed_repayment_sum"
                    valueToPrice
                    :valueUseCurrency="asset.instrument.currency"
                    classMain="assets__info-block-item"
                    :classColor="`text-${asset.info.planed_repayment_sum >= 0 ? 'success' : 'danger'}`"
                    helper="asset_planed_repayment_sum"
                />
                <BlockInfo
                    title="Среднегодовая прибыль по активу"
                    :value="(asset.info.days_long <= 30) ? 'Недостаточный срок для расчетов' : asset.info.average_annual_income"
                    :valueToPercent="(asset.info.days_long <= 30) ? false : true"
                    classMain="assets__info-block-item"
                    :classColor="`text-${asset.info.average_annual_income >= 0 ? 'success' : 'danger'}`"
                    helper="asset_average_annual_income"
                />
            </div>
        </div>
        <div class="assets__history">
            <h2 class="assets__history-title mb-4">История операций</h2>
            <b-card
                class="assets__history-table col-12 shadow mb-5"
                bodyClass="p-0"
            >
                <b-tabs
                    nav-class="px-3 mt-1"
                    content-class="mt-3 p-1"
                >
                    <b-tab
                        title="История сделок по ЦБ"
                        active
                        @click="changeTab('transactions')"
                    >
                        <BlockAssetsTransactions
                            :id="portfolioId"
                            :ticker="ticker"
                            :currency="asset.instrument.currency"
                            :instrumentType="asset.instrument.instrument_type.key"
                            :portfolioType="portfolioType"
                        />
                    </b-tab>
                    <b-tab
                        :title="`История ${asset.instrument.instrument_type.key === 'bond' ? 'купонов' : 'дивидендов'}`"
                        @click="changeTab('dividents')"
                    >
                        <BlockAssetsDividents
                            :id="portfolioId"
                            :ticker="ticker"
                            :portfolioType="portfolioType"
                        />
                    </b-tab>
                    <!--b-tab
                        v-if="asset.instrument.instrument_type.key === 'bond'"
                        title="График выплат купонов"
                        @click="changeTab('schedule')"
                    >
                        <BlockAssetsSchedule
                            :id="portfolioId"
                            :ticker="ticker"
                            :portfolioType="portfolioType"
                        />
                    </b-tab-->
                </b-tabs>
            </b-card>
            <template
                v-if="rules.chart || !rules.chart"
            >
                <h2 class="assets__history-title mb-4">
                    История цены ЦБ
                    <Helper
                        id="popover-chart"
                        helper="asset_history_chart"
                        classModifier="m--dark"
                        sup
                    />
                </h2>
                <b-card
                    class="shadow mb-5 py-3"
                    bodyClass="py-0"
                >
                    <template
                        v-if="!showLoaderCharts"
                    >
                        <highcharts
                            :id="`chart-asset-${ticker}`"
                            :constructorType="'stockChart'"
                            :options="chartOptions"
                        >
                        </highcharts>
                    </template>
                    <b-overlay
                        :show="showLoaderCharts"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-card>
            </template>
        </div>
    </template>
    <template v-else>
        <div class="app__loader">
            <b-spinner
                variant="success"
                class="me-3"
            />
            <span class="text-success">Загрузка данных ...</span>
        </div>
    </template>
</template>

<script>
    import { assetHistory } from "@/settings/charts";

    import { app } from "@/services";
    import BlockInfo from "@/components/block-info";
    import BlockAssetsTransactions from "@/components/block-assets-transactions";
    import BlockAssetsDividents from "@/components/block-assets-dividents";
    //import BlockAssetsSchedule from "@/components/block-assets-schedule";
    import Helper from "@/components/helper";
    export default {
        name: 'assets',
        components: {
            BlockInfo,
            BlockAssetsTransactions,
            BlockAssetsDividents,
            //BlockAssetsSchedule,
            Helper
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            ticker: {
                type: String,
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                asset: {},
                chartOptions: {},
                showTwelveMonthIncome: false,
                showLoaderSending: false,
                showLoaderCharts: false,
            };
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
        },
        created() {
            this.getPortfolioAsset();
        },
        mounted() {
            this.$breadcrumbs.value.at(-2).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
        },
        methods: {
            getHelper(obj, key, currency) {
                let data = { key: key };
                if (key === 'invested_info') {
                    data.content =
                        `<div><strong>Инвестировано</strong></div>` +
                        `<hr>` +
                        `Инвестировано в текущие бумаги: ${this.$helpers.toPrice(obj.actual_invested, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `НКД по текущим бумагам: ${this.$helpers.toPrice(obj.actual_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Инвестировано в текущие бумаги с НКД: ${this.$helpers.toPrice(obj.actual_invested_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Инвестировано в проданные бумаги: ${this.$helpers.toPrice(obj.sold_invested, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `НКД по проданным бумагам: ${this.$helpers.toPrice(obj.sold_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Инвестировано в проданные бумаги с НКД: ${this.$helpers.toPrice(obj.sold_invested_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Итого: ${this.$helpers.toPrice(obj.invested, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Итого с НКД: ${this.$helpers.toPrice(obj.invested_aci, { sign: currency.symbol, pointer: ',' })}<br>`;
                } else if (key === 'actual_sum_info') {
                    data.content =
                        `<div><strong>Текущая стоимость ЦБ</strong></div>` +
                        `<hr>` +
                        `Текущая цена, 1 шт: ${this.$helpers.toPrice(obj.price, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Текущий НКД, 1 шт: ${this.$helpers.toPrice(obj.aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Текущая стоимость: ${this.$helpers.toPrice(obj.price_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Текущий НКД: ${this.$helpers.toPrice(obj.aci_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Текущая стоимость с НКД: ${this.$helpers.toPrice(obj.total_sum, { sign: currency.symbol, pointer: ',' })}`;
                } else if (key === 'actual_income_info') {
                    data.content =
                        `<div><strong>Доход 2. Прибыль/убыток (П/У) по текущим ЦБ</strong></div>` +
                        `<hr>` +
                        `Инвестировано в текущие бумаги: ${this.$helpers.toPrice(obj.invested, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `НКД по текущим бумагам: ${this.$helpers.toPrice(obj.invested_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Инвестировано в текущие бумаги с НКД: ${this.$helpers.toPrice(obj.invested_aci_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Текущая стоимость: ${this.$helpers.toPrice(obj.asset_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Текущий НКД: ${this.$helpers.toPrice(obj.aci_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Текущая стоимость с НКД: ${this.$helpers.toPrice(obj.asset_sum_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Амортизация: ${this.$helpers.toPrice(obj.amortization_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Доход 2. П/У: ${this.$helpers.toPrice(obj.income, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Доход 2. П/У c НКД: ${this.$helpers.toPrice(obj.income_aci, { sign: currency.symbol, pointer: ',' })}<br>`;
                } else if (key === 'sold_income_info') {
                    data.content =
                        `<div><strong>Доход 2. Прибыль/убыток (П/У) по проданным ЦБ</strong></div>` +
                        `<hr>` +
                        `Инвестировано в проданные бумаги: ${this.$helpers.toPrice(obj.invested, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `НКД по проданным бумагам: ${this.$helpers.toPrice(obj.invested_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Инвестировано в проданные бумаги с НКД: ${this.$helpers.toPrice(obj.invested_aci_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Стоимость продажи: ${this.$helpers.toPrice(obj.asset_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Сумма НКД: ${this.$helpers.toPrice(obj.aci_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Стоимость продажи с НКД: ${this.$helpers.toPrice(obj.asset_sum_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Амортизация: ${this.$helpers.toPrice(obj.amortization_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Доход 2. П/У: ${this.$helpers.toPrice(obj.income, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Доход 2. П/У c НКД: ${this.$helpers.toPrice(obj.income_aci, { sign: currency.symbol, pointer: ',' })}<br>`;
                } else if (key === 'income_info') {
                    data.content =
                        `<div><strong>Доход 2 за всю историю. Прибыль/убыток (П/У) за всю историю</strong></div>` +
                        `<hr>` +
                        `Общая сумма. Инвестировано: ${this.$helpers.toPrice(obj.invested, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Общая сумма НКД: ${this.$helpers.toPrice(obj.invested_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Общая сумма. Инвестировано с НКД: ${this.$helpers.toPrice(obj.invested_aci_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Расчетная оценка стоимости всех ЦБ: ${this.$helpers.toPrice(obj.asset_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Расчетная оценка всех ЦБ: ${this.$helpers.toPrice(obj.aci_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Расчетная оценка стоимости всех ЦБ c НКД: ${this.$helpers.toPrice(obj.asset_sum_aci, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Амортизация: ${this.$helpers.toPrice(obj.amortization_sum, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `<hr>` +
                        `Общий доход 2. П/У: ${this.$helpers.toPrice(obj.income, { sign: currency.symbol, pointer: ',' })}<br>` +
                        `Общий доход 2. П/У c НКД: ${this.$helpers.toPrice(obj.income_aci, { sign: currency.symbol, pointer: ',' })}<br>`;
                }
                return data;
            },
            getPortfolioAsset() {
                this.showLoaderSending = true;
                app.getPortfolioAsset(this.portfolioId, this.ticker, this.portfolioType).then(res => {
                    if (!res.error) {
                        this.asset = res;
                        this.$breadcrumbs.value.at(-1).label = `История сделок ${this.asset.instrument.short_name || this.asset.instrument.name}`;

                        console.log(res);
                        this.getPortfolioAssetHistory();
                        this.showLoaderSending = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getPortfolioAssetHistory() {
                this.showLoaderCharts = true;
                console.log('getPortfolioAssetHistory');
                app.getPortfolioAssetHistory(this.portfolioId, this.ticker, this.portfolioType).then(res => {
                    if (!res.error) {
                        this.asset.history = res;
                        this.chartOptions = assetHistory;
                        this.chartOptions.tooltip.valueSuffix = this.asset.instrument.currency.symbol || {};
                        this.chartOptions.series[0].name = this.chartOptions.series[0].nameTemplate + this.asset.instrument.ticker;
                        this.chartOptions.series[0].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                item.price,
                            ];
                        });
                        this.getPortfolioAssetChart();
                        /*
                        this.chartOptions.xAxis.categories = this.chartOptions.series[0].data.map(item => { return new Date(item.time).toLocaleDateString('ru') });
                        */
                        this.showLoaderCharts = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderCharts = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getPortfolioAssetChart() {
                //this.showLoaderSending = true;
                app.getPortfolioAssetChart(this.portfolioId, this.ticker, this.portfolioType).then(res => {
                    if (!res.error) {
                        console.log(res);
                        //this.showLoaderSending = false;
                        this.chartOptions.series[1].data = [];
                        this.chartOptions.series[2].data = [];
                        Object.keys(res).forEach(key => {
                            console.log(key);
                            let items_buy = res[key].filter(item => {
                                return item.action === 'buy';
                            });
                            if (items_buy.length) {
                                this.chartOptions.series[1].data.push({
                                    x: new Date(key).getTime(),
                                    items: items_buy,
                                    price: items_buy[0].price
                                    //description: 'Покупка: ' + item_buy.quantity + 'шт.'
                                });
                            }
                            let items_sale = res[key].filter(item => {
                                return item.action === 'sale';
                            });
                            if (items_sale.length) {
                                this.chartOptions.series[2].data.push({
                                    x: new Date(key).getTime(),
                                    items: items_sale,
                                    price: items_sale[0].price
                                    //description: 'Покупка: ' + item_buy.quantity + 'шт.'
                                });
                            }
                        });
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    //this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            changeTab(tab) {
                console.log('changeTab', tab);
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
