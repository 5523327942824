<template>
    <b-form
        @submit="onSubmitReminder"
        class="account__edit-form"
    >
        <b-form-group
            id="input-group-reminder_date"
            label="Когда напомнить?*"
            class="col-12"
        >
            <b-input-group
                class="m--with-icon"
            >
                <template #append>
                    <b-icon-calendar2-check class="icon"></b-icon-calendar2-check>
                </template>
                <VueDatePicker
                    v-model="formData.reminder_date"
                    format="dd.MM.yyyy HH:mm"
                    locale="ru"
                    :auto-apply="true"
                    select-text="Выбрать"
                    cancel-text="Отменить"
                    :year-range="[2000, 2050]"
                    :min-date="minDate"
                    :prevent-min-max-navigation="true"
                    text-input
                    :month-change-on-scroll="true"
                    :clearable="false"
                    :hide-input-icon="true"
                    :disabled="showLoaderSending"
                >
                    <template #dp-input="{ value, onInput, onEnter, onTab }">
                        <input
                            v-maska
                            data-maska="##.##.#### ##:##"
                            data-maska-eager
                            class="form-control shadow-sm m--no-pill"
                            type="text"
                            :value="value"
                            :disabled="showLoaderSending"
                            @keyup="onInput"
                            @keydown.enter.prevent="onEnter"
                            @keydown.tab="onTab"
                        />
                    </template>
                </VueDatePicker>
            </b-input-group>
        </b-form-group>
        <b-form-group
            id="input-group-content"
            label="Сообщение*"
            class="col-12"
        >
            <b-form-textarea
                id="input-content"
                v-model="formData.content"
                placeholder=""
                size="sm"
                class="shadow-sm m--no-pill"
                :disabled="showLoaderSending"
            ></b-form-textarea>
        </b-form-group>
        <b-form-group
            v-if="!reminderId"
            id="input-group-recipient"
            label="Кому напомнить?"
            class="col-12"
        >
            <Multiselect
                id="input-recipient"
                v-model="formData.recipient"
                :options="recipientList[portfolioType]"
                mode="single"
                :close-on-select="true"
                :close-on-deselect="true"
                :can-deselect="false"
                :can-clear="false"
                :searchable="false"
                :create-option="false"
                class="shadow-sm m--no-pill"
                placeholder=""
                noOptionsText="Список пуст"
                noResultsText="По Вашему запросу ничего не найдено"
                requered
                :disabled="showLoaderSending"
                @change="onChangeRecipient"
                @clear="onClearRecipient"
            />
        </b-form-group>
        <b-form-group
            id="input-group-portfolio"
            label="Брокерский счет"
            class="col-12"
        >
            <b-form-input
                v-if="reminderId"
                v-model="formData.portfolio_name"
                size="sm"
                class="shadow-sm m--no-pill"
                disabled
            />
            <Multiselect
                v-else
                id="input-portfolio"
                ref="portfolio"
                v-model="formData.portfolio"
                :options="async function() {
                    return await getPortfolioList();
                }"
                mode="single"
                :close-on-select="true"
                :searchable="true"
                :create-option="false"
                class="shadow-sm m--no-pill"
                placeholder=""
                noOptionsText="Список пуст"
                noResultsText="По Вашему запросу ничего не найдено"
                :disabled="showLoaderSending || reminderId ? true : false"
                @change="onChangePortfolio"
                @clear="onClearPortfolio"
            />
        </b-form-group>
        <b-form-group
            id="input-group-instrument"
            label="Инструмент"
            class="col-12"
        >
            <b-form-input
                v-if="reminderId"
                v-model="formData.instrument_name"
                size="sm"
                class="shadow-sm m--no-pill"
                disabled
            />
            <Multiselect
                v-else
                id="input-instrument"
                ref="instrument"
                v-model="formData.instrument"
                :options="async function() {
                    return await getPortfolioInstrumentList();
                }"
                mode="single"
                :close-on-select="true"
                :searchable="true"
                :create-option="false"
                class="shadow-sm m--no-pill"
                placeholder=""
                noOptionsText="Список пуст"
                noResultsText="По Вашему запросу ничего не найдено"
                :disabled="showLoaderSending"
            />
        </b-form-group>
        <div class="col-12">
            <b-alert
                variant="success"
                :show="addSuccess"
            >
                {{ reminderId ? 'Напоминание успешно изменено' : 'Напоминание успешно добавлено' }}
            </b-alert>
            <b-alert
                variant="danger"
                :show="addError"
            >
                {{ addError }}
            </b-alert>
        </div>
        <div
            class="col-12 pt-3 row"
        >
            <div class="col-auto">
                <b-button
                    variant="outline-success"
                    class="account__edit-form-button mb-3"
                    type="submit"
                    pill
                    :disabled="showLoaderSending"
                >
                    Сохранить
                </b-button>
            </div>
        </div>
        <b-overlay
            :show="showLoaderSending"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-form>
</template>

<script>
    import { app } from "@/services";
    import Multiselect from '@vueform/multiselect'
    export default {
        components: {
            Multiselect,
        },
        emits: ['hideModal'],
        props: {
            reminderId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'all'; }
            },
        },
        data() {
            return {
                //minDate: this.$helpers.formatDate((new Date()).setDate((new Date()).getTime() + 1), 'YYYY-MM-DD HH:mm'),
                minDate: this.$helpers.formatDate(new Date(), 'YYYY-MM-DD HH:mm'),
                formData: {
                    reminderId: this.reminderId,
                    reminder_date: this.$helpers.formatDate((new Date()).setDate((new Date()).getDate() + 1), 'YYYY-MM-DD HH:mm'),
                    recipient: 'creator',
                    portfolio: this.portfolioId
                },
                recipientList: {
                    'all': [
                        { label: 'Себе и владельцу брокерского счета', value: 'both' },
                        { label: 'Только владельцу брокерского счета', value: 'trust' },
                        { label: 'Только себе', value: 'creator' },
                    ],
                    'account': [
                        { label: 'Себе и владельцу брокерского счета', value: 'both', disabled: true },
                        { label: 'Только владельцу брокерского счета', value: 'trust', disabled: true },
                        { label: 'Только себе', value: 'creator' },
                    ],
                    'trust': [
                        { label: 'Себе и владельцу брокерского счета', value: 'both' },
                        { label: 'Только владельцу брокерского счета', value: 'trust' },
                        { label: 'Только себе', value: 'creator' },
                    ],
                },
                showLoaderSending: false,
                addSuccess: false,
                addError: null,
                instrumentList: [],
                portfolioList: [],
                refreshData: false,
            };
        },
        mounted() {
            this.getReminder();
        },
        methods: {
            getReminder() {
                if (this.reminderId) {
                    let params = {
                        reminderId: this.reminderId,
                    }
                    app.getReminder(params).then(res => {
                        if (!res.error) {
                            console.log(res);
                            //res.date = this.$helpers.formatDate(new Date(res.date), 'YYYY-MM-DD');
                            this.formData = {
                                reminderId: this.reminderId,
                                reminder_date: res.reminder_date,
                                content: res.content,
                                recipient: res.creator.id == res.user.id ? 'creator' : 'trust',
                                portfolio_name: res.portfolio?.trust_name || res.portfolio?.name,
                                instrument_name: res.instrument?.name
                            };
                            if (this.formData.portfolio) {
                                this.$refs.instrument.refreshOptions();
                            }
                            //this.formData.reminderId = res.reminder;
                            //delete res.portfolio;
                            //this.getInstrument(this.formData.instrument);
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                }
            },
            onChangeRecipient() {
                this.$nextTick(() => {
                    this.$refs.portfolio.clear();
                    this.$refs.portfolio.refreshOptions();
                });
            },
            onClearRecipient() {
                this.$nextTick(() => {
                    this.$refs.portfolio.clear();
                    this.$refs.portfolio.refreshOptions();
                });
            },
            async getPortfolioList() {
                let params = {};
                if (this.formData.recipient === 'creator') {
                    return app.getPortfolioMenuList(params).then(res => {
                        console.log(res);
                        this.portfolioList = res;
                        return res.map((item) => {
                            return {
                                label: `${item.trust_name || item.name}`,
                                value: item.id,
                            }
                        });
                    }).catch(err => {
                        console.error(err);
                        return [];
                    });
                }
                if (this.formData.recipient === 'trust' || this.formData.recipient === 'both') {
                    return app.getTrustList(params).then(res => {
                        console.log(res);
                        this.portfolioList = res.detail;
                        return res.detail.map((item) => {
                            return {
                                label: `${item.trust_name || item.portfolio_name}`,
                                value: item.portfolio_id,
                            }
                        });
                    }).catch(err => {
                        console.error(err);
                        return [];
                    });
                }
                /*
                if (this.formData.recipient === 'both') {
                    let listCreator = [];
                    let listTrust = [];
                    await app.getPortfolioMenuList(params).then(res => {
                        console.log(res);
                        listCreator = res.map((item) => {
                            return {
                                label: `Мой - ${item.trust_name || item.name}`,
                                value: item.id,
                            }
                        });
                    }).catch(err => {
                        console.error(err);
                    });
                    await app.getTrustList(params).then(res => {
                        console.log(res);
                        listTrust = res.detail.map((item) => {
                            return {
                                label: `Доверенный - ${item.trust_name || item.name}`,
                                value: item.portfolio_id,
                            }
                        });
                    }).catch(err => {
                        console.error(err);
                    });
                    return [...listCreator, ...listTrust];
                }
                */
            },
            onChangePortfolio() {
                this.$nextTick(() => {
                    this.$refs.instrument.clear();
                    this.$refs.instrument.refreshOptions();
                });
            },
            onClearPortfolio() {
                this.$nextTick(() => {
                    this.$refs.instrument.clear();
                    this.$refs.instrument.refreshOptions();
                });
            },
            getPortfolioInstrumentList() {
                if (this.formData.portfolio) {
                    let params = {
                        id: this.formData.portfolio
                    };
                    if (this.formData.recipient === 'creator') {
                        return app.getPortfolioInstrumentList(params).then(res => {
                            console.log(res);
                            this.instrumentList = res;
                            return res.map((instrument) => {
                                return {
                                    label: `${instrument.name}`,
                                    value: instrument.id,
                                }
                            });
                        }).catch(err => {
                            console.error(err);
                            return [];
                        });
                    }
                    if (this.formData.recipient === 'trust' || this.formData.recipient === 'both') {
                        return app.getTrustInstrumentList(params).then(res => {
                            console.log(res);
                            this.instrumentList = res;
                            return res.map((instrument) => {
                                return {
                                    label: `${instrument.name}`,
                                    value: instrument.id,
                                }
                            });
                        }).catch(err => {
                            console.error(err);
                            return [];
                        });
                    }
                } else {
                    return [];
                }
            },
            onSubmitReminder() {
                console.log('onSubmitReminder');
                this.addError = null;
                let params = Object.assign({}, this.formData);
                if ((new Date(params.reminder_date).getTime() - new Date().getTime()) < 15 * 60 * 1000) {
                    this.addError = 'Дата и время напоминания не может быть меньше 15 мин. от текущей даты и времени';
                    return;
                }
                params.reminder_date = this.$helpers.formatDate(new Date(params.reminder_date), 'YYYY-MM-DD HH:mm');
                this.showLoaderSending = true;
                this.addSuccess = false;

                if (this.reminderId) {
                    app.updateReminder(params).then(() => {
                        this.refreshData = true;
                        this.addSuccess = true;
                        this.$emit('hideModal', this.refreshData);
                        this.formData = {};
                        this.showLoaderSending = false;
                    }).catch(err => {
                        //this.formData.portfolioId = this.portfolioId;
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                } else {
                    app.addReminder(params).then(() => {
                        this.refreshData = true;
                        this.addSuccess = true;
                        this.$emit('hideModal', this.refreshData);
                        this.formData = {};
                        this.showLoaderSending = false;
                    }).catch(err => {
                        //this.formData.portfolioId = this.portfolioId;
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                }
            },
        }
    };
</script>
