<template>
    <b-modal
        id="modal-page"
        v-model="showModal"
        size="xl"
        classes="modal__container"
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        :title="page.title || '...'"
        centered
        hide-footer
        scrollable
        @hidden="hideModal"
    >
        <div class="modal__content">
            <template v-if="showLoaderSending">
                <b-spinner
                    variant="success"
                    class="me-3"
                />
                <span class="h4 text-success">Загрузка данных ...</span>
            </template>
            <template v-else>
                <div
                    class="content"
                    v-html="page.content || `Создайте страницу с полем slug: ${slug}`"
                />
            </template>
        </div>
    </b-modal>
</template>

<script>
    import { app } from "@/services";
    export default {
        components: {
        },
        //computed: {
        //    showModal() {
        //        return this.show;
        //    },
        //},
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                    if (this.show) {
                        this.getPageContent(this.slug);
                    }
                }
            },
        },
        props: {
            slug: {
                type: String,
                default() { return null; }
            },
            show: {
                type: Boolean,
                default() { return false; }
            }
        },
        data() {
            return {
                page: {},
                requested: false,
                showModal: this.show,
                showLoaderModal: false,
                showLoaderSending: true
            };
        },
        created() {
        },
        mounted() {
        },
        methods: {
            getPageContent(slug) {
                this.requested = false;
                if (!this.requested) {
                    this.showLoaderSending = true;
                    app.getPage(slug).then(res => {
                        //console.log(res);
                        this.page = res;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.page = {};
                        console.error(err);
                        this.showLoaderSending = false;
                    });
                    this.requested = true;
                }
            },
            hideModal() {
                this.$emit('hideModal');
            },
        }
    };
</script>
