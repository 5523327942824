<template>
    <div class="app__block page m--full-width d-flex h-100 flex-column p-4">
        <BlockUserCurrencies/>
        <template v-if="showLoaderSending">
            <div class="app__loader">
                <b-spinner
                    variant="success"
                    class="me-3"
                />
                <span class="h4 text-success">Загрузка данных ...</span>
            </div>
        </template>
        <template v-else>
            <div class="app__block-title">
                <h1 class="mb-3">{{ page.title }}</h1>
            </div>
            <div
                class="content"
                v-html="page.content || `Создайте страницу с полем slug: ${slug}`"
            />
        </template>
    </div>
</template>

<script>
    import { app } from "@/services";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    export default {
        name: 'page',
        components: {
            BlockUserCurrencies
        },
        props: {
            slug: {
                type: String,
                default() { return null; }
            },
        },
        data() {
            return {
                page: {},
                showLoaderSending: false
            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
            this.getPageContent(this.slug);
        },
        methods: {
            getPageContent(slug) {
                this.requested = false;
                if (!this.requested) {
                    this.showLoaderSending = true;
                    app.getPage(slug).then(res => {
                        //console.log(res);
                        this.page = res;
                        this.$breadcrumbs.value.at(-1).label = this.page.title;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.page = {};
                        console.error(err);
                        this.showLoaderSending = false;
                    });
                    this.requested = true;
                }
            },
        }
    };
</script>
