<template>
    <b-modal
        id="modal-filter-add"
        v-model="showModal"
        size="md"
        classes="modal__container"
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Новый фильтр"
        centered
        hide-footer
        no-stacking
        @hidden="hideModal()"
    >
        <div class="modal__content">
            <b-form
                @submit="onSubmitFilter"
                class="bond-screener__filter-form row"
            >
                <b-form-group
                    id="input-filter-name"
                    label="Название фильтра"
                    class="col-12"
                >
                    <b-form-input
                        id="input-name"
                        v-model="formData.name"
                        type="text"
                        placeholder="Новый фильтр"
                        size="sm"
                        required
                        class="shadow-sm m--no-pill"
                    ></b-form-input>
                </b-form-group>
                <div class="col-12">
                    <b-alert
                        variant="danger"
                        :show="addError"
                    >
                        {{ addError }}
                    </b-alert>
                </div>
                <div
                    class="col-12 pt-3"
                >
                    <b-button
                        variant="outline-success"
                        class="bond-screener__group-form-button mb-3 me-auto"
                        type="submit"
                        pill
                        :disabled="showLoaderSending"
                    >
                        Сохранить фильтр
                    </b-button>
                </div>
                <b-overlay
                    :show="showLoaderSending"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                />
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import { app } from '@/services';
    //import FormDealAdd from "@/components/form-deal-add";
    //import BlockTariffLimited from "@/components/block-tariff-limited";
    //import Multiselect from '@vueform/multiselect';
    export default {
        components: {
            //FormDealAdd,
            //BlockTariffLimited
            //Multiselect
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        props: {
            filterInterface: {
                type: String,
                default() { return 'bondScreener'; }
            },
            filterData: {
                type: Object,
                default() { return {}; }
            },
            show: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                formData: {},
                showModal: this.show,
                showLoaderSending: false,
                addError: null,
                refreshData: false,
            };
        },
        created() {
        },
        mounted() {
        },
        methods: {
            onSubmitFilter() {
                this.showLoaderSending = true;
                this.addSuccess = false;
                this.addError = null;
                let settings = this.userSettings;
                if (!settings.filters) settings.filters = {};
                if (!settings.filters[this.filterInterface]) settings.filters[this.filterInterface] = {};
                let filterKey = Math.floor(Date.now() / 1000);
                settings.filters[this.filterInterface][filterKey] = {
                    name: this.formData.name,
                    filters: this.filterData
                };
                app.updateUser({ user_settings: settings }).then(res => {
                    console.log(res);
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                    this.showLoaderSending = false;
                    this.hideModal(filterKey);
                }).catch(err => {
                    console.error(err);
                    this.showLoaderSending = false;
                });
                /*
                if (this.watchlistId) {
                    params.id = this.watchlistId;
                    app.updateWatchList(params).then(() => {
                        this.dismissCountDown = 3;
                        this.refreshData = true;
                        this.addSuccess = true;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                } else {
                    app.addWatchList(params).then(() => {
                        this.dismissCountDown = 3;
                        this.refreshData = true;
                        this.addSuccess = true;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                }
                */
            },
            hideModal(filterKey) {
                this.addSuccess = false;
                this.addError = null;
                this.$emit('hideModal', filterKey);
            },
        }
    };
</script>
