<template>
    <div class="account__settings-import account__edit mb-3">
        <div class="account__settings-import-info row mb-4">
            <div class="col-4">
                <b-card bodyClass="account__settings-import-broker">
                    <div class="account__settings-import-broker-logo">
                        <img
                            :src="portfolio.broker?.logo"
                            alt=""
                        >
                    </div>
                    <div class="">
                        Источник сделок
                        <div class="account__settings-import-broker-name">
                            {{ portfolio.broker?.name }}
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-8">
                <template v-if="portfolio.broker?.key === 'tinkoff'">
                    <a
                        href="#"
                        class="account__settings-import-info-help"
                        @click.prevent="showPage('account_instruction_tinkoff')"
                    >
                        Инструкция по получению токена у брокера Тинькофф
                    </a>
                    <div></div>
                </template>
                <template v-if="portfolio.broker?.key === 'finam'">
                    <a
                        href="#"
                        class="account__settings-import-info-help"
                        @click.prevent="showPage('account_instruction_file')"
                    >
                         Инструкция “Как получить отчет брокера?”
                    </a>
                    <div>Перетащите сюда отчет брокера в формате XML или кликните по значку XML снизу.</div>
                </template>
            </div>
        </div>
        <template v-if="portfolio.broker?.api_upload">
            <div class="account__edit-form m--100 mb-4 row">
                <b-form-group
                    :label="`Токен ${portfolio.broker_name} API`"
                    class="account__settings-import-token col"
                >
                    <b-form-input
                        v-model="formData.token"
                        :type="formData.broker_token ? 'password' : 'text'"
                        :readonly="formData.broker_token"
                        placeholder="Ваш токен"
                        autocomplete="off"
                        size="sm"
                        class="shadow-sm m--no-pill"
                        :disabled="showLoaderSending"
                        :state="formErrors && formErrors.token ? false : null"
                    ></b-form-input>
                </b-form-group>
                <div
                    v-if="formData.broker_token"
                    class="col-auto mt-auto mb-3"
                >
                    <b-button
                        variant="outline-primary"
                        class="account__edit-form-deletet"
                        pill
                        @click.stop="updateAccountTokenDeals"
                    >
                        Обновить данные <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
                    </b-button>
                </div>
                <div
                    v-if="formData.broker_token"
                    class="col-12 mb-3 pt-0 pb-2"
                >
                    <span>Последняя синхронизация: {{ $helpers.formatDate(new Date(portfolio.last_parsed), 'DD.MM.YY HH:mm') }}</span>
                </div>
                <div
                    v-else
                    class="col-auto mt-auto mb-3"
                >
                    <b-button
                        class="account__edit-form-save"
                        variant="outline-success"
                        pill
                        :disabled="showLoaderSending"
                        @click.stop="saveAccountToken"
                    >
                        Сохранить
                        <b-overlay
                            :show="showLoaderSending"
                            no-wrap
                            spinner-variant="success"
                            spinner-small
                        />
                    </b-button>
                </div>
                <div v-if="formData.broker_token">
                    <a href="#" class="account__edit-form-deletet text-danger" @click.stop="deleteAccountToken"><strong>Удалить токен</strong></a> (При удалении токена Тинькофф API  из системы "Капитал" данные о прошлых сделках сохраняются)
                </div>
                <div
                    v-if="formErrors && formErrors.detail"
                    class="col-12 mb-3"
                >
                    <b-alert
                        variant="danger"
                        show
                    >
                        <div class="content">
                            {{ formErrors.detail }}
                        </div>
                    </b-alert>
                </div>
                <b-overlay
                    :show="showLoaderSending"
                    no-wrap
                    spinner-variant="success"
                />
            </div>
        </template>
        <template v-if="portfolio.broker?.file_upload">
            <div
                v-if="!importResultStatus"
                class="account__edit-form row"
            >
                <b-form
                    @submit="onSubmitFile"
                    class="account__edit-import-file row"
                    @dragover.prevent
                    @drop.prevent
                    @dragenter.prevent
                    @dragleave.prevent
                >
                    <input
                        id="input-file"
                        type="file"
                        accept=".xml"
                        class="d-none"
                        @change="uploadFileComplete"
                    />
                    <div
                        v-if="!formData.file"
                        class="col-12 mb-4"
                        @drop.prevent="dragFile"
                    >
                        <b-card
                            class="account__edit-import-file-button shadow py-4"
                            @click="onClickUploadFile('input-file')"
                        >
                            <b-icon-filetype-xml />
                        </b-card>
                    </div>

                    <div
                        v-if="formData.file"
                        class="col-12 mb-4"
                    >
                        <b-card
                            class="shadow"
                        >
                            <div
                                class="mb-3"
                            >
                                <strong>Файл: {{ formData.file.name }}</strong>
                            </div>
                            <div
                                class=""
                            >
                                <b-button
                                    variant="outline-success"
                                    class="account__edit-form-button me-3"
                                    type="submit"
                                    pill
                                    :disabled="showLoaderSending"
                                >
                                    Обработать данные
                                </b-button>
                                <b-button
                                    variant="outline-success"
                                    class="account__edit-form-button me-3"
                                    pill
                                    @click.prevent="onClearFile()"
                                >
                                    Загрузить другой отчет
                                </b-button>
                            </div>
                        </b-card>
                    </div>
                </b-form>
            </div>
            <template v-if="importResultStatus">
                <div class="account__edit-import-status">
                    <b-card class="shadow px-3 py-4 mb-4">
                        <b-overlay
                            :show="showLoaderSending"
                            no-wrap
                            spinner-variant="success"
                        />
                        <template v-if="importResultStatus === 'ok'">
                            <div class="account__edit-import-status-title mb-4">
                                Импорт завершен
                            </div>
                            <div class="account__edit-import-status-items mb-4">
                                <div class="account__edit-import-status-item">
                                    Активов: <span>{{ importResultData.instrument }}</span>
                                </div>
                                <div class="account__edit-import-status-item">
                                    Сделок: <span>{{ importResultData.deal }}</span>
                                </div>
                                <div class="account__edit-import-status-item">
                                    Комиссий: <span>{{ importResultData.tax_commission }}</span>
                                </div>
                                <div class="account__edit-import-status-item">
                                    Начисления: <span>{{ importResultData.charge }}</span>
                                </div>
                                <div class="account__edit-import-status-item">
                                    Прочее: <span>{{ importResultData.other }}</span>
                                </div>
                                <template v-if="importResultData.errors">
                                    <div class="account__edit-import-status-item text-danger">
                                        Ошибок: {{ importResultData.errors }}
                                        <a
                                            v-if="importResultData.errors_info && importResultData.errors_info.length"
                                            href="#"
                                            class="small text-danger text-decoration-underline"
                                            v-b-toggle.collapse-errors
                                        >
                                            подробнее
                                        </a>
                                    </div>
                                    <b-collapse
                                        v-if="importResultData.errors_info && importResultData.errors_info.length"
                                        id="collapse-errors"
                                    >
                                        <template
                                            v-for="(error, index) in importResultData.errors_info"
                                            :key="`error-info${index}`"
                                        >
                                            <div
                                                v-if="error.category === 'isin_not_found'"
                                                class="text-danger"
                                            >
                                                <p class="mb-1"><strong>{{ error.message }}</strong></p>
                                                <ol>
                                                    <li
                                                        v-for="(item, index) in error.data"
                                                        :key="`error-${error.category}-${index}`"
                                                        class="small"
                                                    >
                                                        {{ item.isin }}, {{ item.comment }}
                                                    </li>
                                                </ol>
                                            </div>
                                            <div
                                                v-else
                                                class="text-danger"
                                            >
                                                <p class="mb-1"><strong>{{ error.message }}</strong></p>
                                                <ol>
                                                    <li
                                                        v-for="(item, index) in error.data"
                                                        :key="`error-${error.category}-${index}`"
                                                        class="small"
                                                    >
                                                        {{ item.date }}, {{ item.comment }}
                                                    </li>
                                                </ol>
                                            </div>
                                        </template>
                                    </b-collapse>
                                </template>
                            </div>
                            <b-button
                                variant="outline-success"
                                class="me-3"
                                pill
                                @click.prevent="onClearFile()"
                            >
                                Загрузить еще один файл
                            </b-button>
                            <b-button
                                variant="outline-success"
                                class="me-3"
                                pill
                                @click.prevent="$router.push({ name: portfolioType, params: { id: portfolio.id } })"
                            >
                                Вернуться к портфелю
                            </b-button>
                        </template>
                        <template v-else-if="importResultStatus === 'error'">
                            <div class="account__edit-import-status-title mb-4 text-danger">
                                Импорт не удался
                            </div>
                            <div class="content">
                                <p>Ошибка!</p>
                                <p>Пожалуйста, убедитесь, что отчет выгружен правильно согласно инструкции и имеет полную форму документа.</p>
                                <p
                                    v-if="importResultStatusDetail"
                                    class="text-danger"
                                >
                                    {{ importResultStatusDetail }}
                                </p>
                            </div>
                            <b-button
                                variant="outline-success"
                                class="account__edit-form-button me-3"
                                pill
                                @click.prevent="onClearFile()"
                            >
                                Загрузить другой отчет
                            </b-button>
                        </template>
                    </b-card>
                </div>
            </template>
            <template v-if="portfolio.files?.length">
                <div class="h4">Загруженные отчеты</div>
                <b-card class="account__settings-import-files shadow">
                    <div class="account__settings-import-files-inner">
                        <div
                            v-for="(item, index) in portfolio.files"
                            :key="`file-${index}`"
                            class="account__settings-import-files-item"
                        >
                            <span>{{ $helpers.formatDate(new Date(item.date_publication), 'DD.MM.YYYY HH.mm') }}</span>
                            <a
                                :href="item.file"
                                target="_blank"
                            >
                                {{ item.file.split('/').at(-1) }}
                            </a>
                        </div>
                    </div>
                </b-card>
            </template>
        </template>
        <ModalPage
            :slug="ModalPageSlug"
            :show="showModalPage"
            @hideModal="hidePage"
        />
    </div>
</template>

<script>
    import { app } from "@/services";
    //import Helper from "@/components/helper";
    import ModalPage from "@/components/modals/modal-page";
    export default {
        name: 'accountSettingsTrust',
        emits: ['refreshPortfolio'],
        components: {
            //Helper,
            ModalPage
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            //broker() {
            //    return this.brokerList.filter(item => { return item.key === this.portfolio.broker_key; })[0];
            //}
        },
        data() {
            return {
                //brokerList: [],
                formData: {},
                formErrors: {},
                importResultStatus: null,
                importResultStatusDetail: '',
                importResultData: [],
                ModalPageSlug: null,
                showModalPage: false,
                showLoaderSending: false,
            }
        },
        created() {
        },
        mounted() {
            this.$breadcrumbs.value.at(-3).label = this.portfolio.name;
            this.formData = {
                id: this.portfolio.id,
            };
            if (this.portfolio.token) {
                this.formData.broker_token = true;
                this.formData.token = '#'.repeat(20);
            } else {
                this.formData.token = '';
            }

            //this.getBrokerList();
        },
        methods: {
            /*
            getBrokerList() {
                let params = {};
                this.showLoaderSending = true;
                app.getBrokerList(params).then(res => {
                    this.showLoaderSending = false;
                    if (!res.error) {
                        this.brokerList = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            */
            saveAccountToken() {
                if (this.formData.token) {
                    this.formErrors = {};
                    let params = {
                        portfolio: this.portfolio.id,
                        token: this.formData.token
                    }
                    this.showLoaderSending = true;
                    /*updateBrokerAuth*/
                    if (this.portfolio.token) {
                        app.updateBrokerAuth(params).then(res => {
                            console.log(res);
                            this.updateAccountTokenDeals();
                        // }).then(() => {
                            // Проверить необходимость получения профиля.
                            // this.refreshPortfolio();
                            // this.showLoaderSending = false;
                        }).catch(err => {
                            this.showLoaderSending = false;
                            if (err.response.status === 400) {
                                console.log(err.response.data);
                                //this.formErrors = err.response.data.error;
                                this.formErrors.token = 1;
                                this.formErrors.detail = err.response.data.detail;
                            } else {
                                //this.$store.dispatch('showError', err);
                                this.formErrors = {
                                    detail: err
                                }
                                console.error(err);
                            }
                        });
                    } else {
                        app.addBrokerAuth(params).then(res => {
                            console.log(res);
                            this.updateAccountTokenDeals();
                        // }).then(() => {
                            // Проверить необходимость получения профиля.
                            // this.refreshPortfolio();
                            // this.showLoaderSending = false;
                        }).catch(err => {
                            this.showLoaderSending = false;
                            if (err.response.status === 400) {
                                console.log(err.response.data);
                                //this.formErrors = err.response.data.error;
                                this.formErrors.token = 1;
                                this.formErrors.detail = err.response.data.detail;
                            } else {
                                //this.$store.dispatch('showError', err);
                                this.formErrors = {
                                    detail: err
                                }
                                console.error(err);
                            }
                        });
                    }
                } else {
                    this.formErrors.token = 1;
                }
            },
            deleteAccountToken() {
                this.formErrors = {};
                let params = {
                    portfolio: this.portfolio.id,
                }
                this.showLoaderSending = true;
                app.deleteBrokerAuth(params).then(res => {
                    console.log(res);
                    // Проверить необходимость получения профиля.
                    this.refreshPortfolio();
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response.data);
                        //this.formErrors = err.response.data.error;
                        this.formErrors.token = 1;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            updateAccountTokenDeals() {
                this.formErrors = {};
                let params = {
                    portfolio: this.portfolio.id,
                }
                this.showLoaderSending = true;
                app.updateBrokerAuthDeals(params).then(res => {
                    console.log(res);
                    // Проверить необходимость получения профиля.
                    this.refreshPortfolio();
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response.data);
                        //this.formErrors = err.response.data.error;
                        this.formErrors.token = 1;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            onClickUploadFile(id) {
                let input;
                if (id) { // for file edit
                    input = document.getElementById(id);
                } else {
                    input = document.getElementById(id);
                }
                let click = new MouseEvent('click');
                input.dispatchEvent(click);
            },
            uploadFileComplete(event) {
                this.importResultStatus = null;
                this.importResultStatusDetail = '';
                for (let file of event.target.files) {
                    file.id = event.target.id;

                    const formData = new FormData();
                    formData.append('file', file);
                    app.addPortfolioFile(this.formData.id, formData, this.portfolioType).then(res => {
                        console.log(res);
                        this.formData.file = res;
                    }).catch(err => {
                        console.error(err.detail);
                        this.importResultStatus = 'error';
                        this.importResultStatusDetail = err.detail;
                    })
                }
            },
            dragFile(event) {
                console.log('dragFile', event);
                for (let file of event.dataTransfer.files) {
                    file.id = event.target.id;

                    const formData = new FormData();
                    formData.append('file', file);
                    app.addPortfolioFile(this.formData.id, formData, this.portfolioType).then(res => {
                        console.log(res);
                        this.formData.file = res;
                    }).catch(err => {
                        console.error(err);
                    })
                }
            },
            onSubmitFile() {
                this.importResultStatus = 'import';
                this.importResultStatusDetail = '';
                let params = {
                    portfolio_id: this.formData.id,
                    file: this.formData.file.id,
                    status: 0
                };
                this.showLoaderSending = true;
                app.importBrokerData(params).then(res => {
                    this.showLoaderSending = false;
                    this.importResultStatus = 'ok';
                    this.importResultData = res.detail;
                    this.refreshPortfolio();
                    if (res.errors) {
                        //this.importResultData.errors = res.errors;
                        this.importResultData.errors_info = res.errors_info;
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.importResultStatus = 'error';
                    this.importResultStatusDetail = err.detail;
                    this.currentStep--;
                    delete this.formData.file;
                    console.error(err);
                });

            },
            onClearFile() {
                this.importResultStatus = null;
                this.importResultStatusDetail = '';
                this.importResultData = [];
                delete this.formData.file;
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            refreshPortfolio() {
                console.log('refreshPortfolio');
                this.$emit('refreshPortfolio', true);
            },
        }
    };
</script>
