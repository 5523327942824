<template>
    <b-dropdown
        :id="`action-${deal.id}`"
        class="float-end"
        dropleft
        variant="link-primary px-1 py-0 h2 mb-0"
        no-caret
    >
        <template #button-content>
            <template v-if="deal.is_user_created">
                <template v-if="deal.has_original">
                    <b-icon-file-earmark-binary class="text-warning" :id="`popover-status-${deal.id}`" />
                </template>
                <template v-else>
                    <b-icon-file-earmark-person  :id="`popover-status-${deal.id}`" />
                </template>
            </template>
            <template v-else>
                <b-icon-file-earmark-arrow-down class="text-success" :id="`popover-status-${deal.id}`" />
            </template>
            <b-popover
                custom-class="shadow"
                :target="`popover-status-${deal.id}`"
                triggers="hover"
            >
                <template v-if="deal.is_user_created">
                    <template v-if="deal.has_original">
                        Сделка загружена из отчета и отредактирована
                    </template>
                    <template v-else>
                        Сделка создана пользователем
                    </template>
                </template>
                <template v-else>
                    Сделка загружена из отчета
                </template>
            </b-popover>
            <b-icon-card-list class="ms-2" :id="`popover-action-${deal.id}`" />
            <b-popover
                custom-class="shadow"
                :target="`popover-action-${deal.id}`"
                triggers="hover"
            >
                Действия со сделкой
            </b-popover>
        </template>
        <b-dropdown-item-button
            variant=""
            @click.prevent="showEditDeal"
        >
            Редактировать
        </b-dropdown-item-button>
        <b-dropdown-item-button
            v-if="deal.has_original && deal.is_user_created"
            variant=""
            @click.prevent="showCancelDeal"
        >
            Отменить редактирование
        </b-dropdown-item-button>
        <b-dropdown-item-button
            variant="danger"
            @click.prevent="showDeleteDeal"
        >
            Удалить
        </b-dropdown-item-button>
    </b-dropdown>
    <ModalDealAdd
        :show="showModalEditDeal"
        :portfolioId="deal.portfolio"
        :dealId="deal.id"
        :portfolioType="portfolioType"
        @hideModal="hideEditDeal"
    />
    <ModalCashDealAdd
        :show="showModalEditCashDeal"
        :portfolioId="deal.portfolio"
        :dealId="deal.id"
        :portfolioType="portfolioType"
        @hideModal="hideEditDeal"
    />
    <ModalDealCancel
        :show="showModalCancelDeal"
        :deal="deal"
        :portfolioType="portfolioType"
        @hideModal="hideCancelDeal"
    />
    <ModalDealDelete
        :show="showModalDeleteDeal"
        :deal="deal"
        :portfolioType="portfolioType"
        @hideModal="hideDeleteDeal"
    />
</template>

<script>
    import ModalDealAdd from "@/components/modals/modal-deal-add";
    import ModalCashDealAdd from "@/components/modals/modal-cash-deal-add";
    import ModalDealCancel from "@/components/modals/modal-deal-cancel";
    import ModalDealDelete from "@/components/modals/modal-deal-delete";
    export default {
        emits: ['changeFilterTable', 'getPortfolioDealsList'],
        components: {
            ModalDealAdd,
            ModalCashDealAdd,
            ModalDealCancel,
            ModalDealDelete
        },
        props: {
            deal: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                showModalEditDeal: false,
                showModalEditCashDeal: false,
                showModalCancelDeal: false,
                showModalDeleteDeal: false,
            }
        },
        mounted() {
        },
        methods: {
            showEditDeal() {
                if (this.deal.operation_type_cash_operation) {
                    this.showModalEditCashDeal = true;
                } else {
                    this.showModalEditDeal = true;
                }
            },
            hideEditDeal(refreshData = false) {
                if (this.deal.operation_type_cash_operation) {
                    this.showModalEditCashDeal = false;
                } else {
                    this.showModalEditDeal = false;
                }
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getPortfolioDealsList');
                    })
                }
            },
            showCancelDeal() {
                this.showModalCancelDeal = true;
            },
            hideCancelDeal(refreshData = false) {
                this.showModalCancelDeal = false;
                if (refreshData) {
                    this.$emit('getPortfolioDealsList');
                }
            },
            showDeleteDeal() {
                this.showModalDeleteDeal = true;
            },
            hideDeleteDeal(refreshData = false) {
                this.showModalDeleteDeal = false;
                if (refreshData) {
                    this.$emit('getPortfolioDealsList');
                }
            },
        }
    };
</script>
