<template>
    <b-dropdown
        :id="`action-${formData.id}`"
        class="float-start"
        :container="`#app`"
        dropright
        variant="link-primary px-2"
        no-caret
        menuClass="ms-2"
    >
        <template #button-content>
            <span v-if="!hideIndex" class="table__data-row-index">{{ bond._index }}</span>
            <b-icon-bookmark-fill
                v-if="formData.lists?.length"
            />
            <b-icon-bookmark
                v-else
            />
        </template>
        <b-dropdown-group
            header="Добавить в список"
            class="block"
        >
            <b-dropdown-item-button
                v-for="list in lists"
                :key="`watchlist-${list.id}`"
                @click.prevent="updateWatchlistInstrument(list.id)"
            >
                <b-icon-bookmark-fill
                    v-if="formData.lists?.map(item => { return item.watch_list }).indexOf(list.id) !== - 1"
                />
                <b-icon-bookmark
                    v-else
                />
                {{ list.name }}
            </b-dropdown-item-button>
        </b-dropdown-group>
        <b-dropdown-divider />
        <b-dropdown-item-button
            @click.prevent="showAddWatchlist"
        >
            Создать новый список
        </b-dropdown-item-button>
        <b-overlay
            :show="showLoaderSending"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-dropdown>
    <ModalWatchlistAdd
        v-if="showModalAddWatchlist"
        :show="showModalAddWatchlist"
        :bond="bond"
        @hideModal="hideAddWatchlist"
    />
</template>

<script>
    import { app } from "@/services";
    import ModalWatchlistAdd from "@/components/modals/modal-watchlist-add";
    export default {
        emits: ['getWatchLists'],
        components: {
            ModalWatchlistAdd,
        },
        props: {
            bond: {
                type: Object,
                default() { return {}; }
            },
            lists: {
                type: Array,
                default() { return []; }
            },
            hideIndex: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                formData: [],
                showModalAddWatchlist: false,
                showLoaderSending: false
            }
        },
        mounted() {
            this.formData = this.bond;
        },
        methods: {
            updateWatchlistInstrument(watchlistId) {
                console.log('updateWatchlistInstrument', watchlistId);
                if (this.formData.lists.map(item => { return item.watch_list }).indexOf(watchlistId) !== - 1) {
                    console.log('Delete');
                    this.showLoaderSending = true;
                    let params = {
                        id: watchlistId,
                        instrument: this.formData.id
                    };
                    app.deleteWatchListInstrument(params).then(res => {
                        if (!res.error) {
                            console.log(res);
                            //this.formData.lists.push(res);
                            this.formData.lists = this.formData.lists.filter(item => { return item.watch_list !== res.watch_list });
                        }
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                        this.currencyList = [];
                    });
                } else {
                    console.log('Add');
                    this.showLoaderSending = true;
                    let params = {
                        id: watchlistId,
                        instrument: this.formData.id
                    };
                    app.addWatchListInstrument(params).then(res => {
                        if (!res.error) {
                            console.log(res);
                            this.formData.lists.push(res);
                        }
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                        this.currencyList = [];
                    });
                }
            },
            showAddWatchlist() {
                this.showModalAddWatchlist = true;
            },
            hideAddWatchlist(refreshData = false) {
                this.showModalAddWatchlist = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getWatchLists');
                    })
                }
            },
        }
    };
</script>
