<template>
    <template v-if="rules.deal_add">
        <b-form
            @submit="onSubmitDeal"
            class="account__edit-form row"
        >
            <b-form-group
                id="input-group-instrument"
                label="Тикер / ISIN / Название*"
                class="col-12"
            >
                <template v-if="dealId">
                    <input
                        id="input-instrument-name"
                        v-model="formData.instrument_name"
                        type="text"
                        name="instrument_name"
                        disabled
                        class="form-control form-control-sm shadow-sm m--no-pill"
                    />
                    <input
                        id="input-instrument"
                        v-model="formData.instrument"
                        type="hidden"
                    />
                </template>
                <template v-else>
                    <Multiselect
                        id="input-instrument"
                        ref="input-instrument"
                        v-model="formData.instrument"
                        :options="async function(query) {
                            return await getInstrumentList(query);
                        }"
                        mode="single"
                        placeholder="Введите тикер или название актива"
                        noOptionsText="Список пуст"
                        noResultsText="По Вашему запросу ничего не найдено"
                        :filter-results="false"
                        :min-chars="1"
                        :resolve-on-load="false"
                        :delay="0"
                        :searchable="true"
                        class="shadow-sm m--no-pill"
                        required
                        :disabled="showLoaderSending"
                        @select="onSelectInstrument()"
                        @clear="onClearInstrument()"
                    >
                        <template #option="{ option }">
                            <div>
                                {{ option.label }}
                                <div class="text-muted small">{{ option.sublabel }}</div>
                            </div>
                        </template>
                    </Multiselect>
                </template>
            </b-form-group>
            <b-form-group
                id="input-group-date"
                label="Дата сделки*"
                class="col-6"
            >
                <b-input-group class="m--with-icon">
                    <template #append>
                        <b-icon-calendar2-check class="icon"></b-icon-calendar2-check>
                    </template>
                    <VueDatePicker
                        v-model="formData.date"
                        model-type="yyyy-MM-dd"
                        format="dd.MM.yyyy"
                        locale="ru"
                        :auto-apply="true"
                        select-text="Выбрать"
                        cancel-text="Отменить"
                        :year-range="[2000, 2050]"
                        :max-date="new Date()"
                        :prevent-min-max-navigation="true"
                        text-input
                        :enable-time-picker="false"
                        :month-change-on-scroll="true"
                        :clearable="false"
                        :hide-input-icon="true"
                        :disabled="showLoaderSending || !formData.instrument"
                    >
                        <template #dp-input="{ value, onInput, onEnter, onTab }">
                            <input
                                v-maska
                                data-maska="##.##.####"
                                data-maska-eager
                                class="form-control form-control-sm shadow-sm m--no-pill"
                                type="text"
                                :value="value"
                                :disabled="showLoaderSending || !formData.instrument"
                                @keyup="onInput"
                                @keydown.enter.prevent="onEnter"
                                @keydown.tab="onTab"
                            />
                        </template>
                    </VueDatePicker>
                </b-input-group>
            </b-form-group>
            <b-form-group
                id="input-group-operation_type"
                label="Операция*"
                class="col-6"
            >
                <Multiselect
                    id="input-operation_type"
                    ref="input-operation_type"
                    v-model="formData.operation_type"
                    :options="operationList"
                    valueProp="operation_type_id"
                    trackBy="operation_type_detail"
                    label="operation_type_detail"
                    mode="single"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    class="shadow-sm m--no-pill"
                    placeholder=""
                    required
                    :disabled="showLoaderSending || !formData.instrument"
                    @select="onSelectOperation()"
                    @clear="onClearOperation()"
                />
            </b-form-group>
            <b-form-group
                id="input-group-price"
                :label="priceInputLabel"
                class="col-6"
            >
                <input
                    id="input-price"
                    v-maska:[optionsMaska]
                    data-maska="0.99"
                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                    v-model="formData.price"
                    type="text"
                    name="price"
                    placeholder="Введите цену одной бумаги"
                    class="form-control form-control-sm shadow-sm m--no-pill"
                    :disabled="showLoaderSending || !formData.instrument"
                    required
                    @maska="calcDealPayment"
                />
            </b-form-group>
            <b-form-group
                id="input-group-quantity"
                label="Количество ЦБ*"
                class="col-6"
            >
                <input
                    id="input-quantity"
                    v-maska:[optionsMaska]
                    data-maska="0"
                    data-maska-tokens="0:\d:multiple"
                    v-model="formData.quantity"
                    type="text"
                    name="quantity"
                    placeholder="Введите количество"
                    class="form-control form-control-sm shadow-sm m--no-pill"
                    :disabled="showLoaderSending || !formData.instrument"
                    required
                    @maska="calcDealPayment"
                />
            </b-form-group>
            <b-form-group
                id="input-group-commission"
                label="Комиссия*"
                class="col-6"
            >
                <input
                    id="input-commission"
                    v-maska:[optionsMaska]
                    data-maska="0.99"
                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                    v-model="formData.commission"
                    type="text"
                    name="commission"
                    placeholder="Комиссии за сделку"
                    class="form-control form-control-sm shadow-sm m--no-pill"
                    :disabled="showLoaderSending || !formData.instrument || hideCommission"
                    required
                    @maska="calcDealPayment"
                />
            </b-form-group>
            <b-form-group
                id="input-group-currency"
                label="Валюта сделки*"
                class="col-6"
            >
                <Multiselect
                    id="input-currency"
                    ref="input-currency"
                    v-model="formData.currency"
                    :options="currencyList"
                    mode="single"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    class="shadow-sm m--no-pill"
                    placeholder=""
                    :disabled="showLoaderSending || !formData.instrument"
                    required
                    @select="onSelectCurrency()"
                />
            </b-form-group>
            <b-form-group
                v-if="instrument?.instrument_type_id === 2 && (+formData.operation_type === 1 || +formData.operation_type === 2)"
                id="input-group-aci"
                label="НКД"
                class="col-6"
            >
                <input
                    id="input-aci"
                    v-maska:[optionsMaska]
                    data-maska="0.99"
                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                    v-model="formData.aci"
                    type="text"
                    name="aci"
                    placeholder=""
                    size="sm"
                    class="form-control form-control-sm shadow-sm m--no-pill"
                    :disabled="showLoaderSending || !formData.instrument"
                    required
                    @maska="calcDealPayment"
                />
            </b-form-group>
            <b-form-group
                id="input-group-comment"
                label="Комментарий"
                class="col-12"
            >
                <b-form-textarea
                    id="input-comment"
                    v-model="formData.comment"
                    placeholder="При необходимости введите ваш комментарий"
                    size="sm"
                    class="shadow-sm m--no-pill"
                    :disabled="showLoaderSending || !formData.instrument"
                ></b-form-textarea>
            </b-form-group>
            <!--
            <b-form-group
                id="input-group-ticker"
                label="Тикер*"
                class="col-4"
            >
                <b-form-input
                    id="input-ticker"
                    v-model="formData.ticker"
                    type="text"
                    placeholder="Введите тикер"
                    size="sm"
                    class="shadow-sm m--no-pill"
                    :disabled="showLoaderSending"
                    required
                ></b-form-input>
            </b-form-group>
            -->
            <div class="account__edit-form-amount col-12">
                Итого:
                <span
                    v-if="formData.price && formData.quantity && formData.currency"
                >
                    {{ $helpers.toPrice(formData.payment, { sign: formData.currency_iso, pointer: ',' }) }}
                </span>
            </div>
            <input
                id="input-payment"
                v-model="formData.payment"
                name="payment"
                type="hidden"
            />
            <b-form-group
                v-if="instrument && formData.operation_type && formData.quantity && formData.currency && showUseCash"
                id="checkbox-group-confirm"
                class="col-12"
            >
                <b-form-checkbox
                    id="checkbox-confirm"
                    v-model="formData.confirm"
                    name="confirm"
                >
                    Списать с баланса счета {{ $helpers.toPrice(formData.price * formData.quantity, { sign: formData.currency_iso, pointer: ',' }) }} за операцию: {{ instrument.operation_types[formData.operation_type] }} - {{ instrument.ticker }} - {{ instrument.name }} - {{ formData.quantity }} шт.
                </b-form-checkbox>
            </b-form-group>
            <div
                v-if="this.formData.currency"
                class="text-muted mb-4"
            >
                Текущий баланс счёта: {{ $helpers.toPrice(currentCashAmount, { sign: currentCashCurrency, pointer: ',' }) }}
            </div>
            <div class="col-12">
                <b-alert
                    variant="success"
                    :show="addSuccess"
                >
                    {{ dealId ? 'Сделка успешно изменена' : 'Сделка успешно добавлена' }}
                </b-alert>
                <b-alert
                    variant="danger"
                    :show="addError"
                >
                    {{ addError }}
                </b-alert>
            </div>
            <div :class="['col-12 pt-3 row', (inModal || dealId) && 'justify-content-center']">
                <div
                    v-if="inModal"
                    class="col-auto"
                >
                    <b-button
                        variant="outline-success"
                        class="account__edit-form-button mb-3"
                        type="submit"
                        pill
                        :disabled="showLoaderSending"
                        @click="formData.typeSubmit = 'close'"
                    >
                        Сохранить
                    </b-button>
                </div>
                <div
                    v-if="!dealId"
                    class="col-auto"
                >
                    <b-button
                        variant="success"
                        class="account__edit-form-button mb-3"
                        type="submit"
                        pill
                        :disabled="showLoaderSending"
                        @click="formData.typeSubmit = 'repeat'"
                    >
                        Сохранить и добавить еще
                    </b-button>
                </div>
            </div>
            <b-overlay
                :show="showLoaderSending"
                variant="transparent"
                no-wrap
                spinner-variant="success"
            />
        </b-form>
    </template>
    <template v-else>
        <BlockTariffLimited
            @hideModal="hideModal"
        />
    </template>
</template>

<script>
    import { app } from "@/services";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import Multiselect from '@vueform/multiselect'
    export default {
        components: {
            BlockTariffLimited,
            Multiselect,
        },
        emits: ['hideModal'],
        props: {
            dealId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            inModal: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                optionsMaska:  {
                    preProcess: val => {
                        return val.replace(/[$,\s]/g, '')
                    },
                    postProcess: val => {
                        if (!val) return ''
                        const sub = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)
                        return Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).format(val)
                            .slice(0, sub ? -sub : undefined)
                            .replace(/[$]/g, '')
                            .replace(/[,]/g, ' ')
                    }
                },
                /*
                masks: {
                    input: 'DD.MM.YYYY',
                },
                calendarConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD'
                },
                */
                formData: {
                    portfolioId: this.portfolioId,
                    date: this.$helpers.formatDate(new Date(), 'YYYY-MM-DD'),
                    commission: 0,
                    aci: 0
                },
                showLoaderSending: false,
                addSuccess: false,
                addError: null,
                instrument: null,
                instrumentList: [],
                operationList: [],
                currencyList: [],
                cashList: [],
                //cash: null,
                hideCommission: false,
                showUseCash: false,
                refreshData: false,
            };
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currentCashAmount() {
                let cash = this.cashList.filter(item => {
                    return item.currency === this.formData.currency;
                });
                return cash[0]?.amount || 0;
            },
            currentCashCurrency() {
                let cash = this.cashList.filter(item => {
                    return item.currency === this.formData.currency;
                });
                return cash[0]?.currency_iso_name;
            },
            priceInputLabel() {
                let operation = this.instrument?.operation_types.filter(item => { return item.operation_type_id === this.formData.operation_type })[0];
                if (operation) {
                    if (this.instrument?.instrument_type === 'share') {
                        if (operation.operation_type === 'dividend') return 'Размер дивиденда на 1 шт.*';
                    }
                    if (this.instrument?.instrument_type === 'bond') {
                        if (operation.operation_type === 'coupon') return 'Размер купона на 1 шт.*';
                        if (operation.operation_type === 'amortization') return 'Сумма выплаты на 1 шт.*';
                    }
                }
                return 'Цена одной бумаги*';
            }
            /*
            hideCommission() {
                let operation = this.instrument.operation_types.filter(item => { return item.operation_type_id === this.formData.operation_type })[0];
                let commission = this.formData.commission;
                console.log (commission);
                if (operation) {
                    if (this.instrument.instrument_type === 'share' && operation.operation_type === 'dividend') {
                        commission = 0;
                        return true;
                    }
                    if (this.instrument.instrument_type === 'bond' && operation.operation_type === 'coupon') {
                        commission = 0;
                        return true;
                    }
                }
                return false;
            }
            */
        },
        mounted() {
            if (this.dealId) {
                this.getPortfolioDeal();
            }
            this.getPortfolioCash();
        },
        methods: {
            getPortfolioDeal() {
                if (this.dealId) {
                    let params = {
                        dealId: this.dealId,
                        portfolioId: this.portfolioId,
                    }
                    app.getPortfolioDeal(params, this.portfolioType).then(res => {
                        if (!res.error) {
                            console.log(res);
                            res.date = this.$helpers.formatDate(new Date(res.date), 'YYYY-MM-DD');
                            this.formData = res;
                            this.formData.portfolioId = res.portfolio;
                            delete res.portfolio;
                            this.getInstrument(this.formData.instrument);
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                }
            },
            getPortfolioCash() {
                this.showLoaderSending = true;
                app.getPortfolioCash(this.portfolioId, this.portfolioType).then(res => {
                    this.showLoaderSending = false;
                    if (!res.error) {
                        this.cashList = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getInstrument(id) {
                return app.getInstrument(id).then(res => {
                    console.log(res);
                    this.instrumentList = [res];
                    this.formData.instrument_name = `${res.ticker} - ${res.name}`;
                    this.onSelectInstrument();
                    this.onSelectOperation();
                    this.calcDealPayment();
                    /*
                    return [res].map((instrument) => {
                        return {
                            label: `${instrument.ticker} - ${instrument.name}`,
                            value: instrument.id,
                        }
                    });
                    */
                }).catch(err => {
                    console.error(err);
                    return [];
                });
            },
            getInstrumentList(query) {
                this.addSuccess = false;
                let params = {
                    word: query
                };
                return app.getInstrumentList(params).then(res => {
                    console.log(res);
                    this.instrumentList = res.detail;
                    return res.detail.map((instrument) => {
                        return {
                            label: `${instrument.ticker} - ${instrument.name}`,
                            sublabel: `${this.$helpers.toPrice(instrument.price, { sign: instrument.currency_symbol, pointer: ',' })}, ${instrument.instrument_type_detail}${instrument.exchange ? ', ' + instrument.exchange : ''}`,
                            value: instrument.id,
                        }
                    });
                }).catch(err => {
                    console.error(err);
                    return [];
                });
            },
            onSelectInstrument() {
                this.addSuccess = false;
                this.instrument = this.instrumentList.filter(item => {
                    if (this.formData.instrument === item.id) {
                        return item;
                    }
                })[0];
                if (this.instrument) {
                    if (!this.dealId) {
                        this.onClearInstrument();
                    }
                    this.$nextTick(() => {
                        this.formData.instrument_type = this.instrument.instrument_type_id;
                        if (!this.dealId) {
                            this.formData.price = this.instrument.price;
                        }
                        this.operationList = this.instrument.operation_types;
                        console.log(this.instrument);
                        if (this.instrument.currency_info) {
                            this.currencyList = this.instrument.currency_info.map(item => {
                                return {
                                    label: item.name,
                                    value: item.id,
                                    currency_iso: item.key
                                }
                            });
                        } else {
                            this.currencyList = [{
                                label: this.instrument.currency_name,
                                value: this.instrument.currency_id,
                                currency_iso: this.instrument.currency_iso_name
                            }]
                            this.formData.currency = this.instrument.currency_id;
                            this.formData.currency_iso = this.instrument.currency_iso_name;
                        }
                    });
                }
            },
            onClearInstrument() {
                console.log('CLEAR');
                this.formData.instrument_type = null;
                this.formData.operation_type = null;
                this.formData.currency = null;
                this.formData.currency_iso = null;
                this.formData.price = null;
                this.formData.aci = 0;
            },
            onSelectOperation() {
                this.hideCommission = false;
                let operation = this.instrument.operation_types.filter(item => { return item.operation_type_id === this.formData.operation_type })[0];
                if (operation) {
                    if (this.instrument.instrument_type === 'share' && operation.operation_type === 'dividend') {
                        this.formData.commission = 0;
                        this.hideCommission = true;
                    }
                    if (this.instrument.instrument_type === 'bond' && operation.operation_type === 'coupon') {
                        this.formData.commission = 0;
                        this.hideCommission = true;
                    }
                }
            },
            onClearOperation() {
                this.hideCommission = false;
            },
            onChangeDate() {
                console.log('onChangeDate');
                if (this.formData.instrument && this.formData.date) {
                    app.getInstrumentHistoryDate(this.formData.instrument, this.formData.date).then(res => {
                        console.log(res);
                        this.formData.price = res.detail.price;
                    }).catch(err => {
                        console.error(err);
                    });
                }
            },
            onSelectCurrency() {
                let currency = this.currencyList.filter(item => {
                    if (this.formData.currency === item.value) {
                        return item;
                    }
                })[0];
                if (currency) {
                    this.formData.currency_iso = currency.currency_iso;
                }
            },
            onSubmitDeal() {
                console.log('onSubmitDeal');
                this.showLoaderSending = true;
                this.addSuccess = false;
                this.addError = null;
                let params = Object.assign({}, this.formData);
                params.commission = params.commission + '';
                params.commission = +(params.commission.replace(/\s/g, ''));
                params.price = +(params.price.replace(/\s/g, ''));
                params.quantity = +(params.quantity.replace(/\s/g, ''));
                params.aci = (params.aci || 0) + '';
                params.aci = +(params.aci.replace(/\s/g, ''));
                if (this.dealId) {
                    delete params.payment_sum;
                    app.updatePortfolioDeal(params, this.portfolioType).then(() => {
                        this.refreshData = true;
                        this.addSuccess = true;
                        if (this.formData.typeSubmit === 'close') {
                            this.$emit('hideModal', this.refreshData);
                        }
                        this.formData = {
                            portfolioId: this.portfolioId,
                            commission: 0
                        };
                        this.getPortfolioCash();
                        this.showLoaderSending = false;
                    }).catch(err => {
                        //this.formData.portfolioId = this.portfolioId;
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                } else {
                    app.addPortfolioDeal(params, this.portfolioType).then(() => {
                        this.refreshData = true;
                        this.addSuccess = true;
                        if (this.formData.typeSubmit === 'close') {
                            this.$emit('hideModal', this.refreshData);
                        }
                        this.formData = {
                            portfolioId: this.portfolioId,
                            commission: 0,
                            aci: 0
                        };
                        this.$refs['input-instrument'].clear();
                        this.$refs['input-operation_type'].clear();
                        this.$refs['input-currency'].clear();
                        this.getPortfolioCash();
                        this.showLoaderSending = false;
                    }).catch(err => {
                        //this.formData.portfolioId = this.portfolioId;
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                }
            },
            calcDealPayment() {
                console.log('calcDealPayment');
                if (event) {
                    this.formData[event.target.name] = event.target.dataset?.maskRawValue;
                }
                this.$nextTick(() => {
                    //this.formData.commission = 0;
                    let amount = (1 * this.formData.price?.toString().replace(/\s/g, '')) * (1 * this.formData.quantity?.toString().replace(/\s/g, ''));
                    if (this.instrument?.instrument_type_id === 2) {
                        if (+this.formData.operation_type === 1 || +this.formData.operation_type === 2) {
                            amount = amount + 1 * this.formData.aci.toString().replace(/\s/g, '');
                        }
                    }
                    this.formData.payment = amount;
                });
            },
        }
    };
</script>
