<template>
    <div class="account__settings-trust-manager row">
        <b-form-group
            :label="index ? '' : 'ID'"
            class="account__settings-trust-manager-id col-2"
        >
            <b-form-input
                v-model="formData.user"
                type="text"
                readonly
                size="sm"
                class="shadow-sm m--no-pill"
                :disabled="showLoaderSending"
                :state="formErrors && formErrors.manager ? false : null"
            ></b-form-input>
        </b-form-group>
        <b-form-group
            :label="index ? '' : 'Имя'"
            class="account__settings-trust-manager-name col"
        >
            <b-form-input
                v-model="formData.user_public_name"
                type="text"
                readonly
                size="sm"
                class="shadow-sm m--no-pill"
                :disabled="showLoaderSending"
                :state="formErrors && formErrors.manager_name ? false : null"
            ></b-form-input>
        </b-form-group>
        <b-form-group
            :label="index ? '' : 'Доступ к портфелю'"
            class="account__settings-trust-manager-access col-auto"
        >
            <Multiselect
                v-model="formData.access"
                :options="accessTypes"
                :canClear="false"
                class="shadow-sm m--no-pill"
                placeholder="Права"
                @select="changeManagerAccess"
            />
        </b-form-group>
        <div 
            class="col-2 mt-auto mb-3"
        >
            <b-button
                class="account__settings-trust-manager-save w-100"
                variant="outline-success" 
                pill
                :disabled="showLoaderSending"
                @click.stop="showManagerDelete"
            >
                Удалить
                <b-overlay 
                    :show="showLoaderSending"
                    no-wrap
                    variant="transparent"
                    spinner-variant="success"
                />
            </b-button>
        </div>
    </div>
    <ModalAccountManagerDelete
        :show="showModalManagerDelete"
        :portfolio="portfolio"
        :trustUser="trustUser"
        @hideModal="hideManagerDelete"
    />
</template>

<script>
    import { app } from "@/services";
    import Multiselect from '@vueform/multiselect';
    import ModalAccountManagerDelete from "@/components/modals/modal-account-manager-delete";
    export default {
        components: {
            Multiselect,
            ModalAccountManagerDelete,
        },
        emits: ['refreshPortfolio'],
        computed: {
        },
        props: {
            index: {
                type: Number,
                default() { return 0; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            trustUser: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                accessTypes: {
                    read: 'Просмотр',
                    edit: 'Редактирование'
                },
                formData: {},
                formErrors: {},
                showModalManagerDelete: false,
                showLoaderSending: false
            }
        },
        created() {
        },
        mounted() {
            this.formData = this.trustUser;
        },
        methods: {
            refreshPortfolio() {
                this.$emit('refreshPortfolio');
            },
            changeManagerAccess() {
                this.formErrors = {};
                let params = {
                    portfolio: this.portfolio.id,
                    user: this.formData.user,
                    access: this.formData.access,
                };
                this.showLoaderSending = true;
                app.updateTrustUser(params).then(res => {
                    console.log(res);
                    this.showLoaderSending = false;
                    this.refreshPortfolio();
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response.data);
                        //this.formErrors = err.response.data.error;
                        //this.formErrors.token = 1;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            showManagerDelete() {
                this.showModalManagerDelete = true;
            },
            hideManagerDelete(refresh) {
                this.showModalManagerDelete = false;
                if (refresh) {
                    this.refreshPortfolio();
                }
            },
        }
    };
</script>
