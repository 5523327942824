<template>
    <div :class="['app__block d-flex h-100 flex-column px-4 pt-3 pb-4', $route.name === 'trust-management' ? 'accounts' : 'account']">
        <BlockUserCurrencies/>
        <template v-if="$route.name === 'trust-management'">
            <div class="app__block-title m--help">
                <h1 class="mb-5">Доверительное управление</h1>
                <router-link
                    :to="{ name: 'page', params: { slug: 'help_trust' }}"
                    custom
                    v-slot="{ href }"
                >
                    <a
                        :href="href"
                        class="app__block-help"
                        @click.prevent="showPage('help_trust')"
                    >
                        <b-icon-question-circle-fill />
                    </a>
                </router-link>
            </div>
            <div class="managment__filters filters mb-4">
                <div class="mb-3 me-3 mt-auto">
                    <b-card
                        class="filters__item shadow"
                        bodyClass="py-1"
                    >
                        <b-input-group>
                            <b-form-input
                                v-model="tableDataFilters.query"
                                placeholder="Поиск по имени/ID инвестора, названию брокерского счета"
                                size="sm"
                                class="border-transparent py-0"
                            >
                            </b-form-input>
                            <b-input-group-append>
                                <div
                                    class="btn-icon"
                                >
                                    <b-icon-search></b-icon-search>
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </b-card>
                </div>
            </div>
            <template v-if="!showLoaderSending">
                <b-card
                    :class="['managment__table col-12 shadow mb-5', showLoaderSending ? 'py-4' : '']"
                    bodyClass="p-1"
                >
                    <template v-if="portfolios.length">
                        <b-table
                            :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                            :items="prepareTableData()"
                            sticky-header
                            responsive
                            hover
                            class="table__data b-table-sticky-header"
                        >
                            <template #head()="data">
                                <TableHeaderSlot
                                    :data="data"
                                    :currentSorting="currentSorting"
                                    @changeSorting="changeSorting"
                                />
                            </template>
                            <template #cell(user_public_name)="data">
                                <strong class="text-nowrap pe-3">{{ data.item.user_public_name }}</strong>
                                <div class="text-uppercase">
                                    ID: {{ data.item.user_id }}
                                </div>
                            </template>
                            <template #cell(portfolio_name)="data">
                                <router-link
                                    :to="{ name: 'trust', params: { portfolioId: data.item.portfolio_id } }"
                                >
                                    <strong class="text-nowrap pe-3">{{ data.item.trust_name || data.item.portfolio_name }} ({{ data.item.broker_name }})</strong>
                                </router-link>
                                <div
                                    class="text-uppercase"
                                    :class="data.item.access === 'edit' ? 'text-success' : ''"
                                >
                                    {{ data.item.access_name }}
                                </div>
                            </template>
                            <template #cell(action)="data">
                                <BlockActionsManager
                                    :portfolio="data.item"
                                    @getPortfolioList="getTrustList"
                                />
                            </template>
                            <template #cell(comment)="data">
                                <div class="small">
                                    {{ data.item.trust_user_comment }}
                                </div>
                            </template>
                        </b-table>
                        <BlockNavigateTable
                            :totalRows="currentTotalRows"
                            :currentPage="currentPage"
                            :currentPerPage="currentPerPage"
                            @changeNavigateTable="changeNavigateTable"
                        />
                    </template>
                    <template v-else-if="!showLoaderSending">
                        <b-alert
                            class="mb-0"
                            variant="warning"
                            :model-value="true"
                        >
                            У вас нет брокерских счетов в доверительном управлении.
                        </b-alert>
                    </template>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-card>
            </template>
            <template v-else>
                <div class="app__loader">
                    <b-spinner
                        variant="success"
                        class="me-3"
                    />
                    <span class="text-success">Загрузка данных ...</span>
                </div>
            </template>
        </template>
        <routerView v-else />
        <ModalPage
            :slug="ModalPageSlug"
            :show="showModalPage"
            @hideModal="hidePage"
        />

    </div>
</template>

<script>
    import { trustManagerData } from "@/settings/data";
    import { fieldNames } from "@/settings/tables";

    import { app } from "@/services";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import BlockActionsManager from "@/components/block-actions-manager";
    import TableHeaderSlot from "@/components/table-header-slot";
    import ModalPage from "@/components/modals/modal-page";

    export default {
        name: 'trustManagment',
        components: {
            BlockUserCurrencies,
            BlockNavigateTable,
            BlockActionsManager,
            TableHeaderSlot,
            ModalPage
        },
        watch: {
        },
        props: {
        },
        data() {
            return {
                tableFilters: [],
                tableDataFilters: {
                    query: null,
                },
                tableHeader: fieldNames.trustManagerItem,
                currentSorting: {
                    key: 'owner',
                    direction: 'asc'
                },
                portfolios: trustManagerData,
                currentPerPage: 50,
                currentPage: 1,
                ModalPageSlug: null,
                showModalPage: false,
                showLoaderSending: false
            };
        },
        computed: {
        },
        created() {
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
            this.getTrustList();
        },
        mounted() {
            let tF = [];
            Object.keys(this.tableHeader).map((key) => {
                tF.push(key);
            });
            this.tableFilters = tF;
        },
        methods: {
            getTrustList() {
                let params = {};
                this.showLoaderSending = true;
                app.getTrustList(params).then(res => {
                    if (!res.error) {
                        this.portfolios = res.detail;
                        this.currentTotalRows = this.portfolios.length;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.portfolios];
                list = this.filterTableData(list);
                this.currentTotalRows = list.length;
                if (list.length) {
                    //console.log('prepareTableData', this.currentFilter);
                    if (this.currentSorting.key) {
                        if (this.currentSorting.key === 'portfolio_name') {
                            list = list.sort((a, b) => {
                                if ((a['trust_name'] || a['portfolio_name']) > (b['trust_name'] || b['portfolio_name']))
                                    return this.currentSorting.direction === 'asc' ? 1 : -1;
                                if ((a['trust_name'] || a['portfolio_name']) < (b['trust_name'] || b['portfolio_name']))
                                    return this.currentSorting.direction === 'asc' ? -1 : 1;
                                return 0;
                            });
                        } else {
                            list = list.sort((a, b) => {
                                if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                    return this.currentSorting.direction === 'asc' ? 1 : -1;
                                if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                    return this.currentSorting.direction === 'asc' ? -1 : 1;
                                return 0;
                            });
                        }
                    }
                    if ((this.currentPage - 1) * this.currentPerPage > list.length) {
                        this.currentPage = Math.floor(list.length / this.currentPerPage) + 1;
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                console.log('Actvives data:', list);
                list = list.filter(item => {
                    if (this.tableDataFilters.query) {
                        if (item.user_public_name.toLowerCase().indexOf(this.tableDataFilters.query.toLowerCase()) !== -1 ||
                            item.user_id.toLowerCase().indexOf(this.tableDataFilters.query.toLowerCase()) !== -1 ||
                            item.trust_name.toLowerCase().indexOf(this.tableDataFilters.query.toLowerCase()) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeNavigateTable(page, perPage) {
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
