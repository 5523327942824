<template>
    <template
        v-if="showLoaderSending"
    >
        <b-spinner 
            variant="success"
            class="me-3"
        />
        <span class="h4 text-success">Загрузка данных ...</span>
    </template>
    <template
        v-else
    >
        <b-alert
            variant="danger"
            show
        >
            <div
                class="content"
                v-html="page.content || `Создайте страницу с полем slug: ${slug}`"
            />
            <b-button
                :variant="'primary'"
                size=""
                pill
                @click.stop="next('profile-tariffs')"
            >
                Купить подписку
            </b-button>
        </b-alert>
    </template>
</template>

<script>
    import { app } from "@/services";
    export default {
        emits: ['hideModal'],
        components: {
        },
        computed: {
        },
        props: {
        },
        data() {
            return {
                slug: 'tariff_limited',
                page: {},
                requested: false,
                showLoaderSending: true
            }
        },
        mounted() {
            this.getPageContent(this.slug);
        },
        methods: {
            getPageContent(slug) {
                this.requested = false;
                if (!this.requested) {
                    this.showLoaderSending = true;
                    app.getPage(slug).then(res => {
                        //console.log(res);
                        this.page = res;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        console.error(err);
                        this.showLoaderSending = false;
                    });
                    this.requested = true;
                }
            },
            next(name) {
                this.$emit('hideModal');
                this.$router.push({ name: name });
            }
        }
    };
</script>
