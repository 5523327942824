<template>
    <b-dropdown 
        :id="`action-${reminder.id}`" 
        class="float-end"
        dropleft 
        variant="link-primary" 
        no-caret
    >
        <template #button-content>
            <b-icon-three-dots-vertical />
        </template>
        <b-dropdown-item-button
            variant=""
            @click.prevent="showEditReminder"
        >
            Изменить напоминание
        </b-dropdown-item-button>
        <b-dropdown-item-button
            variant="danger"
            @click.prevent="showDeleteReminder"
        >
            Удалить
        </b-dropdown-item-button>
    </b-dropdown>
    <ModalReminderAdd
        :show="showModalEditReminder"
        :reminderId="reminder.id"
        @hideModal="hideEditReminder"
    />
    <ModalReminderDelete
        :show="showModalDeleteReminder"
        :reminder="reminder"
        @hideModal="hideDeleteReminder"
    />
</template>

<script>
    import ModalReminderAdd from "@/components/modals/modal-reminder-add";
    import ModalReminderDelete from "@/components/modals/modal-reminder-delete";
    export default {
        emits: ['getReminders'],
        components: {
            ModalReminderAdd,
            ModalReminderDelete
        },
        props: {
            reminder: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                showModalEditReminder: false,
                showModalDeleteReminder: false,
            }
        },
        mounted() {
        },
        methods: {
            showEditReminder() {
                this.showModalEditReminder = true;
            },
            hideEditReminder(refreshData = false) {
                this.showModalEditReminder = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getReminders');
                    });
                }
            },
            showDeleteReminder() {
                this.showModalDeleteReminder = true;
            },
            hideDeleteReminder(refreshData = false) {
                this.showModalDeleteReminder = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getReminders');
                    });
                }
            },
        }
    };
</script>
