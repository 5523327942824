<template>
    <div class="profile__currencies">
        <b-form 
            class="row profile__currencies-form pt-3"
        >
            <b-form-group
                label="Вы можете выбрать 4 валюты, между которыми можно быстро переключаться внутри сервиса."
                class="profile__currencies-block col-12"
            >
            </b-form-group>
            <b-form-group
                label="Валюта клиента по умолчанию"
                class="profile__currencies col-12"
            >
                <BlockProfileCurrency
                    :key="`currency-0`"
                    :index="0"
                    :currencyListFull="currencyListFull"
                    :currencyListSelected="currencyListSelected"
                    :currencyCurrent="currencyListSelected.filter(item => { return item.default; })[0]"
                    :requered="true"
                    @changeCurrencyList="changeCurrencyList"
                />
            </b-form-group>
            <b-form-group
                label="Дополнительные валюты"
                class="profile__currencies-block col-12"
            >
                <BlockProfileCurrency
                    v-for="index in 3"
                    :key="`currency-${index}`"
                    :index="index"
                    :currencyListFull="currencyListFull"
                    :currencyListSelected="currencyListSelected"
                    :currencyCurrent="currencyListSelected.filter(item => { return !item.default; })[index - 1]"
                    @changeCurrencyList="changeCurrencyList"
                />
            </b-form-group>
            <!--
            <div class="col">
                <b-button
                    type="submit"
                    variant="primary"
                    class="w-100 py-2"
                    pill
                >
                    Сохранить настройки
                </b-button>
            </div>
            <div class="col">
                <b-button
                    type="reset"
                    variant="outline-secondary"
                    class="col-6 py-2 mt-auto mb-3"
                    pill
                >
                    Отменить
                </b-button>
            </div>
            -->
        </b-form>
    </div>
</template>

<script>
    import { app } from "@/services";
    import BlockProfileCurrency from "@/components/block-profile-currency";
    export default {
        name: 'profileCurrencies',
        components: {
            BlockProfileCurrency
        },
        props: {
        },
        computed: {
            currencyListSelected() {
                return this.$store.state.currencyList;
            }
        },
        data() {
            return {
                formData: {},
                showLoaderSending: false,
                currencyListFull: [],
            };
        },
        created() {
        },
        mounted() {
            let params = {};
            this.showLoaderSending = true;
            app.getCurrencies(params).then(res => {
                if (!res.error) {
                    this.currencyListFull = res;
                } else {
                    this.$store.dispatch('showError', {err: { message: res.error }});
                }
                this.showLoaderSending = false;
            }).catch(err => {
                this.showLoaderSending = false;
                this.$store.dispatch('showError', err);
                console.error(err);
            });
            this.getProfileCurrencyList();
            /*
            app.getProfileCurrencyList().then(res => {
                if (!res.error) {
                    console.log(res);
                } else {
                    this.$store.dispatch('showError', {err: { message: res.error }});
                }
                this.showLoaderSending = false;
            }).catch(err => {
                this.showLoaderSending = false;
                this.$store.dispatch('showError', err);
                console.error(err);
            });
            */
            //this.currencyListSelected = this.$store.state.currencyList || this.currencyListDefault();
            //this.$store.dispatch('setCurrencyList', this.currencyListSelected);
        },
        methods: {
            getProfileCurrencyList() {
                app.getProfileCurrencyList().then(res => {
                    //this.currencyListSelected = res.results;
                    //this.currencyListSelected = res.detail;
                    this.$store.dispatch('setCurrencyList', res.detail);
                    //this.$store.dispatch('setCurrency', currency);
                }).catch(err => {
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
            changeCurrencyList(oldId, newId, requered) {
                console.log(oldId, newId, requered);
                let currency = this.currencyListSelected.filter(с => { return с.currency === newId })[0];
                //let currency = this.currencyListSelected[index];
                if (!currency) {
                    currency = {};
                }
                if (requered && currency.currency) {
                    return;
                }

                if (this.currencyListSelected.filter(с => { return с.currency === oldId })[0]) {
                    if (currency.id) {
                        console.log('Delete', currency.currency, currency.iso_name);
                        this.showLoaderSending = true;
                        let params = {
                            currency: oldId
                        };
                        app.deleteProfileCurrency(params).then(res => {
                            console.log(res);
                            this.showLoaderSending = false;
                            this.$store.dispatch('setCurrencyList', res.detail);
                            //this.getProfileCurrencyList();
                        }).catch(err => {
                            this.showLoaderSending = false;
                            this.$store.dispatch('showError', err);
                            console.error(err);
                        });
                    } else {
                        console.log('Change', oldId, newId);
                        this.showLoaderSending = true;
                        let params = {
                            currency: newId,
                            currency_old: oldId,
                            default: requered
                        };
                        app.changeProfileCurrency(params).then(res => {
                            console.log(res);
                            this.showLoaderSending = false;
                            this.$store.dispatch('setCurrencyList', res.detail);
                            //this.getProfileCurrencyList();
                        }).catch(err => {
                            this.showLoaderSending = false;
                            this.$store.dispatch('showError', err);
                            console.error(err);
                        });
                    }
                } else {
                    console.log('Add', newId);
                    let params = {
                        currency: newId
                    };
                    this.showLoaderSending = true;
                    app.addProfileCurrency(params).then(res => {
                        console.log(res);
                        this.showLoaderSending = false;
                        this.$store.dispatch('setCurrencyList', res.detail);
                        //this.getProfileCurrencyList();
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });

                }
                //this.$store.dispatch('setCurrencyList', this.currencyListSelected);
                let currencyCurrent = this.$store.state.currencyCurrent;
                if (!this.currencyListSelected.filter(c => c.iso_name === currencyCurrent.iso_name).length) {
                    currencyCurrent = this.$store.state.currencyList[0];
                    this.$store.dispatch('setCurrency', currencyCurrent);
                }
            },
        }
    };
</script>
